import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./locale.fr.json";
import en from "./locale.en.json";

i18n
  .use(initReactI18next)
  .init({
    resources: { fr, en },
    defaultNS: "fft",
    fallbackLng: "fr",
    lng: getLng(new URLSearchParams(window.location.search).get('locale'))
      || getLng(localStorage.getItem("locale"))
      || getLng(navigator.language)
  }).then(() => {
    localStorage.setItem("locale", i18n.language);
  });

/**
 * Convert browser language format (RFC 5646) to ISO 2 with French by default
 * 
 * @param language language string with format RFC 5646
 * @returns ISO 2 format ('en'  or 'fr') language
 */
function getLng(language: string | null): string {
  const isPresent: boolean = typeof language === 'string' && language.trim().length != 0;
  
  return !isPresent ? '' : (language?.slice(0, 2) === 'en') ? 'en' : 'fr';
}
