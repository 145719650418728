import axios from "axios";
import { kcContext } from "./config/keycloak/kcContext";
import { KcFftContextBase } from "./config/keycloak/KcFftContextBase";

export default axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': process.env.CORS_POLICY as string,
    'x-api-key': process.env.X_API_KEY as string,
    'X-Gravitee-Api-Key': process.env.X_GRAVITEE_API_KEY as string,
  }
});

const baseURLIam = (kcContext !== undefined)
  ? (kcContext as KcFftContextBase).baseUrlIam
  : (process.env.BASE_URL_IAM !== undefined)
    ? process.env.BASE_URL_IAM
    : '';

export const identityHttp = axios.create({
  baseURL: baseURLIam,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': process.env.CORS_POLICY as string,
    'x-api-key': process.env.X_API_KEY as string,
  }
});
