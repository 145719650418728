/* eslint-disable @typescript-eslint/no-explicit-any */
import alertCodes from '../resources/alerts.json';

export interface AlertMessage {
    type?: 'success' | 'warning' | 'error' | 'info',
    summary?: string
}

const DEFAULT_KEY = 'fft.reset.default';

export function getAlertTypeFromCode(alertCode: string): string {
    return getAlertSummaryFromCode(alertCode).split('.')[1];
}

export function getAlertSummaryFromCode(alertCode: string): string {
    return (Object.keys(alertCodes).includes(alertCode))
        ? (alertCodes as any)[alertCode]
        : (alertCodes as any)[DEFAULT_KEY];
}
