import { KcProps } from 'keycloakify';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KcContext } from "../../../../config/keycloak/kcContext";
import { Country } from "../../../../model/Country";
import storageService from '../../../../services/storage.service';
import { getClassName } from '../../../../utils/getClassFunctions';
import tcPage from '../../../../utils/hooks/tcPage';
import { ValidEmailRegex, ValidPasswordRegex } from '../../../../utils/Regex';
import { TcTemplates } from '../../../../utils/TcVars';
import Template from '../../../../view/Template';
import CardFlatButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent';
import CardButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import { DefaultCountry } from '../../../shared/DropdownComponent/CountryDropdownComponent/CountryDropdown';
import InputPasswordComponent from "../../../shared/InputComponent/InputPassswordComponent/InputPasswordComponent";
import InputTextComponent from '../../../shared/InputComponent/InputTextComponent/InputTextComponent';
import KcAlert from "../../KcAlert/KcAlert";
import CountryComponent from "../../../shared/SearchComponent/CountryComponent";
import './RegisterCredentials.scss';

type KcContext_RegisterCredentials = Extract<KcContext, { pageId: 'register-credentials.ftl' }>;

const RegisterCredentials = ({ applicationId, doFetchDefaultThemeResources = true, kcContext, ...props }: { applicationId: string, doFetchDefaultThemeResources?: boolean, kcContext: KcContext_RegisterCredentials; } & KcProps) => {

  const { t } = useTranslation();
  tcPage(TcTemplates.CREATE_ACCOUNT.templateName, TcTemplates.CREATE_ACCOUNT.pages.credentials, kcContext.client.clientId);
  const { url, message } = kcContext;

  useEffect(() => {
    storageService.clear();
  }, []);

  // EMAIL INPUT
  const [email, setEmail] = useState(storageService.getEmail());
  const [emailInvalid, setEmailInvalid] = useState(false);
  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailInvalid(!ValidEmailRegex.test(newEmail));
    if (newEmail.length === 0)
      setEmailInvalid(false);
    if (!usernameHasBeenChange) {
      setUsername(event.target.value);
    }
  };

  // USERNAME INPUT
  const [username, setUsername] = useState(storageService.getUsername());
  const [usernameHasBeenChange, setUsernameHasBeenChange] = useState(false);
  const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    setUsernameHasBeenChange(true);
    setUsername(event.target.value);
  };

  // PASSWORD INPUT
  const [password, setPassword] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false)
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordInvalid(!ValidPasswordRegex.test(event.target.value));
  }

  // PHONE COUNTRY
  const [selectedCountry, setSelectedCountry] = useState<Country>(storageService.getCountry() || DefaultCountry);

  const onChangeCountry = (country: Country) => {
    setSelectedCountry(country);
    updatePhoneValidity(phone, country);
  }
  const getDialCode = (): string => selectedCountry.position === 0 ? '' : selectedCountry.phoneNumberPrefix.toString() ;

  // PHONE INPUT
  const [phone, setPhone] = useState(storageService.getPhone() || "");
  const [formattedPhone, setFormattedPhone] = useState(formatPhone());
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  function getPhoneInvalid(): string {
    return phoneInvalid ? ' invalid' : '';
  }
  function formatPhone(): string {
    if (storageService.getPhone() && storageService.getCountry()
        && isValidPhoneNumber(storageService.getPhone(), storageService.getCountry().codeAlpha2 as CountryCode)) {
      const phoneNumber = parsePhoneNumber(storageService.getPhone(), storageService.getCountry().codeAlpha2 as CountryCode);
      return phoneNumber.number;
    } else {
      return '';
    }
  }

  function updatePhoneValidity(phoneInput: string, country: Country) {
    if (isValidPhoneNumber(phoneInput, country.codeAlpha2 as CountryCode)) {
      const phoneNumber = parsePhoneNumber(phoneInput, country.codeAlpha2 as CountryCode);
      setFormattedPhone(phoneNumber.number);
      setPhoneInvalid(false);
    } else {
      setFormattedPhone('');
      setPhoneInvalid(true);
    }
  }

  const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    const phoneInput = event.target.value.replace(/\D/gi, '');
    setPhone(phoneInput);

    if (phoneInput.length === 0) {
      setPhoneInvalid(false);
      setFormattedPhone('');
    }
    else {
      updatePhoneValidity(phoneInput, selectedCountry);
    }
  }

  // CONTINUE
  const [canContinue, setCanContinue] = useState(false);
  useEffect(() => {
    setCanContinue(username !== '' && email !== '' && !emailInvalid && password !== '' && !passwordInvalid && phone !== undefined && phone !== '' && !phoneInvalid && selectedCountry.position !== 0);
  });

  const handleSubmit = () => {
    storageService.setEmail(email);
    storageService.setUsername(username);
    storageService.setCountry(selectedCountry);
    storageService.setPhone(phone);
  }

  const [countryIsInvalid, setCountryIsInvalid] = useState(false);



  return (
      <Template
          {... { kcContext, ...props }}
          doFetchDefaultThemeResources={doFetchDefaultThemeResources}
          headerTitleNode={t('register.header.title')}
          headerBoldSubtitleIds={['subtitle2']}
          headerStepperNode={2}
          formNode={
            <div>

              {message !== undefined && <KcAlert errorType={message.type} code={message.code} kcContext={kcContext}/>}

              <div className="fft-card-form">
                <form id="register-credentials-form" action={url.loginAction} method="post">
                  {/* HIDDEN EMAIL*/}
                  <div style={{display: 'none'}}>
                    <legend>{t('global.form.hiddenEmail')}</legend>
                    <input name='hiddenEmail'
                           type='text'
                           autoComplete='false'
                           aria-required
                           aria-label={t('global.form.hiddenEmail')} />
                  </div>
                  {/* EMAIL */}
                  <InputTextComponent
                      name={'email'}
                      value={email}
                      function={onChangeEmail}
                      label={t('global.form.email')}
                      className={getClassName(false, emailInvalid)}
                      invalid={emailInvalid} errorMessage={t('global.form.emailInvalid')}
                      type={'text'}
                      inputMode='email'
                  />

                  {/* USERNAME */}
                  <InputTextComponent
                      name={'username'}
                      value={username}
                      function={onChangeUsername}
                      label={t('global.form.login')}
                      className={getClassName(false, false)}
                  />

                  {/* PASSWORD */}
                  <InputPasswordComponent onChange={onChangePassword} legendCode={t('global.form.password')} tabIndex={3} />

                  {/* PHONE */}
                  <div id={'country-phone-div'}>
                    {/* SELECT COUNTRY PHONE */}
                    <CountryComponent onChangeCountry={onChangeCountry} selectedCountry={selectedCountry} countryIsInvalid={countryIsInvalid} setCountryIsInvalid={setCountryIsInvalid} tabIndex={4}/>
                    <div id='phone-input' className={'fft-card-form-item' + (phoneInvalid ? ' invalid' : '')}>
                      <div>

                        {/* PHONE INPUT */}
                        <fieldset id='phone' className={getPhoneInvalid()}>
                          <legend>{t('global.form.phone')}</legend>
                          <div id='phone-input-content'>
                            <span>{getDialCode()}</span>
                            <input
                                onChange={onChangePhone}
                                value={phone}
                                type='tel' tabIndex={5}
                                autoComplete='true'
                                aria-required aria-label={t('global.form.phone')}
                                enterKeyHint='next' inputMode='tel'
                            />
                            <input name={'phone'} value={ formattedPhone } hidden={true}/>
                          </div>
                        </fieldset>
                        {phoneInvalid && <span id='phone-invalid' className='invalid'>
                          {t('global.form.phoneInvalid')}
                        </span>}
                      </div>
                    </div>
                  </div>

                  {/* CONTINUE BUTTON */}
                  <CardButtonComponent
                      text={t('global.button.submit.continue')}
                      filled={true}
                      disabled={!canContinue}
                      applicationId={applicationId}
                      handleSubmit={handleSubmit}
                  />

                </form>
                <CardFlatButtonComponent />
              </div>
            </div>
          }
      />
  );
};

RegisterCredentials.displayName = 'RegisterCredentials';
export default RegisterCredentials;
