import { AxiosError } from 'axios';
import { Buffer } from 'buffer';
import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ClientContext } from '../../../context/ClientContext';
import { getAlertSummaryFromCode } from '../../../model/AlertMessage';
import resetEmailService from '../../../services/reset-email.service';
import storageService from "../../../services/storage.service";
import tagService from '../../../services/tag.service';
import { getClassName } from '../../../utils/getClassFunctions';
import tcPage from '../../../utils/hooks/tcPage';
import { ValidEmailRegex } from '../../../utils/Regex';
import { TcEventId, TcEventName, TcEventType, TcTemplates } from '../../../utils/TcVars';
import AlertComponent from '../../shared/AlertComponent/AlertComponent';
import CardButtonComponent from '../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import CardFlatButtonComponent from '../../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent';
import CardHeaderComponent from '../../shared/CardComponent/CardHeaderComponent/CardHeaderComponent';
import { ResetType } from '../../shared/ConfirmReset/ConfirmReset';
import InputTextComponent from '../../shared/InputComponent/InputTextComponent/InputTextComponent';
import './UpdateEmail.scss';
import { useNavigate } from 'react-router-dom';

interface UpdateEmailProps {
  applicationId: string;
}

export default function UpdateEmail(props: UpdateEmailProps) {
  const { t, i18n } = useTranslation();

  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.RESET_EMAIL.templateName, TcTemplates.RESET_EMAIL.pages.updateEmail, clientContext.clientId);

  const idCrm: string = storageService.getIdCrm();
  const code: string = storageService.getCode();

  const [message, setMessage] = useState({ type: '', summary: '' });
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(true);
  const navigate = useNavigate();

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    if (value.length === 0)
      setEmailIsValid(true);
    else
      setEmailIsValid(ValidEmailRegex.test(value));
  }

  const onChangeEmailConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailConfirm(event.target.value);
  };

  const isEmailValid = (): boolean => ValidEmailRegex.test(email) && email === emailConfirm;

  const handleUpdateEmail = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    resetEmailService.updateEmail(email, code, idCrm, i18n.language, clientContext.clientId, clientContext.templateId)
      .then(() => {
        tagService.sendEvent(TcEventId.RESET_EMAIL, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
        storageService.setResetType(ResetType.email, maskEmail());
        const toLoginStr = Buffer.from(storageService.getUrlLoginBase64(), 'base64').toString();
        const toLoginURI = new URL(toLoginStr);

        if (toLoginURI.searchParams.get('locale')) {
          toLoginURI.searchParams.set('locale', i18n.language);
        } else {
          toLoginURI.searchParams.append('locale', i18n.language);
        }

        navigate("/reset/email/success");
      })
      .catch((error: AxiosError) => {
        setStateErrorCode(error);
      });
  }

  function setStateErrorCode(error: AxiosError) {
    setMessage({
      type: 'error',
      summary: getAlertSummaryFromCode(error.response?.data?.errorCode)
    });
  }

  function maskEmail(): string {
    const emailParts = email.split('@');
    const endEmailParts = emailParts[1].split('.');
    return email.charAt(0) + new Array(emailParts[0].length - 1).fill('x').join('')
      + "@"
      + endEmailParts[0].charAt(0) + new Array(endEmailParts[0].length - 1).fill('x').join('')
      + '.'
      + endEmailParts[1].charAt(0) + new Array(endEmailParts[1].length - 1).fill('x').join('');
  }

  function getEmailConfirmValid() {
    return (emailConfirm !== email) && emailConfirm !== '';
  }

  return (
    <div className='reset-email'>
      <div>

        <CardHeaderComponent
          title={t('resetEmail.title')}
          subtitle1={t('resetEmail.updateEmail.header.subtitle1')}
          subtitle2={t('resetEmail.updateEmail.header.subtitle2')}
          boldSubtitleIds={['subtitle2']}
        />

        <AlertComponent type={message.type} summary={message.summary} />

        <div className='fft-card-form'>
          <form id="update-email-form" onSubmit={handleUpdateEmail}>
            <InputTextComponent name={'email'} function={onChangeEmail}
              label={t('global.form.email')}
              className={getClassName(false, !emailIsValid)}
              invalid={!emailIsValid}
              errorMessage={t('global.form.emailInvalid')}
              type={'text'}
              inputMode='email'
            />

            <InputTextComponent
              name={getEmailConfirmValid()
                ? 'confirm-email-error'
                : 'confirm-email'}
              function={onChangeEmailConfirm}
              label={t('resetEmail.updateEmail.form.emailConfirm')}
              className={getClassName(false, getEmailConfirmValid())}
              invalid={getEmailConfirmValid()}
              type={'text'}
              errorMessage={t('resetEmail.updateEmail.form.errorMailDifferent')}
              inputMode='email'
            />

            <CardButtonComponent
              text={t('global.button.submit.validate')}
              filled={true}
              disabled={!isEmailValid()}
              applicationId={props.applicationId}
            />
          </form>

          <CardFlatButtonComponent />

        </div>
      </div>
    </div>
  );
}
