import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Country } from "../../../../model/Country";
import storageService from "../../../../services/storage.service";
import { getClassName } from "../../../../utils/getClassFunctions";
import { ValidEmailRegex } from "../../../../utils/Regex";
import InputTextComponent from "../../InputComponent/InputTextComponent/InputTextComponent";
import './InformationComponent.scss';
import CountryComponent from "../../SearchComponent/CountryComponent";

type InformationComponentProps = {
  email: string,
  setEmail: Dispatch<SetStateAction<string>>,
  phone: string,
  setPhone: Dispatch<SetStateAction<string>>,
  isFormValid: Dispatch<SetStateAction<boolean>>,
  startTabIndexAt: number,
  selectedCountry: Country,
  setSelectedCountry: Dispatch<SetStateAction<Country>>
}

export default function InformationComponent(props: InformationComponentProps) {
  const { t } = useTranslation();

  // COUNTRY
  const onChangeCountry = (country: Country) => {
    props.setSelectedCountry(country);
    // setDialCode(country.phoneNumberPrefix.toString())
    checkPhoneValidity(props.phone, country.codeAlpha2 as CountryCode);
  }
  const [countryIsInvalid, setCountryIsInvalid] = useState(false);

  // PHONE
  const getDialCode = (): string => props.selectedCountry.position === 0 ? '' : props.selectedCountry.phoneNumberPrefix.toString();
  const [formattedPhone, setFormattedPhone] = useState(storageService.getPhone() || (props.selectedCountry.phoneNumberPrefix + props.phone));
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  // const [dialCode, setDialCode] = useState(props.selectedCountry.phoneNumberPrefix.toString());
  const getClassNameForTelInput = () => !phoneIsValid ? 'invalid' : '';

  function checkPhoneValidity(phone: string, countryCode: CountryCode) {
    if (phone.length === 0) {
      setPhoneIsValid(false);
      setFormattedPhone('');
    }
    else {
      if (isValidPhoneNumber(phone, countryCode)) {
        const phoneNumber = parsePhoneNumber(phone, countryCode as CountryCode);
        setFormattedPhone(phoneNumber.number);
        setPhoneIsValid(true);
      } else {
        setFormattedPhone('');
        setPhoneIsValid(false);
      }
    }
  }

  function onChangePhone(event: ChangeEvent<HTMLInputElement>) {
    const phoneInput = event.target.value.replace(/\D/gi, '');
    props.setPhone(phoneInput);
    checkPhoneValidity(phoneInput, props.selectedCountry.codeAlpha2 as CountryCode);
  }

  // EMAIL
  const [emailIsValid, setEmailIsValid] = useState(true);
  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    props.setEmail(event.target.value);
    setEmailIsValid(event.target.value.length !== 0 && ValidEmailRegex.test(event.target.value))
  }

  useEffect(() => {
    props.isFormValid(emailIsValid && phoneIsValid && !countryIsInvalid);
  }, [emailIsValid, phoneIsValid])

  useEffect(() => {
    storageService.clear();
    checkPhoneValidity(props.phone, props.selectedCountry.codeAlpha2 as CountryCode)
  }, [])

  return (
    <>
      <h3>{t('updateAccount.subComponent.coordinates.title')}</h3>
      <div id="coordinates-div" >
        <div id='update-information' className={getClassName(false, false)}>

          <InputTextComponent name={'email'}
            function={onChangeEmail}
            label={t('global.form.email')}
            className={getClassName(false, !emailIsValid)}
            invalid={!emailIsValid} errorMessage={t('global.form.emailInvalid')}
            type={'text'}
            value={props.email}
            tabIndex={props.startTabIndexAt + 0}
            inputMode='email'
          />
          <div id={'country-phone-div'}>
            {/* SELECT COUNTRY */}
            <CountryComponent onChangeCountry={onChangeCountry} selectedCountry={props.selectedCountry} setCountryIsInvalid={setCountryIsInvalid} countryIsInvalid={countryIsInvalid} tabIndex={props.startTabIndexAt + 1}/>
            <div id='phone-inputs-wrapper' className={'no-legend'}>
            {/* PHONE INPUT */}
            <div id='phone'
              className={getClassNameForTelInput()}>
              <fieldset >
                <legend>{t('global.form.phone')}</legend>
                <div id='phone-input-content'>
                  <span>{getDialCode()}</span>
                  <input
                    value={props.phone}
                    onChange={onChangePhone}
                    type='tel'
                    autoComplete='true'
                    aria-required aria-label={t('global.form.phone')}
                    tabIndex={props.startTabIndexAt + 2}
                    enterKeyHint='next' inputMode='tel'
                  />
                </div>
              </fieldset>
              {!phoneIsValid && <span id='phone-invalid' className='invalid'>
                {t('global.form.phoneInvalid')}
              </span>}
            </div>
            <input name='phone' value={formattedPhone} type='hidden' readOnly />
          </div>
        </div>
      </div >
      </div>
    </>
  );
}

