import './CardButtonComponent.scss';

export interface CardButtonProps {
  text: string;
  filled: boolean;
  disabled: boolean;
  applicationId: string;
  handleSubmit?: (event: { preventDefault: () => void; }) => void;
  tabIndex?: number;
  name?: string
}

export default function CardButtonComponent(props: CardButtonProps) {
  return (
    <div className='fft-card-button'>
      <button id={props.applicationId}
              name={props.name}
              data-testid={props.applicationId}
              className={props.filled ? 'filled' : ''}
              type='submit' tabIndex={props.tabIndex ? props.tabIndex : 10}
              disabled={props.disabled}
              onClick={props.handleSubmit}>
        <span>{props.text}</span>
      </button>
    </div>
  );
}
