import { defaultKcProps, KcProps as KcPropsBase} from 'keycloakify';
import { assert } from "tsafe/assert";

export const kcFftProps = {
    ...defaultKcProps,
    "stylesCommon": [],
    "styles": [],
} as const;

assert<typeof kcFftProps extends KcPropsBase ? true : false>();
