import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import './OptinComponent.scss';

export interface OptinProps {
    name: string,
    setOptin: Dispatch<SetStateAction<boolean | undefined>>,
    optin: boolean | undefined,
    description: string,
    isHidden: boolean,
    applicationId: string,
    startTabIndexAt: number
}

export default function OptinComponent(props: OptinProps) {

    const check = (value: boolean | undefined) => {
        return (props.optin === undefined ? undefined : value);
    }

    const onCheck = (e: React.FormEvent<HTMLInputElement>) => {
        props.setOptin(e.currentTarget.value === 'true');
    };

    const BLOCK = { display: 'block' }
    const NONE= { display: 'none' }
    const ISHIDDEN = props.isHidden ? NONE : BLOCK;

    return (
        <div className="register-optin" style={ISHIDDEN}>
            <span data-testid='description'>
                {
                    props.description
                }
            </span>
            <div className="fft-card-form-radio">
                <div className='radio'>
                    <input
                        id={props.applicationId}
                        data-testid={'yes'}
                        type='radio'
                        value={String(true)}
                        name={props.name}
                        checked={check(props.optin)}
                        onChange={onCheck}
                        tabIndex={props.startTabIndexAt + 0}
                    />
                    <label id={props.applicationId}>
                        {t('register.optins.yes')}
                    </label>
                </div>
                <div className='radio'>
                    <input
                        id={props.applicationId}
                        data-testid={'no'}
                        type='radio'
                        value={String(false)}
                        name={props.name}
                        checked={check(!props.optin)}
                        onChange={onCheck}
                        tabIndex={props.startTabIndexAt + 1}
                    />
                    <label>
                        {t('register.optins.no')}
                    </label>
                </div>
            </div>
        </div>
    );
}

