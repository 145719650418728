import { identityHttp } from '../http-common';

class CountryService {

  private route = '/datahub/countries';

  public getCountries() {
    return identityHttp.get(this.route + `?activeOnly=true`);
  }
}

export default new CountryService();