import MessageKey, { getKcContext } from 'keycloakify';
import { ClientConfig } from '../../model/ClientConfig';
import { UpdateAccountUser, CredentialUser, ContactUser, OptinsUser } from '../../model/User';
export type ErrorType = 'success' | 'warning' | 'error' | 'info';

export const { kcContext } =
  getKcContext<{
    pageId: 'login.ftl';
    passwordResetRedirectUri: string;
    emailResetRedirectUri: string;
    baseUrlIam: string;
    clientConfig: ClientConfig;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
  } | {
    pageId: 'login-oauth-grant.ftl';
    clientConfig: ClientConfig;
    client: {
      name: string;
    };
    oauth: {
      code: string;
      url: string;
      clientScopesRequested: Array<{ consentScreenText: string }>;
    }
  }
      | {
    pageId: 'update-account.ftl';
    clientConfig: ClientConfig;
    baseUrlIam: string;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
    user: UpdateAccountUser;
    redirectUri: string;
  } | {
    pageId: 'update-credentials.ftl';
    clientConfig: ClientConfig;
    baseUrlIam: string;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
    user: CredentialUser;
    redirectUri: string;
  } | {
    pageId: 'update-contacts.ftl';
    clientConfig: ClientConfig;
    baseUrlIam: string;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
    user: ContactUser;
    redirectUri: string;
  } | {
    pageId: 'update-optins.ftl';
    clientConfig: ClientConfig;
    baseUrlIam: string;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
    user: OptinsUser;
    redirectUri: string;
  } | {
    pageId: 'login-update-password.ftl';
    clientConfig: ClientConfig;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
  } | {
    pageId: 'info.ftl';
    clientConfig: ClientConfig;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
  } | {
    pageId: 'register-quadruplet.ftl';
    baseUrlIam: string;
    clientConfig: ClientConfig;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
  } | {
    pageId: 'register-credentials.ftl';
    clientConfig: ClientConfig;
    baseUrlIam: string;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
  } | {
    pageId: 'register-optins.ftl';
    clientConfig: ClientConfig;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
  } | {
    pageId: 'error.ftl';
    clientConfig: ClientConfig;
    message?: {
      type: ErrorType;
      summary: string;
      code: typeof MessageKey;
    };
  }>({
    /* Uncomment a line to test its view */
    // 'mockPageId': 'login.ftl',
    // 'mockPageId': 'update-account.ftl',
    // 'mockPageId': 'login-oauth-grant.ftl',
    // 'mockPageId': 'update-credentials.ftl',
    // 'mockPageId': 'update-contacts.ftl',
    // 'mockPageId': 'update-optins.ftl',
    // 'mockPageId': 'login-update-password.ftl',
    //  'mockPageId': 'register-quadruplet.ftl',
    // 'mockPageId': 'register-credentials.ftl',
    // 'mockPageId': 'register-optins.ftl',
    // 'mockPageId': 'info.ftl',
    // 'mockPageId': 'error.ftl',
    'mockData': [
      // Login page
      {
        'pageId': 'login.ftl',
        'passwordResetRedirectUri': 'http://localhost:3000/reset/password?applicationId=',
        'emailResetRedirectUri': 'http://localhost:3000/reset/email?applicationId=',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'my super summary',
          'code': 'invalidUserMessage'
        }
      },
      // Login oauth page
      {
        'pageId': 'login-oauth-grant.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'client': {
          'name': '4padel'
        },
        'oauth': {
          'code': 'LkvFg1yx4A1yUfOAAS74BZ4nshTnf2ra07OSiaBRsGs',
          'clientScopesRequested': [
            {'consentScreenText': 'authorization.form.read.licence'},
            {'consentScreenText': 'authorization.form.write.licence'},
            {'consentScreenText': 'authorization.form.read.classement.padel'},
          ]
        }
      },
      // Update account page
      {
        'pageId': 'update-account.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'my super summary',
          'code': 'invalidUserMessage'
        },
        'user': {
          'username': 'foo.bar',
          'civility': 'M',
          'firstName': 'Foo',
          'lastName': 'Bar',
          'birthDate': '2000-01-01',
          'language': 'EN',
          'optinFFT': true,
          'optinPartenaire': false,
          'contacts': [
            {
              'id': '1',
              'detailContact': '649618403',
              'typeContact': 'MOBILE'
            }
          ]
        },
        'baseUrlIam': 'http://localhost:8081/v1',
        'redirectUri': 'http://localhost:3000'
      },
      // Update credentials page
      {
        'pageId': 'update-credentials.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'my super summary',
          'code': 'invalidUserMessage'
        },
        'user': {
          'username': 'foo.bar',
          'quadruplet': {
            'civility': 'M',
            'firstName': 'Foo',
            'lastName': 'Bar',
            'birthDate': '2000-01-01'
          }
        },
        'baseUrlIam': 'http://localhost:8081/v1',
        'redirectUri': 'http://localhost:3000'
      },
      // Update contacts page
      {
        'pageId': 'update-contacts.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'my super summary',
          'code': 'invalidUserMessage'
        },
        'user': {
          'civility': 'M',
          'firstName': 'Foo',
          'lastName': 'Bar',
          'birthDate': '2000-01-01',
          'language': 'EN',
          'contacts': [
            {
              'id': '1',
              'detailContact': '649618403',
              'typeContact': 'MOBILE'
            }
          ]
        },
        'baseUrlIam': 'http://localhost:8081/v1',
        'redirectUri': 'http://localhost:3000'
      },
      // Update optins page
      {
        'pageId': 'update-optins.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'my super summary',
          'code': 'invalidUserMessage'
        },
        'user': {
          'civility': 'M',
          'firstName': 'Foo',
          'lastName': 'Bar',
          'birthDate': '2000-01-01',
          'optinFFT': true,
          'optinPartenaire': false,
        },
        'baseUrlIam': 'http://localhost:8081/v1',
        'redirectUri': 'http://localhost:3000'
      },
      // Update password page
      {
        'pageId': 'login-update-password.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'my super summary',
          'code': 'invalidUserMessage'
        },
        'locale': {
          'currentLanguageTag': 'en'
        }
      },
      // Info page
      {
        'pageId': 'info.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'my super summary',
          'code': 'invalidUserMessage'
        }
      },
      // Register quadruplet page
      {
        'pageId': 'register-quadruplet.ftl',
        'baseUrlIam': 'http://localhost:8081/v1',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'this is the quadruplet page for registration',
          'code': 'invalidUserMessage'
        },
      },
      // Register credentials page
      {
        'pageId': 'register-credentials.ftl',
        'baseUrlIam': 'http://localhost:8081/v1',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'this is the credentials page for registration',
          'code': 'invalidUserMessage'
        }
      },
      // Register optins page
      {
        'pageId': 'register-optins.ftl',
        'clientConfig': {
          'templateId': 'FFT',
          'othersTemplateId': ['RG', 'TENUP', 'RPM']
        },
        'message': {
          'type': 'info',
          'summary': 'this is the optins page for registration',
          'code': 'invalidUserMessage'
        }
      },
      // Error page
      {
        'pageId': 'error.ftl',
        'clientConfig': {
          'templateId': 'RPM',
          'othersTemplateId': ['RG', 'TENUP', 'FFT']
        },
        'message': {
          'type': 'info',
          'summary': 'this is the optins page for registration',
          'code': 'invalidUserMessage'
        }
      }
    ]

  });

export type KcContext = NonNullable<typeof kcContext>;

const TestCommon = {
  url: {
    loginAction: '',
    resourcesPath: 'string',
    resourcesCommonPath: 'string',
    loginRestartFlowUrl: 'string',
    loginUrl: 'string'
  },
  realm: {
    name: 'string',
    internationalizationEnabled: false,
    registrationEmailAsUsername: true
  },
  scripts: [],
  client: {
    clientId: 'string',
    description: 'string'
  },
  isAppInitiatedAction: true,
  messagesPerField: {
    printIfExists: (...[,]) => undefined,
    existsError: (fieldName: string) => true,
    exists: (fieldName: string) => true,
    get: (fieldName: string) => 'true',
  },
  baseUrlIam: 'http://localhost:8081/v1',
  clientConfig: {
    templateId: 'FFT',
    othersTemplateId: ['RG', 'TENUP', 'RPM']
  }
}

export const RegisterQuadrupletTestContext = {
  ...TestCommon,
  pageId: 'register-quadruplet.ftl' as 'register-quadruplet.ftl'
}

export const RegisterQuadrupletPostalCodeNotRequiredTestContext = {
  ...TestCommon,
  pageId: 'register-quadruplet.ftl' as 'register-quadruplet.ftl'
}

export const RegisterCredentialsTestContext = {
  ...TestCommon,
  pageId: 'register-credentials.ftl' as 'register-credentials.ftl'
}

export const RegisterOptinsTestContext = {
  ...TestCommon,
  pageId: 'register-optins.ftl' as 'register-optins.ftl'
}
