import { Trans, useTranslation } from "react-i18next";
import alertIconError from '../../../resources/img/icons/alert/icon-message-error.svg';
import alertIconInfo from '../../../resources/img/icons/alert/icon-message-info.svg';
import alertIconSuccess from '../../../resources/img/icons/alert/icon-message-success.svg';
import alertIconWarning from '../../../resources/img/icons/alert/icon-message-warning.svg';
import { eventBus } from "../../../services/event-bus.service";
import './AlertComponent.scss';

interface AlertProps {
  type: string,
  summary: string,
}

export default function AlertComponent(props: AlertProps) {

  const { t } = useTranslation();

  const getAlertIconSrcFromAlertType = (alertType: string) => {
    switch (alertType) {
      case 'error':
        return alertIconError;
      case 'info':
        return alertIconInfo;
      case 'success':
        return alertIconSuccess;
      case 'warning':
        return alertIconWarning;
    }
  }

  const errorsContainingPopupLink = [
    'alert.error.reset.password.notFound',
    'alert.error.reset.email.phoneQuadrupletNotFound',
    'alert.error.reset.email.notFound',
    'alert.error.reset.email.code.wrong',
  ];

  if (props.summary !== '') {
    const error: string = t(props.summary);
    const alertIconSrc = getAlertIconSrcFromAlertType(props.type);

    return (
      <div className={'fft-alert ' + props.type}>
        <img src={alertIconSrc} alt={'Icône d\'alerte ' + props.type} />
        {
          errorsContainingPopupLink.indexOf(props.summary) !== -1 ?
            <div>
              <Trans i18nKey={props.summary}>
                <a onClick={() => eventBus.dispatch('openPopup', true)} />
              </Trans>
            </div>
            :
            <span dangerouslySetInnerHTML={{ "__html": error, }} />
        }
      </div>
    )

  } else {
    return (<div />)

  }
}
