import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Error from '../component/Error/Error';
import CheckCode from '../component/ResetEmail/CheckCode/CheckCode';
import ResetEmail from '../component/ResetEmail/ResetEmail/ResetEmail';
import UpdateEmail from '../component/ResetEmail/UpdateEmail/UpdateEmail';
import ResetPassword from '../component/ResetPassword/ResetPassword';
import SelectLogin from '../component/ResetPassword/SelectLogin/SelectLogin';
import ConfirmReset from '../component/shared/ConfirmReset/ConfirmReset';
import { KcContext, kcContext } from "../config/keycloak/kcContext";
import { kcFftProps } from '../config/keycloak/KcProps';
import KcInfo from '../component/KcComponent/KcInfo/KcInfo';
import Login from '../component/KcComponent/Login/Login';
import RegisterCredentials from '../component/KcComponent/Register/RegisterCredentials/RegisterCredentials';
import RegisterOptins from '../component/KcComponent/Register/RegisterOptins/RegisterOptins';
import RegisterQuadruplet from "../component/KcComponent/Register/RegisterQuadruplet/RegisterQuadruplet";
import UpdateAccount from "../component/KcComponent/UpdateAccount/UpdateAccount";
import UpdateContacts from "../component/KcComponent/UpdateAccount/UpdateAccountSplit/UpdateContact";
import UpdateCredentials from "../component/KcComponent/UpdateAccount/UpdateAccountSplit/UpdateCredentials";
import UpdateOptins from "../component/KcComponent/UpdateAccount/UpdateAccountSplit/UpdateOptins";
import UpdatePassword from "../component/KcComponent/UpdatePassword/UpdatePassword";
import ResetBoundary from '../component/ResetBoundary';
import LoginOauth from "../component/KcComponent/Auth/LoginOauth";

interface FftRouterProps {
  clientId: string;
  applicationId: string;
}

export default function FftRouter(props: FftRouterProps) {

  return (
    <div className='fft-router'>
      {
        <BrowserRouter basename='/'>
          <Routes>
            {kcContext !== undefined ? <Route path='*' element={getKcComponent(kcContext)} /> : <Route path='*' element={<Navigate to={'/error'} replace />} />}
            {/* RESET PASSWORD */}
            <Route path='reset/password' element={<ResetBoundary><ResetPassword applicationId={props.applicationId} clientId={props.clientId} /></ResetBoundary>} />
            <Route path='reset/select-login' element={<ResetBoundary><SelectLogin applicationId={props.applicationId} /></ResetBoundary>} />
            {/* RESET EMAIL */}
            <Route path='reset/email' element={<ResetBoundary><ResetEmail applicationId={props.applicationId} /></ResetBoundary>} />
            <Route path='reset/verify-code' element={<ResetBoundary><CheckCode applicationId={props.applicationId} /></ResetBoundary>} />
            <Route path='reset/update-email' element={<ResetBoundary><UpdateEmail applicationId={props.applicationId} /></ResetBoundary>} />
            <Route path='reset/email/success' element={<ResetBoundary><KcInfo applicationId={props.applicationId} {...{ ...kcFftProps, }} /></ResetBoundary>} />
            {/* CONFIRM RESET */}
            <Route path='reset/confirm-reset' element={<ResetBoundary><ConfirmReset /></ResetBoundary>} />
            {/* ERROR */}
            <Route path='/error' element={<ResetBoundary><Error applicationId={props.applicationId} clientId={props.clientId} /></ResetBoundary>} />
          </Routes>
        </BrowserRouter>
      }
    </div>
  )

  function getKcComponent(kcContext: KcContext) {
      switch (kcContext.pageId) {
          case 'login.ftl':
              return <ResetBoundary>
                  <Login applicationId={props.applicationId}
                         clientId={props.clientId}
                         kcContext={kcContext} {...{...kcFftProps,}} />
              </ResetBoundary>
          case 'login-oauth-grant.ftl':
              return <ResetBoundary>
                  <LoginOauth applicationId={props.applicationId}
                              clientId={props.clientId}
                              kcContext={kcContext} {...{...kcFftProps,}} />
              </ResetBoundary>
          case 'login-update-password.ftl':
              return <ResetBoundary>
                  <UpdatePassword applicationId={props.applicationId} kcContext={kcContext} {...{...kcFftProps,}} />
              </ResetBoundary>;
          case 'update-account.ftl':
              return <ResetBoundary>
                  <UpdateAccount applicationId={props.applicationId} kcContext={kcContext} {...{...kcFftProps,}} />
              </ResetBoundary>;
          case 'update-credentials.ftl':
              return <ResetBoundary>
                  <UpdateCredentials applicationId={props.applicationId} kcContext={kcContext} {...{...kcFftProps,}} />
              </ResetBoundary>;
          case 'update-contacts.ftl':
              return <ResetBoundary>
                  <UpdateContacts applicationId={props.applicationId} kcContext={kcContext} {...{...kcFftProps,}} />
              </ResetBoundary>;
          case 'update-optins.ftl':
              return <ResetBoundary>
                  <UpdateOptins applicationId={props.applicationId} kcContext={kcContext} {...{...kcFftProps,}} />
              </ResetBoundary>;
      case 'info.ftl':
        return <ResetBoundary>
                  <KcInfo applicationId={props.applicationId} kcContext={kcContext} {...{ ...kcFftProps, }} />
                </ResetBoundary>;
      case 'register-quadruplet.ftl':
        return <ResetBoundary>
                  <RegisterQuadruplet applicationId={props.applicationId} kcContext={kcContext} {...{ ...kcFftProps, }} />
                </ResetBoundary>;
      case 'register-credentials.ftl':
        return <ResetBoundary>
                  <RegisterCredentials applicationId={props.applicationId} kcContext={kcContext} {...{ ...kcFftProps, }} />
                </ResetBoundary>;
      case 'register-optins.ftl':
        return <ResetBoundary>
                  <RegisterOptins applicationId={props.applicationId} kcContext={kcContext} {...{ ...kcFftProps, }} />
                </ResetBoundary>;
      default:
        return <ResetBoundary>
                  <Error applicationId={props.applicationId} clientId={props.clientId} />
                </ResetBoundary>;
    }
  }
}
