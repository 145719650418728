import { KcProps } from 'keycloakify';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { KcContext } from '../../../config/keycloak/kcContext';
import { ClientContext } from '../../../context/ClientContext';
import tagService from '../../../services/tag.service';
import { getClassName } from '../../../utils/getClassFunctions';
import tcPage from '../../../utils/hooks/tcPage';
import { ValidPasswordRegex } from '../../../utils/Regex';
import { TcEventId, TcEventName, TcEventType, TcTemplates } from '../../../utils/TcVars';
import Template from '../../../view/Template';
import InputPasswordComponent from '../../shared/InputComponent/InputPassswordComponent/InputPasswordComponent';
import InputTextComponent from '../../shared/InputComponent/InputTextComponent/InputTextComponent';
import KcAlert from '../KcAlert/KcAlert';
import './UpdatePassword.scss';
import CheckboxComponent from "../../shared/CheckboxComponent/CheckboxComponent";

type KcContext_UpdatePassword = Extract<KcContext, { pageId: 'login-update-password.ftl'; }>;

const UpdatePassword = ({ applicationId, kcContext, ...props }: { applicationId: string, kcContext: KcContext_UpdatePassword; } & KcProps) => {

  const { t, i18n } = useTranslation();
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.RESET_PWD.templateName, TcTemplates.RESET_PWD.pages.updatePwd, clientContext.clientId);

  useEffect(() => {
    i18n.changeLanguage(kcContext.locale?.currentLanguageTag == 'fr' ? 'fr' : 'en');
  }, [])

  const { url } = kcContext;
  let { message } = kcContext
  const RESET_PASSWORD_MESSAGE = 'resetPasswordMessage';
  if (message?.code.name === RESET_PASSWORD_MESSAGE || message?.code.toString() === RESET_PASSWORD_MESSAGE) {
    message = undefined;
  }
  const [password, setPassword] = useState('');
  // eslint-disable-next-line
  const [disconnect, setDisconnect] = useState<boolean>(true);
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const onChangePasswordConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
  };

  const isPasswordValid = (): boolean =>
    ValidPasswordRegex.test(password) && password === passwordConfirm;

  const passWordValid = (): boolean => {
    return passwordConfirm !== password && passwordConfirm !== '';
  }

  const handleSubmit = (): void => {
    tagService.sendEvent(TcEventId.RESET_PASSWORD, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
  }

  return (
    <Template
      {...{ kcContext, ...props }}
      doFetchDefaultThemeResources={true}
      headerTitleNode={t('resetPassword.resetPassword.header.title')}
      headerSubtitleNode={[
        t('resetPassword.updatePassword.header.subtitle1'),
        t('resetPassword.updatePassword.header.subtitle2')
      ]}
      headerBoldSubtitleIds={['subtitle2']}
      formNode={
        <div>
          {message !== undefined && <KcAlert errorType={message.type} code={message.code} kcContext={kcContext}/>}
          <div className='fft-card-form'>
            <form id="reset-form" action={url.loginAction} method="post">
              <InputPasswordComponent onChange={onChangePassword} legendCode={t('resetPassword.updatePassword.form.newPassword')} tabIndex={0} />

              <InputTextComponent name={'password-confirm'} function={onChangePasswordConfirm}
                label={t('resetPassword.updatePassword.form.confirmNewPassword')}
                className={getClassName(false, passWordValid())}
                invalid={passWordValid()}
                errorMessage={t('keycloak.password_confirm_error')}
              />

              <CheckboxComponent id={'logout-sessions'} name={'logout-sessions'} setValue={setDisconnect} label={t('checkbox.label')}/>

              <div className='fft-card-button'>
                <button className='filled' disabled={!isPasswordValid()} id={applicationId} onClick={handleSubmit} type='submit'>
                  <span>{t('global.button.submit.validate')}</span>
                </button>
              </div>

            </form>
          </div>
        </div>
      }
    />
  );
};

UpdatePassword.displayName = 'UpdatePassword';
export default UpdatePassword;
