import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientContext } from "../../../context/ClientContext";
import iconOpenMail from '../../../resources/img/icons/icon-open-mail.svg';
import storageService from "../../../services/storage.service";
import tcPage from "../../../utils/hooks/tcPage";
import { TcTemplates } from "../../../utils/TcVars";
import CardFlatButtonComponent from "../CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent";
import './ConfirmReset.scss';

export enum ResetType { email, password }

function ConfirmReset() {

  const { t } = useTranslation();

  const resetType: number = storageService.getResetType();
  const hiddenResetCanal: string = storageService.getHiddenResetCanal();

  const clientContext = useContext(ClientContext);
  tcPage(
    resetType == 0 ? TcTemplates.RESET_EMAIL.templateName : TcTemplates.RESET_PWD.templateName,
    TcTemplates.RESET_PWD.pages.emailSent,
    clientContext.clientId);

  return (
    <div className='confirm-reset'>
      <div id='header'>
        <div id='title'>{t('confirmReset.title')}</div>
        <div id='icon'>
          <img src={iconOpenMail} alt={t('confirmReset.iconAlt')} />
        </div>
        <div id='subtitle'>
          <p>
            {t('confirmReset.' + ResetType[resetType] + '.subtitle1')}<b>{hiddenResetCanal}.</b>
            <br />
            {t('confirmReset.' + ResetType[resetType] + '.subtitle2')}
          </p>
        </div>
      </div>

      {
        resetType === ResetType.password &&
        <div id='subtitle'>
          <p>
            {t('confirmReset.' + ResetType[resetType] + '.notReceived')}
            <br />
            <a href={
              process.env.RESET_EMAIL_URL === undefined ? '' : 
              new URL(process.env.RESET_EMAIL_URL.replace('appId', clientContext.templateId).replace('clientId', clientContext.clientId)).pathname 
              +
              new URL(process.env.RESET_EMAIL_URL.replace('appId', clientContext.templateId).replace('clientId', clientContext.clientId)).search}>
              <b>{t('confirmReset.' + ResetType[resetType] + '.resetPassword')}</b>
            </a>
          </p>
        </div>
      }
      <CardFlatButtonComponent />
    </div>
  )
}

export default ConfirmReset
