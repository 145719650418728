import {Dispatch, SetStateAction} from "react";
import './CheckboxComponent.scss';

export interface CheckboxProps {
    id: string,
    setValue: Dispatch<SetStateAction<boolean>>,
    name: string,
    applicationId?: string,
    label: string,
}

export default function CheckboxComponent(props: CheckboxProps) {
    return (
        <div className="checkbox">
            <input
                id={props.id}
                type='checkbox'
                name={props.name}
                defaultChecked={true}
                onChange={(e) => props.setValue(e.target.checked)}
            />
            <label id={props.applicationId} htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    );
}

