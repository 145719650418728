import MessageKey, {MessageKeyBase, useI18n} from "keycloakify";
import { ErrorType, KcContext } from "../../../config/keycloak/kcContext";
import alertIconError from '../../../resources/img/icons/alert/icon-message-error.svg';
import alertIconInfo from '../../../resources/img/icons/alert/icon-message-info.svg';
import alertIconSuccess from '../../../resources/img/icons/alert/icon-message-success.svg';
import alertIconWarning from '../../../resources/img/icons/alert/icon-message-warning.svg';
import { useTranslation } from "react-i18next";
import "./KcAlert.scss"
import { getResetPasswordUrl } from "../../shared/CardComponent/CardComponent/CardComponent";

interface KcAlertProps {
  errorType: ErrorType,
  code: typeof MessageKey,
  applicationId?: string,
  kcContext: KcContext
}

export default function KcAlert(props: KcAlertProps) {
  const { t, i18n } = useTranslation();

  //TODO use the i18n of keycloak, but actually the change of languages is not on live, you have to refresh the page, that's why we keep the two
  const i18nKC = useI18n({
        kcContext: props.kcContext, extraMessages: {
          "en": {
            "multipleEmails": "The email address is associated with multiple accounts. Please try logging in again with your login.",
            "invalidUserMessage": "Login informations are incorrect. Please try again.",
            "accountTemporarilyDisabledMessage": "You have tried to login 10 times. Please try again in 1 min or reset your password.",
            "error.loginmultiple": "The login is already used",
            "error.logininvalid": "The identifier must be at least 6 characters long",
            "error.createIdentity": "An error has occurred, please try again later or contact customer service",
            "error.quadruplefound": "Your account already exists. If you wish to reset your password, ",
            "error.createAccount": "An error has occurred, please try again later or contact customer service",
            "error.updateAccount": "An error has occurred, please try again later or contact customer service",
            "error.mail.invalid": "Email address extension format is not valid",
            "invalid_user_credentials": "Current password is incorrect",
            "invalid_input": "Input informations are incorrect",
            "password_confirm_error": "The passwords entered are differents",
            "invalid_request": "An error has occurred, please try again later or contact customer service",
            "update_invalid_user_credentials": "The current password entered is wrong",
            "accountDisabledMessage": "Your account is blocked. Please contact our customer service.",
            "loginTimeout": "Your session has expired. Please try again.",
            "click_here": "click here"
          },
          "fr": {
            "multipleEmails": "L'adresse e-mail est associée à plusieurs comptes. Veuillez réessayer de vous connecter avec votre identifiant.",
            "invalidUserMessage": "Les informations de connexion sont incorrectes. Veuillez réessayer.",
            "accountTemporarilyDisabledMessage": "Vous avez essayé de vous connecter 10 fois. Réessayez dans 1 minute ou réinitialisez votre mot de passe.",
            "error.loginmultiple": "L'identifiant est déjà utilisé",
            "error.logininvalid": "L'identifiant doit avoir 6 caractères minimum",
            "error.createIdentity": "Une erreur s'est produite, veuillez réessayer plus tard ou contacter le service client",
            "error.quadruplefound": "Votre compte existe déjà. Si vous souhaitez réinitialiser votre mot de passe, ",
            "error.createAccount": "Une erreur s'est produite, veuillez réessayer plus tard ou contacter le service client",
            "error.updateAccount": "Une erreur s'est produite, veuillez réessayer plus tard ou contacter le service client",
            "error.mail.invalid": "L’extension de l’adresse email n’est pas valide",
            "invalid_user_credentials": "Le mot de passe saisi est erroné",
            "invalid_input": "Les données saisies sont incorrects",
            "password_confirm_error": "Les mots de passe saisis sont différents",
            "invalid_request": "Une erreur s'est produite, veuillez réessayer plus tard ou contacter le service client",
            "update_invalid_user_credentials": "Le mot de passe actuel saisi est erroné",
            "accountDisabledMessage": "Votre compte est bloqué. Veuillez contacter notre service client.",
            "loginTimeout": "Votre session a expiré en raison d'une trop longue inactivité. Merci de réessayer.",
            "click_here": "cliquez ici"
          }
        }
      }
  )


    const alertIconSrc = () => {
        switch (props.errorType) {
            case 'error':
                return alertIconError;
            case 'info':
                return alertIconInfo;
            case 'success':
                return alertIconSuccess;
            case 'warning':
                return alertIconWarning;
        }
    }

    return (
        <div className={'fft-alert ' + props.errorType}>
            <img src={alertIconSrc()} alt={"Icône d'alerte " + props.errorType}/>
            <span>
        {
            i18n.exists('keycloak.' + props.code) ? t('keycloak.' + props.code) : i18nKC?.msg(props.code as unknown as MessageKeyBase)
        }
                {
                    'keycloak.' + props.code == 'keycloak.error.quadruplefound' &&
                    <a href={getResetPasswordUrl({applicationId: props.applicationId || "", clientId: props.kcContext.client.clientId})}>
                        {i18n.exists('keycloak.click_here') ? t('keycloak.click_here') : i18nKC?.msg(props.code as unknown as MessageKeyBase)}
                    </a>

                }.
          </span>
        </div>
    )
}
