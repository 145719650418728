import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Home from './view/Home';

ReactDOM.render(
    <React.StrictMode>
        <Home />
    </React.StrictMode>,
    document.getElementById("root"),
);
