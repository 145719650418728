import { AxiosError } from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ClientContext } from "../../../context/ClientContext";
import { Account } from "../../../model/Account";
import { getAlertSummaryFromCode } from "../../../model/AlertMessage";
import { CredentialUser } from "../../../model/User";
import resetPasswordService from "../../../services/reset-password.service";
import tcPage from "../../../utils/hooks/tcPage";
import { TcEventId, TcEventName, TcEventType, TcTemplates } from "../../../utils/TcVars";
import AlertComponent from "../../shared/AlertComponent/AlertComponent";
import CardHeaderComponent from "../../shared/CardComponent/CardHeaderComponent/CardHeaderComponent";
import { useNavigate } from "react-router-dom";
import SelectLoginRow from "./SelectLoginRow/SelectLoginRow";
import './SelectLogin.scss';
import { renderToString } from "react-dom/server";
import tagService from "../../../services/tag.service";
import {TcConsentParam} from "../../../utils/hooks/tcConsentParam";
import storageService from "../../../services/storage.service";

type SelectLoginProps = {
  applicationId: string
}

export default function SelectLogin(props: SelectLoginProps) {

  const { t, i18n } = useTranslation();
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.RESET_PWD.templateName, TcTemplates.RESET_PWD.pages.selectLogin, clientContext.clientId);


  const navigate = useNavigate();

  const [accounts, setAccounts] = useState<Account[]>();

  const [email, setEmail] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setMessage] = useState({ type: '', summary: '' });

  useEffect(() => {
    const getAccountInformation = async () => {
      const passphrase = new URLSearchParams(window.location.search).get('info') || '';

      await resetPasswordService.getAccountsByPassphrase(passphrase)
        .then((response) => {
          setAccounts(response.data?.accounts);
          setEmail(response.data?.email);
        })
    }

    getAccountInformation()
      .catch((error) => {
        JSON.parse(error.request.response).message === 'fft.reset.update-email' ?
          navigate('/error') :
          setMessage({ type: 'error', summary: getAlertSummaryFromCode(error) });
      });

  }, [])


    useEffect(() => {
        const event_consent_analytics = new URLSearchParams(window.location.search).get('event_consent_analytics') || '';
        const event_consent_ad = new URLSearchParams(window.location.search).get('event_consent_ad') || '';
        const event_consent_personalization = new URLSearchParams(window.location.search).get('event_consent_personalization') || '';
        const event_device_tmp = new URLSearchParams(window.location.search).get('event_device') || '';
        const event_device:'web'|'app' = event_device_tmp !== 'web' && event_device_tmp !== 'app' ? 'web' : event_device_tmp;

        const event_consent_at = new URLSearchParams(window.location.search).get('event_consent_at') || '';
        const atuserid = new URLSearchParams(window.location.search).get('atuserid') || '';
        const tcConsentParams: TcConsentParam = {
            event_consent_analytics,
            event_consent_ad,
            event_consent_personalization,
            event_device,
            event_consent_at,
            atUserId:atuserid
        }
        storageService.setConsentParams(tcConsentParams)
    }, [])

  const [selectedAccount, setSelectedAccount] = useState<CredentialUser>({ username: "", idCrm: "", clientId: clientContext.clientId });

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    tagService.sendEvent(TcEventId.CLIC, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
    const user = {
      clientId: clientContext.clientId,
      username: '',
      idCrm: selectedAccount.idCrm,
    }

    await resetPasswordService.resetPasswordByLogin(user)
        .then(() => {
            setSuccess(true);
        })
        .catch((error: AxiosError) => {
            setMessage({ type: 'error', summary: getAlertSummaryFromCode(error.response?.data?.errorCode) });
        })
  }

  useEffect(() => setSuccess(false), [errorMessage.type])

  function getResetPasswordSuccessMessage(login: string) {
    return renderToString(
      <div>
        <div id={'bold'}><Trans i18nKey="resetPassword.selectLogin.alert.title" login={login}><u>{{ login }}</u></Trans></div>
        <br/>
        <div>{t('resetPassword.selectLogin.alert.subtitle')}</div>
      </div>
    );
  }

  function reinitialise(){
      setSuccess(false);
      setMessage({ type: '', summary: '' });
  }

  return (
    <div id='select-login'>

      <CardHeaderComponent
        title={t('resetPassword.resetPassword.header.title')}
        subtitle1={t('resetPassword.selectLogin.header.subtitle1', { email: email }) + "\n\n"}
        subtitle2={t('resetPassword.selectLogin.header.subtitle2') + "\n"}
        subtitle3={t('resetPassword.selectLogin.header.subtitle3')}
        boldSubtitleIds={['subtitle3']}
      />

      {/* ALERTS */}
      {success && <AlertComponent type={'success'} summary={getResetPasswordSuccessMessage(selectedAccount.username)} />}
      {errorMessage.summary !== '' && <AlertComponent type={errorMessage.type} summary={errorMessage.summary} />}

      {/* ACCOUNTS LIST */}
      <div className={"select-login-wrapper"} onClick={() => reinitialise()}>
        {accounts?.map((account) =>
          <SelectLoginRow login={account.login} quadruplet={account.quadruplet} idCrm={account.idCrm} lastConnection={account.lastConnection}
            selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} license={account.license} key={account.login}
            applicationId={props.applicationId}
          />
        )}
      </div>

      {/* SUBMIT BUTTON */}
      <div id='select-login-submit'>
        <div className='fft-card-button'>
          <button className='filled'
            id={props.applicationId}
            disabled={selectedAccount.username === ''}
            onClick={handleSubmit}
            type='submit'
          >
            <span>{t('global.button.submit.validate')}</span>
          </button>
        </div>
      </div>

    </div>
  )
}