import { useTranslation } from "react-i18next";
import { Quadruplet } from "../../../../model/User";
import QuadrupletInputComponent from "../../QuadrupletInputComponent/QuadrupletInputComponent";
import './IdentityComponent.scss';

export default function IdentityComponent(props: Quadruplet) {
    const { t } = useTranslation();
    return (
        <>
            <h3>{t('updateAccount.subComponent.identity.title')}</h3>
            <div id="identity-div" >
                <QuadrupletInputComponent
                    disabled={false}
                    readonly={true}
                    quadruplet={props}
                    startTabIndexAt={0}
                    setCivility={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    setLastname={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    setFirstname={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    setBirthdate={function (): void {
                        throw new Error("Function not implemented.");
                    }} />
            </div>
        </>
    );
}