import { KcProps } from 'keycloakify';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { KcContext } from '../../../../config/keycloak/kcContext';
import { ClientContext } from '../../../../context/ClientContext';
import tagService from '../../../../services/tag.service';
import tcPage from '../../../../utils/hooks/tcPage';
import { TcEventId, TcEventName, TcEventType, TcTemplates } from '../../../../utils/TcVars';
import Template from '../../../../view/Template';
import CardButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import CardTextButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardTextButton/CardTextButtonComponent';
import {
  defaultCountries,
  EmptyCountry
} from '../../../shared/DropdownComponent/CountryDropdownComponent/CountryDropdown';
import InformationComponent from '../../../shared/DetailComponent/InformationComponent/InformationComponent';
import KcAlert from '../../KcAlert/KcAlert';
import {getOriginalCountry, parseOriginalPhone} from "../../../../utils/Country";
import storageService from "../../../../services/storage.service";

type KcContext_UpdateContacts = Extract<KcContext, { pageId: 'update-contacts.ftl'; }>;

const UpdateContacts = ({ applicationId, kcContext, ...props }: { applicationId: string, kcContext: KcContext_UpdateContacts; } & KcProps) => {

  // TRADUCTION
  const { t, i18n } = useTranslation();

  // TAGGING
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.UPDATE_ACCOUNT.templateName, TcTemplates.UPDATE_ACCOUNT.pages.update_contacts, clientContext.clientId);
  const handleSubmit = (): void => {
    tagService.sendEvent(TcEventId.UPDATE_ACCOUNT, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
  }

  // KC VAR
  const { url, message, user, redirectUri } = kcContext;

  // USER DATAS
  const [isInformationFormValid, setIsInformationFormValid] = useState(true);
  const originalPhone = user.contacts.find(contact => contact.typeContact === 'MOBILE' && contact.primary)?.detailContact;
  const countries = storageService.getCountries() || defaultCountries;
  const [phone, setPhone] = useState(parseOriginalPhone(originalPhone, countries) || storageService.getPhone() || '');
  const [email, setEmail] = useState(user.contacts?.filter(c => c.typeContact === 'EMAIL')[0]?.detailContact);
  const [selectedCountry, setSelectedCountry] = useState(storageService.getCountry() || getOriginalCountry(originalPhone, countries) || EmptyCountry);

  return (
    <Template
      {...{ kcContext, ...props }}
      doFetchDefaultThemeResources={true}
      headerTitleNode={t('updateAccount.header.title')}
      headerSubtitleNode={[
        t('updateAccount.header.subtitle1')
      ]}
      headerBoldSubtitleIds={['subtitle2']}
      formNode={
        <div>
          {message !== undefined && <KcAlert errorType={message.type} code={message.code} kcContext={kcContext}/>}
          <div className='fft-card-form'>
            <form id="reset-form" action={url.loginAction} method="post">

              <InformationComponent email={email} phone={phone} isFormValid={setIsInformationFormValid} startTabIndexAt={9} setEmail={setEmail} setPhone={setPhone} setSelectedCountry={setSelectedCountry} selectedCountry={selectedCountry}/>

              <input name='language' value={i18n.language} readOnly type='hidden' />

              {/* REGISTER BUTTON */}
              <CardButtonComponent
                text={t('updateAccount.form.submit')}
                filled={true} disabled={!isInformationFormValid}
                applicationId={applicationId}
                handleSubmit={handleSubmit}
                tabIndex={15} />

              {/* CANCEL BUTTON */}
              <CardTextButtonComponent
                applicationId={applicationId}
                redirectUri={redirectUri}
                text={t('updateAccount.form.cancel')}
              />

            </form>
          </div>
        </div>
      }
    />
  );
};

UpdateContacts.displayName = 'UpdateContacts';
export default UpdateContacts;
