import http from '../http-common';
import { ResetEmailUser } from "../model/User";

class ResetEmailService {

    private route = '/email';

    public getCountriesCodes() {
        return http.get(this.route + '/countries');
    }

    public getPhoneInputValidity(phone: string, country: string) {
        return http.get(this.route + '/account' +
            `?phone=${encodeURIComponent(phone)}&country=${country}`);
    }

    public sendResetEmailRequest(user: ResetEmailUser) {
        const quadrupletRequest =
            `phone=${encodeURIComponent(user.phone)}&country=${user.country}
            &civility=${user.quadruplet?.civility}
            &firstname=${user.quadruplet?.firstName}
            &lastname=${user.quadruplet?.lastName}
            &birthdate=${user.quadruplet?.birthDate}`;

        return http.get(this.route + '/account/quadruplet' +
            `?${quadrupletRequest}`);
    }

    public sendResetCode(phone: string, idCrm: string) {
        return http.post(this.route + '/send-code', { 'phoneNumber': phone, 'idCrm': idCrm });
    }

    public checkResetCode(code: string, idCrm: string) {
        return http.post(this.route + '/check-code', { 'code': code, 'idCrm': idCrm });
    }

    public updateEmail(email: string, code: string, idCrm: string, language: string, clientId: string, templateId: string) {
        return http.post(this.route + '/update-email', { 'email': email, 'code': code, 'idCrm': idCrm,
            'language': language, 'clientId': clientId, 'templateId': templateId });
    }
}

export default new ResetEmailService();
