import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClientContext } from '../../../context/ClientContext';
import iconQuestionMark from '../../../resources/img/icons/icon-question-mark.svg';
import logoFFT from '../../../resources/img/logos/logo-FFT.svg';
import logoRG from '../../../resources/img/logos/logo-RG.svg';
import logoRPM from '../../../resources/img/logos/logo-RPM.svg';
import logoTenup from '../../../resources/img/logos/logo-TENUP-2.svg';
import storageService from '../../../services/storage.service';
import tcPage from '../../../utils/hooks/tcPage';
import { TcTemplates } from '../../../utils/TcVars';
import './HelpPopup.scss';

export interface HelpPopupProps {
  handleClose: () => void;
  resetEmailUrl: string;
  resetPasswordUrl: string,
  helpPageType: 'UPDATE' | 'UPDATE_CREDENTIALS' | 'UPDATE_CONTACTS' | 'UPDATE_OPTINS' | 'GENERAL';
}

interface app {
  logo: string;
  alt: string;
  link1: string;
  link2?: string;
  text1: string;
  text2?: string;
}

const HelpPopup = (props: HelpPopupProps) => {

  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.OTHERS.templateName, TcTemplates.OTHERS.pages.help, clientContext.clientId);

  const urlLoginBase64 = localStorage.getItem('urlLoginBase64') as string;
  let resetEmailRedirectUrl: URL | Location, resetPasswordRedirectUrl: URL | Location;
  try {
    resetEmailRedirectUrl = new URL(props.resetEmailUrl);
    resetEmailRedirectUrl.searchParams.append('state', urlLoginBase64);
    if (storageService.getLocale()) {
      resetEmailRedirectUrl.searchParams.append('locale', storageService.getLocale());
    }
    resetPasswordRedirectUrl = new URL(props.resetPasswordUrl);
    resetPasswordRedirectUrl.searchParams.append('state', urlLoginBase64);
    if (storageService.getLocale()) {
      resetPasswordRedirectUrl.searchParams.append('locale', storageService.getLocale());
    }
  } catch (error) {
    console.error(error);
    resetEmailRedirectUrl = window.location;
    resetPasswordRedirectUrl = window.location;
  }

  const getGeneral = (header: string, content: string, link?: string) => {
    return (
      <div className='section'>
        <div className='section-header'>{header}</div>
        <div className='content'>
          <Trans i18nKey={content}>{link && <a className='link-intext' href={link}>link</a>}</Trans>
        </div>
      </div>
    );
  }

  const getGeneralPassword = () => {
    return (
      getGeneral(t('helpPopup.password.header'), 'helpPopup.password.content', resetPasswordRedirectUrl.pathname + resetPasswordRedirectUrl.search)
    );
  }

  const getGeneralLogin = () => {
    return (
      getGeneral(t('helpPopup.login.header'), 'helpPopup.login.content', resetPasswordRedirectUrl.pathname + resetPasswordRedirectUrl.search)
    );
  }

  const getGeneralEmail = () => {
    return (
      getGeneral(t('helpPopup.email.header'), 'helpPopup.email.content', resetEmailRedirectUrl.pathname + resetEmailRedirectUrl.search)
    );
  }

  const getGeneralOther = () => {
    return (
      getGeneral(t('helpPopup.other.header'), 'helpPopup.other.content', resetPasswordRedirectUrl.pathname + resetPasswordRedirectUrl.search)
    );
  }

  const getUpdateIdentity = () => {
    return (
      getGeneral(t('helpPopup.updateIdentity.header'), 'helpPopup.updateIdentity.content')
    );
  }

  const getUpdateContacts = () => {
    return (
      getGeneral(t('helpPopup.updateContacts.header'), 'helpPopup.updateContacts.content')
    );
  }

  const getUpdateAccount = () => {
    return (
      getGeneral(t('helpPopup.updateAccount.header'), 'helpPopup.updateAccount.content')
    );
  }

  const getUpdateConsents = () => {
    return (
      getGeneral(t('helpPopup.updateConsents.header'), 'helpPopup.updateConsents.content')
    );
  }



  const getAllApps = () => {
    const apps: app[] = [
      {
        logo: logoRG,
        alt: 'RG',
        link1: process.env.RG_ESHOP_URL || '',
        link2: process.env.RG_TICKETING_URL || '',
        text1: t('helpPopup.footer.rg.shopping'),
        text2: t('helpPopup.footer.rg.tickets')
      },
      {
        logo: logoTenup,
        alt: "Ten'Up",
        link1: process.env.TENUP_URL || '',
        link2: 'https://weareballos.rolandgarros.com/',
        text1: t('helpPopup.footer.tenup.tenup'),
        text2: t('helpPopup.footer.tenup.ballPickers')
      },
      {
        logo: logoFFT,
        alt: 'FFT',
        link1: 'https://proshop.fft.fr/',
        link2: 'https://mon-espace-arbitrage.fft.fr/',
        text1: t('helpPopup.footer.fft.shopping'),
        text2: t('helpPopup.footer.fft.moja')
      },
      {
        logo: logoRPM,
        alt: 'RPM',
        link1: process.env.RPM_URL || '',
        text1: t('helpPopup.footer.rpm.rpm')
      }
    ]

    return (
      <div className='section-logos'>
        {apps.map((app, index) => {
          return (<div className='section-logo' key={index}>
            <div><img src={app.logo} alt={app.alt} /></div>
            <div className='links'>
              <a href={app.link1}
                target='_blank' rel='noopener noreferrer'>
                {app.text1}
              </a>
              {app.link2 && app.text2 &&
                < a href={app.link2}
                  target='_blank' rel='noopener noreferrer'>
                  {app.text2}
                </a>
              }
            </div>
          </div >)
        }

        )}
      </div>)
  }

  return (
    <div className='popup-box' onClick={props.handleClose}
    >

      <div className='box'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='header' id={clientContext.templateId}>
          <img src={iconQuestionMark} alt="Point d'interrogation" />
          <span>
            {t('global.footer.help')}
          </span>
          <span id='help-close' onClick={props.handleClose}>
            &times;
          </span>
        </div>
        <div className='container'>
          {/* PASSWORD */}
          {props.helpPageType === 'GENERAL' && getGeneralPassword()}
          {/* LOGIN */}
          {props.helpPageType === 'GENERAL' && getGeneralLogin()}
          {/* EMAIL */}
          {props.helpPageType === 'GENERAL' && getGeneralEmail()}
          {/* OTHER */}
          {props.helpPageType === 'GENERAL' && getGeneralOther()}
          {/* UPDATE IDENTITY */}
          {props.helpPageType === 'UPDATE' && getUpdateIdentity()}
          {/* UPDATE ACCOUNT */}
          {(props.helpPageType === 'UPDATE' || props.helpPageType === 'UPDATE_CREDENTIALS') && getUpdateAccount()}
          {/* UPDATE CONTACTS */}
          {(props.helpPageType === 'UPDATE' || props.helpPageType === 'UPDATE_CONTACTS') && getUpdateContacts()}
          {/* UPDATE CONSENTS */}
          {(props.helpPageType === 'UPDATE' || props.helpPageType === 'UPDATE_OPTINS') && getUpdateConsents()}
          {/* FOOTER */}
          <div className='section'>
            <span>{t('helpPopup.footer.text')}</span>
            {getAllApps()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPopup;