import { useContext } from "react";
import { ClientContext } from "../../../../context/ClientContext";
import './CardStepperComponent.scss';

interface CardStepperProps {
  step: number;
}

export default function CardStepperComponent(props: CardStepperProps) {

  const clientContext = useContext(ClientContext);
  const steps = [1, 2, 3]; 
  return (!steps.includes(props.step)) ? (<div />) : (
    <div className="fft-card-stepper">
      {
        //FIXME use this code instead of the bottom but the css is broke
      /* {
        steps.map(step =>
          <div key={step} className="boxY">
            <div id={clientContext.templateId} className={'circle' + (props.step >= step ? ' filled' : '')}>
              {step}
            </div>
            {steps.length >= (step + 1) &&
              <div className={'line' + (props.step > step ? ' test' : '')} id={clientContext.templateId} />
            }

          </div>
        )
      } */}
      
        <div id={clientContext.templateId} className={'circle' + (props.step >= 1 ? ' filled' : '')}>
          1
        </div>

        <div className={'line' + (props.step >= 2 ? ' filled' : '')} id={clientContext.templateId} />
        <div id={clientContext.templateId} className={'circle' + (props.step >= 2 ? ' filled' : '')}>
          2
        </div>

        <div className={'line' + (props.step >= 3 ? ' filled' : '')} id={clientContext.templateId} />
        <div id={clientContext.templateId} className={'circle' + (props.step >= 3 ? ' filled' : '')}>
          3
        </div>
      </div>
  )
}
