import { KcProps } from 'keycloakify';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { KcContext } from '../../../config/keycloak/kcContext';
import { ClientContext } from '../../../context/ClientContext';
import tagService from '../../../services/tag.service';
import tcPage from '../../../utils/hooks/tcPage';
import { TcEventId, TcEventName, TcEventType, TcTemplates } from '../../../utils/TcVars';
import Template from '../../../view/Template';
import CardButtonComponent from '../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import CardTextButtonComponent from '../../shared/CardComponent/CardButtonComponent/CardTextButton/CardTextButtonComponent';
import { AccountComponent } from '../../shared/DetailComponent/AccountComponent/AccountComponent';
import IdentityComponent from '../../shared/DetailComponent/IdentityComponent/IdentityComponent';
import InformationComponent from '../../shared/DetailComponent/InformationComponent/InformationComponent';
import OptinsComponent from '../../shared/DetailComponent/OptinsComponent/OptinsComponent';
import {
  defaultCountries,
  EmptyCountry
} from '../../shared/DropdownComponent/CountryDropdownComponent/CountryDropdown';
import KcAlert from '../KcAlert/KcAlert';
import storageService from "../../../services/storage.service";
import { getOriginalCountry, parseOriginalPhone } from "../../../utils/Country";
import { getCivilityFromKey } from "../../../model/enum/Civility.enum";

type KcContext_UpdateAccount = Extract<KcContext, { pageId: 'update-account.ftl'; }>;

const UpdateAccount = ({ applicationId, kcContext, ...props }: { applicationId: string, kcContext: KcContext_UpdateAccount; } & KcProps) => {

  // TRADUCTION
  const { t, i18n } = useTranslation();

  // TAGGING
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.UPDATE_ACCOUNT.templateName, TcTemplates.UPDATE_ACCOUNT.pages.update_account, clientContext.clientId);
  const handleSubmit = (): void => {
    storageService.setEmail(email);
    storageService.setPhone(selectedCountry.phoneNumberPrefix + phone);
    storageService.setCountry(selectedCountry)
    tagService.sendEvent(TcEventId.UPDATE_ACCOUNT, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
  }

  // KC VAR
  const { url, message, user, redirectUri } = kcContext;
  const countries = storageService.getCountries() || defaultCountries;


  // USER DATAS
  const quadruplet = { civility: user.civility, firstname: user.firstName, lastname: user.lastName, birthdate: moment(user.birthDate, 'YYYY-MM-DD', true).format('DD/MM/YYYY') };
  const [isAccountFormValid, setIsAccountFormValid] = useState(true);
  const [isInformationFormValid, setIsInformationFormValid] = useState(true);
  const [email, setEmail] = useState(user.contacts.find(contact => contact.typeContact === 'EMAIL')?.detailContact || storageService.getEmail() || '');
  const originalPhone = user.contacts.find(contact => contact.typeContact === 'MOBILE' && contact.primary)?.detailContact;
  const [phone, setPhone] = useState(parseOriginalPhone(originalPhone, countries) || storageService.getPhone() || '');
  const [fftOptin, setFFTOptin] = useState<boolean | undefined>(user.optinFFT);
  const [partnerOptin, setPartnerOptin] = useState<boolean | undefined>(user.optinPartenaire);

  // COUNTRY
  const [selectedCountry, setSelectedCountry] = useState(storageService.getCountry() || getOriginalCountry(originalPhone, countries) || EmptyCountry);
  const canSubmit = (): boolean => {
    return isAccountFormValid && isInformationFormValid && selectedCountry.position !== 0;
  }

  return (
    <Template
      {...{ kcContext, ...props }}
      doFetchDefaultThemeResources={true}
      headerTitleNode={t('updateAccount.header.title')}
      headerSubtitleNode={[
        t('updateAccount.header.subtitle1')
      ]}
      headerBoldSubtitleIds={['subtitle2']}
      formNode={
        <div>
          {message !== undefined && <KcAlert errorType={message.type} code={message.code} kcContext={kcContext}/>}
          <div className='fft-card-form'>
            <form id="reset-form" action={url.loginAction} method="post">
              <IdentityComponent civility={getCivilityFromKey(quadruplet.civility)} firstName={quadruplet.firstname} lastName={quadruplet.lastname} birthDate={quadruplet.birthdate} />

              <AccountComponent user={user} isFormValid={setIsAccountFormValid} startTabIndexAt={5} defaultShowDisconnect={false}/>

              <InformationComponent email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} isFormValid={setIsInformationFormValid} startTabIndexAt={9} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/>

              <OptinsComponent applicationId={applicationId} startTabIndexAt={11}
                optins={
                  [
                    [fftOptin, setFFTOptin, 'optinFFT', t('register.optins.optin2'), false],
                    [partnerOptin, setPartnerOptin, 'optinPartenaire', t('register.optins.optin3'),false]
                  ]
                } />

              <input name='language' value={i18n.language} readOnly type='hidden' />

              {/* REGISTER BUTTON */}
              <CardButtonComponent
                text={t('updateAccount.form.submit')}
                filled={true} disabled={!canSubmit()}
                applicationId={applicationId}
                handleSubmit={handleSubmit}
                tabIndex={15} />

              {/* CANCEL BUTTON */}
              <CardTextButtonComponent
                applicationId={applicationId}
                redirectUri={redirectUri}
                text={t('updateAccount.form.cancel')}
              />

            </form>
          </div>
        </div>
      }
    />
  );
};

UpdateAccount.displayName = 'UpdateAccount';
export default UpdateAccount;
