import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import OptinComponent from "../../OptinComponent/OptinsComponent/OptinComponent";
import './OptinsComponent.scss';

type Optins = {
    optins: [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>, string, string, boolean][] //optin setFunction nameOfTheOptin DescriptionOfTheOptin
    applicationId: string,
    startTabIndexAt: number
}

export default function OptinsComponent(props: Optins) {
    const [seeMore, setSeeMore] = useState(false);

    return (
        <>
            <h3>{t('updateAccount.subComponent.consents.title')}</h3>
            <div id="register-optins" >
                {props.optins.map(
                    ([optin, setOptin, name, description, isHidden], index) =>
                        <OptinComponent key={index} name={name}
                                        setOptin={setOptin}
                                        optin={optin}
                                        description={description}
                                        isHidden={isHidden}
                                        applicationId={props.applicationId}
                                        startTabIndexAt={props.startTabIndexAt + 2 * index} />)
                }

                {/* GDPR  */}
                <div className="register-optin">
                    <span>
                        {t('register.optins.rgpd.part1')}
                        {seeMore
                            ? <span>
                                {t('register.optins.rgpd.part2')}
                                <strong id="see-more" onClick={() => setSeeMore(!seeMore)}>
                                    {t('register.optins.rgpd.seeLess')}
                                </strong>
                            </span>
                            : <strong id="see-more" onClick={() => setSeeMore(!seeMore)}>
                                {t('register.optins.rgpd.seeMore')}
                            </strong>
                        }
                    </span>
                </div>
            </div >
        </>
    );
}

