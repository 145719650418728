import { DefaultCountry } from "../component/shared/DropdownComponent/CountryDropdownComponent/CountryDropdown";
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { Country } from "../model/Country";

/*
* Find the country according to the phone number
* @param: the phone number
* @return: the phone number's country
*/
export const getOriginalCountry = (originalPhone: string|undefined, countries: Country[]): Country => {
    if (originalPhone === undefined) return DefaultCountry;
    if (!originalPhone.startsWith('+') && !originalPhone.startsWith('0')) return DefaultCountry;
    const originalCountry = countries.filter(c => ('' + c.phoneNumberPrefix) !== '' && originalPhone.replace('+', '').startsWith(('' + c.phoneNumberPrefix).replace('+', '')))[0];
    return originalCountry === undefined ? DefaultCountry : originalCountry;
}

/*
* Parse the phone number according to the country
* @param: the phone number
* @return: the phone number formatted in national format
*/
export const parseOriginalPhone = (originalPhone: string|undefined, countries: Country[]): string|undefined => {
    const country = getOriginalCountry(originalPhone, countries);
    if(originalPhone !== undefined) {
        if (isValidPhoneNumber(originalPhone, country.codeAlpha2 as CountryCode)) {
            const phoneNumber = parsePhoneNumber(originalPhone, country.codeAlpha2 as CountryCode);
            return phoneNumber.nationalNumber;
        } else
            return originalPhone;
    }
}