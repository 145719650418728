
import { KcProps } from 'keycloakify';
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { KcContext } from "../../../../config/keycloak/kcContext";
import { ClientContext } from '../../../../context/ClientContext';
import tagService from '../../../../services/tag.service';
import tcPage from '../../../../utils/hooks/tcPage';
import { TcEventId, TcEventName, TcEventType, TcTemplates } from '../../../../utils/TcVars';
import Template from '../../../../view/Template';
import CardButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import CardFlatButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent';
import OptinsComponent from '../../../shared/DetailComponent/OptinsComponent/OptinsComponent';
import './RegisterOptins.scss';

type KcContext_RegisterOptins = Extract<KcContext, { pageId: 'register-optins.ftl' }>;

const RegisterOptins = ({ applicationId, doFetchDefaultThemeResources = true, kcContext, ...props }: { applicationId: string, doFetchDefaultThemeResources?: boolean, kcContext: KcContext_RegisterOptins; } & KcProps) => {

  const { t, i18n } = useTranslation();
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.CREATE_ACCOUNT.templateName, TcTemplates.CREATE_ACCOUNT.pages.optins, clientContext.clientId);
  const { url } = kcContext;
  const [fftOptinIsApproved, setFFTOptinIsApproved] = useState<boolean>();
  const [partnerOptinIsApproved, setPartnerOptinIsApproved] = useState<boolean>();
  const [hiddenOptinIsApproved, setHiddenOptinIsApproved] = useState<boolean>();

  const canSubmit = (): boolean => {
    return fftOptinIsApproved !== undefined && partnerOptinIsApproved !== undefined;
  }

  const handleSubmit = () => {
    tagService.sendEvent(TcEventId.REGISTER, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
  }

  return (
    <Template
      {... { kcContext, ...props }}
      doFetchDefaultThemeResources={doFetchDefaultThemeResources}
      headerTitleNode={t('register.header.title')}
      headerStepperNode={3}
      formNode={
        <div>
          <div className="fft-card-form">
            <form id="register-optins" action={url.loginAction} method="post">

              <OptinsComponent applicationId={applicationId} startTabIndexAt={0}
                optins={
                  [
                    [fftOptinIsApproved, setFFTOptinIsApproved, 'optinFFT', t('register.optins.optin2'), false],
                    [partnerOptinIsApproved, setPartnerOptinIsApproved, 'optinPartenaire', t('register.optins.optin3'), false],
                    [hiddenOptinIsApproved, setHiddenOptinIsApproved, 'optinHidden', t('register.optins.optin4'), true]//Hidden optin
                  ]
                } />

              {/* CONTINUE BUTTON */}
              <CardButtonComponent
                text={t('global.button.submit.continue')}
                filled={true}
                disabled={!canSubmit()}
                applicationId={applicationId}
                handleSubmit={handleSubmit}
              />

            </form>
            <CardFlatButtonComponent />
          </div>
        </div>
      }
    />
  );
};

RegisterOptins.displayName = 'RegisterOptins';
export default RegisterOptins;
