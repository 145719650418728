import tcConsentParam from "../utils/hooks/tcConsentParam";

interface ResetBoundaryProps {
  readonly children: JSX.Element | JSX.Element[];
}

export default function  ResetBoundary(props: ResetBoundaryProps) {
  tcConsentParam();

  return (
    <div>{props.children}</div>
  )

}