import http from '../http-common';
import { CredentialUser, ResetPasswordUser } from "../model/User";

class ResetPasswordService {

    private route = '/password';

    public resetPasswordByUser(user: ResetPasswordUser) {
        return http.post(this.route, {email: user.email, language: user.language, clientId: user.clientId, templateId: user.templateId,
            quadrupletForm:{
                civility: user.quadruplet?.civility,
                firstName: user.quadruplet?.firstName,
                lastName: user.quadruplet?.lastName,
                birthDate: user.quadruplet?.birthDate,
            }, tcParamsDto:{
                event_consent_analytics: user.event_consent_analytics,
                event_consent_ad: user.event_consent_ad,
                event_consent_personalization: user.event_consent_personalization,
                event_device: user.event_device,
                event_consent_at: user.event_consent_at,
                atuserid: user.atUserId
            }});
    }

    public resetPasswordByLogin(account: CredentialUser) {
        return http.post(this.route + '/login' + `?idCrm=${account.idCrm}&login=${account.username}&clientId=${account.clientId}`);
    }

    /**
     * Retrieve account list by decrypting list of idCrm in passphrase and
     * get accounts linked to them
     *
     * @param passphrase the encrypted passphrase passed as parameter in url
     * @returns a list of accounts related to encrypted list of idCrm
     */
    public getAccountsByPassphrase(passphrase: string) {
        return http.get(this.route + '/accounts', { params: { passphrase: passphrase }});
    }
}

export default new ResetPasswordService();
