import TC_Wrapper from 'react-tag-commander';
import { TcEventId, TcEventName, TcEventType, tcVarByAppId } from '../utils/TcVars';
import storageService from './storage.service';

class TagService {
  public sendEvent = (eventId: TcEventId, eventType: TcEventType, eventName: TcEventName, clientId: string, currentLangue: string) => {
    try {
      const envVar = tcVarByAppId.get(clientId)?.call(this, {
        production: window.location.href.startsWith('https://auth.fft.fr/'),
        currentLangue: currentLangue
      });
      const wrapper = TC_Wrapper.getInstance();
      wrapper.triggerEvent(eventId, this, { 'id_site_at': envVar?.idSite, 'env_work': envVar?.work, 'env_language': envVar?.language, 'event_type': eventType, 'event_name': eventName });
    } catch (error) {
      console.error('Tagging error:', error);
    }
  }

  public getTcConsentParams = () => {
    return 'event_consent_analytics=' + storageService.getConsentAnalytics() + '&'
      + 'event_consent_ad=' + storageService.getConsentAd() + '&'
      + 'event_consent_personalization=' + storageService.getConsentPerso() + '&'
      + 'event_device=' + storageService.getConsentDevice() + '&'
      + 'event_consent_at=' + storageService.getConsentAt() + '&'
      + 'atuserid=' + storageService.getAtUserId();
  }
}
export default new TagService();