import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Country } from "../../../../model/Country";
import './CountryDropdown.scss';

interface CountryDropdownProps {
  onSelectCountry: (country: Country) => void;
  countries?: Country[];
}

export default function CountryDropdown(props: CountryDropdownProps) {
  const { i18n } = useTranslation();

  let search = '';
  let timeoutSet = false;
  const focusCountryOnKeydown = (e: KeyboardEvent) => {
    if (/^[A-Za-z]$/.test(e.key) && !e.repeat) {
      search = search + e.key.toLocaleUpperCase();
      const index = i18n.language === 'fr' ?
        countries.findIndex(c => c.labelFr.toLocaleUpperCase().startsWith(search))
        : countries.findIndex(c => c.labelEn.toLocaleUpperCase().startsWith(search));
      const countryDropdown = document.querySelector('div#country-dropdown');
      if (index !== -1 && countryDropdown != null) {
        const topPos = document.getElementById(countries[index].code)?.offsetTop || 0;
        countryDropdown.scrollTop = topPos - 5;
      }
      // Start timeout only once at a time.
      // Reset search string a the end.
      if (!timeoutSet) {
        timeoutSet = true;
        setTimeout(() => {
          search = ''; timeoutSet = false
        }, 1000);
      }
    }
  };


  const [countries] = useState<Country[]>((props.countries === undefined ? defaultCountries : props.countries).sort((a: Country, b: Country) => a.position - b.position));

  useEffect(() => {
    document.addEventListener("keydown", focusCountryOnKeydown, false);
    return () => {
      document.removeEventListener("keydown", focusCountryOnKeydown, false);
    }
  }, []);


  return (
    <div id='country-dropdown'>
      {countries.map((country, index) => (
        <label className='country-select' id={country.code} key={index} onMouseDown={() => props.onSelectCountry(country)}>
          <span>{i18n.language === 'fr' ? country.labelFr : country.labelEn}</span>
        </label>
      ))}
    </div>
  )
}

export const EmptyCountry: Country = {
  labelFr: '',
  labelEn: '',
  code: '',
  codeAlpha2: '',
  phoneNumberPrefix: '',
  postalCodeFormat: '',
  position: 0
};

export const DefaultCountry: Country = {
  labelFr: 'France',
  labelEn: 'France',
  code: 'FRA',
  codeAlpha2: 'FR',
  phoneNumberPrefix: '+33',
  postalCodeFormat: '^(\\d{5})$',
  position: 1
};

export const defaultCountries: Country[] = [
  { labelFr: 'France', labelEn: 'France', code: 'FRA', codeAlpha2: 'FR', phoneNumberPrefix: '+33', postalCodeFormat: '^(\\d{5})$', position:1},
  { labelFr: 'Royaume-Uni', labelEn: 'United Kingdom', code: 'GBR', codeAlpha2: 'GB', phoneNumberPrefix: '+44', postalCodeFormat: '^(([A-Z]\\d{2}[A-Z]{2})|([A-Z]\\d{3}[A-Z]{2})|([A-Z]{2}\\d{2}[A-Z]{2})|([A-Z]{2}\\d{3}[A-Z]{2})|([A-Z]\\d[A-Z]\\d[A-Z]{2})|([A-Z]{2}\\d[A-Z]\\d[A-Z]{2})|(GIR0AA))$', position:2 },
  { labelFr: 'États-Unis', labelEn: 'United States', code: 'USA', codeAlpha2: 'US', phoneNumberPrefix: '+1201', postalCodeFormat: '^\\d{5}(-\\d{4})?$', position: 3},
  { labelFr: 'Belgique', labelEn: 'Belgium', code: 'BEL', codeAlpha2: 'BE', phoneNumberPrefix: '+32', postalCodeFormat: '^(\\d{4})$', position: 4},
  { labelFr: 'Allemagne', labelEn: 'Germany', code: 'DEU', codeAlpha2: 'DE', phoneNumberPrefix: '+49', postalCodeFormat: '^(\\d{5})$', position:5},
  { labelFr: 'Pays-Bas', labelEn: 'Netherlands', code: 'NLD', codeAlpha2: 'NL', phoneNumberPrefix: '+31', postalCodeFormat: '^(\\d{4}[A-Z]{2})$', position: 6},
  { labelFr: 'Espagne', labelEn: 'Spain', code: 'ESP', codeAlpha2: 'ES', phoneNumberPrefix: '+34', postalCodeFormat: '^(\\d{5})$', position: 7},
  { labelFr: 'Suisse', labelEn: 'Switzerland', code: 'CHE', codeAlpha2: 'CH', phoneNumberPrefix: '+41', postalCodeFormat: '^(\\d{4})$', position: 8},
  { labelFr: 'Italie', labelEn: 'Italy', code: 'ITA', codeAlpha2: 'IT', phoneNumberPrefix: '+39', postalCodeFormat: '^(\\d{5})$', position: 9},
  { labelFr: 'Brésil', labelEn: 'Brazil', code: 'BRA', codeAlpha2: 'BR', phoneNumberPrefix: '+55', postalCodeFormat: '^(\\d{8})$', position:10 }
];