import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CountryDropdown, {
    defaultCountries, EmptyCountry
} from "../DropdownComponent/CountryDropdownComponent/CountryDropdown";
import { Country } from "../../../model/Country";
import InputTextComponent from "../InputComponent/InputTextComponent/InputTextComponent";
import i18n, { t } from "i18next";
import { getClassName } from "../../../utils/getClassFunctions";
import countryService from "../../../services/country.service";
import './CountryComponent.scss'
import storageService from "../../../services/storage.service";


type CountryComponentProps = {
    selectedCountry: Country;
    onChangeCountry: (country: Country) => void;
    disabled?: boolean;
    tabIndex: number;
    setCountryIsInvalid: Dispatch<SetStateAction<boolean>>;
    countryIsInvalid: boolean;
}

const BOLD_FONT_FAMILY = "GothamBold, sans-serif"

export default function CountryComponent(props: CountryComponentProps) {

    const [openSelectCountry, setOpenSelectCountry] = useState(false);
    const [countries, setCountries] = useState<Country[]>(storageService.getCountries() || defaultCountries);

    useEffect(() => {
        const getCountries = async () => await countryService.getCountries()
            .then((response) => {
                setCountries(response.data);
            })

        getCountries().catch((error) => {
            console.error(error);
        });
    }, [])

    useEffect(() => storageService.setCountries(countries), [countries])


    function displayCountryInGoodLanguage(country: Country): string {
        return i18n.language === 'fr' ? country.labelFr : country.labelEn
    }

    useEffect(() => {
        getHtmlInputElement().value = (
            props.selectedCountry.position !== 0 ? displayCountryInGoodLanguage(props.selectedCountry) : '')
    }, [i18n.language])


    const onChangeCountry = (country: Country) => {
        props.onChangeCountry(country)
        const input = document.getElementsByName('countrySearch').item(0) as HTMLInputElement;
        input.value = displayCountryInGoodLanguage(country);
        setOpenSelectCountry(false);
    }

    function getDisplayedCountries(searchedCountry: string, inputEVent: string) {

        // If the user has a deleting action should iterate over all the countries to make sure that no one is missing
        // else you can use the list of the country already
        const countriesDisplayed = inputEVent === 'deleteContentBackward' ?
            getCountries() : getCountriesDisplayedOnScreen();
        let firstElement = true;
        //Looping over the list to filter which country we have to display
        for (let countryName, i = 0; i < countriesDisplayed.length; i++) {
            countryName = countriesDisplayed[i].getElementsByTagName("span")[0].outerText
                .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                .toUpperCase();
            if (countryName.indexOf(searchedCountry.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) > -1) {
                countriesDisplayed[i].style.fontFamily = firstElement ? BOLD_FONT_FAMILY : "";
                countriesDisplayed[i].style.display = "";
                firstElement = false;
            } else {
                countriesDisplayed[i].style.fontFamily = "";
                countriesDisplayed[i].style.display = "none";
            }
        }
        return getCountriesDisplayedOnScreen();
    }

    const onFilterCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const searchedCountry = event.target.value
        const inputEvent = event.nativeEvent as InputEvent
        if (searchedCountry.length === 0) {
            setOpenSelectCountry(false);
            props.setCountryIsInvalid(false);
            return;
        }
        if (!props.countryIsInvalid) {
            setOpenSelectCountry(true);
        }

        const countriesDisplayed = getDisplayedCountries(searchedCountry, inputEvent['inputType']);
        const dropdown = document.getElementById("country-dropdown");
        if (dropdown) {
            if (!countriesDisplayed.length) {
                props.setCountryIsInvalid(true)
                dropdown.style.display = 'none';
            } else {
                dropdown.style.display = '';
                props.setCountryIsInvalid(false)
            }
            if (countriesDisplayed.length !== 0)
                countriesDisplayed[0].scrollIntoView()
        }
    }

    function changeCursorColor(filtered: Array<HTMLLabelElement>, currentPosition: number, nextPosition: number) {
        const currentLabel = filtered.at(currentPosition)
        const nextLabel = filtered.at(nextPosition)

        if (currentLabel) {
            currentLabel.style.fontFamily = "";
        }

        if (nextLabel) {
            nextLabel.scrollIntoView()
            nextLabel.style.fontFamily = BOLD_FONT_FAMILY;
        }
    }

    function getNextPositionFromKeyEvent(event: React.KeyboardEvent<HTMLDivElement>, nextPosition: number, currentPosition: number, displayedCountries: HTMLLabelElement[]) {
        switch (event.key) {
            case 'ArrowUp': {
                nextPosition = currentPosition - 1;
                if (nextPosition === -1) {
                    nextPosition = displayedCountries.length - 1
                }
                changeCursorColor(displayedCountries, currentPosition, nextPosition);
                break
            }
            case 'ArrowDown': {
                nextPosition = currentPosition + 1;
                if (nextPosition === displayedCountries.length) {
                    nextPosition = 0;
                }
                changeCursorColor(displayedCountries, currentPosition, nextPosition);
                break
            }
            case 'Tab':
            case 'Enter': {
                const searchCountry = displayedCountries.at(currentPosition)
                if (searchCountry) {
                    const validatedCountry: Country | undefined = countries.find(country => country.code === searchCountry.id)
                    if (validatedCountry) {
                        onChangeCountry(validatedCountry);
                    }
                    return -1;
                }

            }
        }
        return nextPosition;
    }

    const getCountries = () => {
        return document.querySelectorAll('#country-dropdown>label')
    }

    function getCountriesDisplayedOnScreen() {
        const countryDropDown = getCountries()
        const filter = Array.prototype.filter
        return filter.call(countryDropDown, node => node.style.display === '');
    }

    const onKeyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Tab') {
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                setOpenSelectCountry(true)
            }
            // event.preventDefault()
            const input = getHtmlInputElement()
            const displayedCountries = getCountriesDisplayedOnScreen();
            let currentPosition = -1;
            let nextPosition = 0;
            displayedCountries.forEach((node, i) => {
                const nodeTmp = node as HTMLLabelElement;
                if (nodeTmp.style.fontFamily === BOLD_FONT_FAMILY) {
                    currentPosition = i;
                }
            })
            if (displayedCountries && displayedCountries.length !== 0) {
                if (currentPosition === -1) {
                    displayedCountries.at(0).style.fontFamily = BOLD_FONT_FAMILY;
                } else {
                    nextPosition = getNextPositionFromKeyEvent(event, nextPosition, currentPosition, displayedCountries);
                }
                if (nextPosition !== -1) {
                    input.value = displayedCountries.at(nextPosition).outerText
                }
            }
        } else if (event.key === 'Backspace') {
            props.onChangeCountry(EmptyCountry);
        }
    }

    function getHtmlInputElement() {
        return document.querySelector('input[name="countrySearch"]') as HTMLInputElement;
    }

    function onClick() {
        if (!props.countryIsInvalid) {
            setOpenSelectCountry(true)
            const inputElement = getHtmlInputElement();
            inputElement.focus()
        }
    }


    const changeCountryWhenClickingOutside = () => {
        const dropDown = document.getElementById("country-dropdown");
        if (dropDown) {
            getDisplayedCountries(getHtmlInputElement().value, '')
            const firstCountryDisplayed = getCountriesDisplayedOnScreen()[0] as HTMLLabelElement
            if (firstCountryDisplayed) {
                const country = countries.find(country => country.code === firstCountryDisplayed.id)
                if (country) {
                    onChangeCountry(country)
                }
            }
        }
    }

    useEffect(() => {
        getHtmlInputElement().addEventListener("blur", changeCountryWhenClickingOutside)
        return () => {
            getHtmlInputElement().removeEventListener("blur", changeCountryWhenClickingOutside)
        }
    }, []);

    return <div id={"countrySearch"} className={getClassName(false, false)}
                onBeforeInput={() => setOpenSelectCountry(true)}
                onKeyDown={(e) => onKeyDownEvent(e)} onClick={() => onClick()}>
        <InputTextComponent
            name={'countrySearch'} function={onFilterCountry}
            label={t('global.form.country')}
            invalid={props.countryIsInvalid} errorMessage={t('global.form.countryInvalid')}
            className={getClassName(!!props.disabled, props.countryIsInvalid)}
            disabled={props.disabled}
            tabIndex={props.tabIndex}

        />
        <input type={'hidden'} value={props.selectedCountry.code} name={'country'}/>
        {openSelectCountry && <CountryDropdown onSelectCountry={onChangeCountry} countries={countries}/>}
    </div>
}