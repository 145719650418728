import { KcProps } from 'keycloakify';
import { useRef } from 'react';
import moment from 'moment';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KcContext } from '../../../../config/keycloak/kcContext';
import { Country } from '../../../../model/Country';
import { Civility, getFrenchCivility } from '../../../../model/enum/Civility.enum';
import storageService from '../../../../services/storage.service';
import { getClassName } from '../../../../utils/getClassFunctions';
import tcPage from '../../../../utils/hooks/tcPage';
import { ValidAccentRegex, ValidCharForName, ValidFrenchPostalCode, ValidNameRegex } from '../../../../utils/Regex';
import { TcTemplates } from '../../../../utils/TcVars';
import Template from '../../../../view/Template';
import CardButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import CardFlatButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent';
import CivilityDropdown from '../../../shared/DropdownComponent/CivilityDropdownComponent/CivilityDropdown';
import { DefaultCountry } from '../../../shared/DropdownComponent/CountryDropdownComponent/CountryDropdown';
import InputTextComponent from '../../../shared/InputComponent/InputTextComponent/InputTextComponent';
import KcAlert from '../../KcAlert/KcAlert';
import CountryComponent from "../../../shared/SearchComponent/CountryComponent";
import './RegisterQuadruplet.scss';
import { isDateAfterToday } from '../../../../utils/dateUtils';

type KcContext_RegisterQuadruplet = Extract<KcContext, { pageId: 'register-quadruplet.ftl' }>;

const RegisterQuadruplet = ({ applicationId, doFetchDefaultThemeResources = true, kcContext, ...props }: { applicationId: string, doFetchDefaultThemeResources?: boolean, kcContext: KcContext_RegisterQuadruplet; } & KcProps) => {

  tcPage(TcTemplates.CREATE_ACCOUNT.templateName, TcTemplates.CREATE_ACCOUNT.pages.quadruplet, kcContext.client.clientId);
  // CONFIG
  const { t, i18n } = useTranslation();
  const { url, message } = kcContext;
  function getSubtitlesByApplicationId(): string[] {
    const subtitles = [
      t('register.header.subtitle1'),
      t('register.header.subtitle2'),
    ]
    switch (applicationId) {
      case 'TENUP':
        subtitles.push(t('register.header.TENUP'));
        break;
      case 'RPM':
        subtitles.push(t('register.header.RPM'));
        break;
      case 'FFT':
      case 'RG':
      default:
        subtitles.push(t('register.header.RG'));
        break;
    }
    return subtitles;
  }

  useEffect(() => {
    message?.code.toString() != "error.quadruplefound" && storageService.clear();
  }, []);

  const kcalert = useRef(null);

  // CIVILITY
  const [selectedCivility, setSelectedCivility] = useState(storageService.getCivility() || Civility.UNDEFINED);
  const [openSelectCivility, setOpenSelectCivility] = useState(false);

  // COUNTRIES
  const [selectedCountry, setSelectedCountry] = useState(storageService.getCountry() || DefaultCountry);
  const onChangeCountry = (country: Country) => {
    // Hide error message existing cmp
    hideMessage()
    setSelectedCountry(country);
    if (postalCode) {
      setPostalCodeIsValid((country.codeAlpha2 === 'FR'
          ? ValidFrenchPostalCode.test(postalCode)
          : true));
    }
  }
  const [countryIsInvalid, setCountryIsInvalid] = useState(false);

  //FIRSTNAME
  const [firstname, setFirstname] = useState(storageService.getFirstname());
  const [firstnameIsValid, setFirstnameIsValid] = useState(true);
  const onChangeFirstname = (event: ChangeEvent<HTMLInputElement>) => {
    // Hide error message existing cmp
    hideMessage()
    onChangeName(event, setFirstname, setFirstnameIsValid)
  }

  //LASTNAME
  const [lastname, setLastname] = useState(storageService.getLastname());
  const [lastnameIsValid, setLastnameIsValid] = useState(true);
  const onChangeLastname = (event: ChangeEvent<HTMLInputElement>) => {
    // Hide error message existing cmp
    hideMessage()
    onChangeName(event, setLastname, setLastnameIsValid);
  }

  //NAME
  const onChangeName = (event: ChangeEvent<HTMLInputElement>, setName: Dispatch<SetStateAction<string>>, setNameIsValid: Dispatch<SetStateAction<boolean>>) => {
    event.preventDefault();
    // Hide error message existing cmp
    hideMessage()
    const value = event.target.value;
    const lastChar = value.charAt(value.length - 1);
    if (!ValidCharForName.test(lastChar) && !ValidAccentRegex.test(lastChar)) {
      event.target.value = value.substring(0, value.length - 1);
      setName(event.target.value);
      return;
    }
    setName(value);

    setNameIsValid(value.length === 0 ? true : ValidNameRegex.test(value.trim()));
  }

  // BIRTHDATE
  const [birthdate, setBirthdate] = useState(storageService.getBirthdate());
  const [birthdateInvalid, setBirthdateInvalid] = useState(false);
  const [guardianApproval, setGuadrianApproval] = useState(true);
  const [showGuardianApproval, setShowGuardianApproval] = useState(false);
  const onChangeBirthdate = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    // Hide error message existing cmp
    hideMessage()
    formatBirthdateOnChange(event.target);
    setBirthdate(event.target.value);
    if (event.target.value === '') {
      setBirthdateInvalid(false);
      return;
    }

    const birthdateMoment = moment.utc(event.target.value, 'DD/MM/YYYY', true);

    if (!birthdateMoment.isValid() || isDateAfterToday(event.target.value)) {
      setBirthdateInvalid(true);
      setGuadrianApproval(true);
      setShowGuardianApproval(false);
    } else if (birthdateMoment.isAfter(moment().subtract(15, 'years'))) {
      setBirthdateInvalid(false);
      setGuadrianApproval(false);
      setShowGuardianApproval(true);
    } else {
      setBirthdateInvalid(false);
      setGuadrianApproval(true);
      setShowGuardianApproval(false);
    }
  };
  const formatBirthdateOnChange = (birthdateInput: EventTarget & HTMLInputElement) => {
    // Hide error message existing cmp
    hideMessage()
    // Stock cursor position before updating input
    let caretStart = birthdateInput.selectionStart ? birthdateInput.selectionStart : 0;
    let caretEnd = birthdateInput.selectionEnd ? birthdateInput.selectionEnd : 0;

    // Count number of '/' before adding it
    const nbrOfSeparator = [...birthdateInput.value].filter(x => x === '/').length;

    // Add '/' after the first group XX i-or after first and second group if both exist
    if (birthdateInput.value.length > 5) {
      birthdateInput.value = birthdateInput.value.replace(/[^\d]/g, '').replace(/(.{2})(.{2})/, '$1/$2/').trim();
    } else if (birthdateInput.value.length > 2) {
      birthdateInput.value = birthdateInput.value.replace(/[^\d]/g, '').replace(/(.{2})/, '$1/').trim();
    } else {
      birthdateInput.value = birthdateInput.value.replace(/[^\d]/g, '').trim();
    }

    // Replace cursor belong the number of '/' separator added
    caretStart += ([...birthdateInput.value].filter(x => x === '/').length - nbrOfSeparator);
    caretEnd += ([...birthdateInput.value].filter(x => x === '/').length - nbrOfSeparator);
    birthdateInput.setSelectionRange(caretStart, caretEnd);
  }


  // POSTAL CODE
  const [postalCode, setPostalCode] = useState(storageService.getPostalCode());
  const [postalCodeIsValid, setPostalCodeIsValid] = useState(true);
  const onChangePostalCode = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    // Hide error message existing cmp
    hideMessage()
    if (event.target.value === '') {
      setPostalCodeIsValid(true);
    } else {
      setPostalCodeIsValid((selectedCountry.codeAlpha2 === "FR"
        ? ValidFrenchPostalCode.test(event.target.value)
        : true));
    }
    setPostalCode(event.target.value);
  }

  function isFormComplete(): boolean {

    return firstname !== '' && firstnameIsValid
    && lastname !== '' && lastnameIsValid
    && birthdate?.length === 10 && !birthdateInvalid && !isDateAfterToday(birthdate) && guardianApproval
    && (postalCode !== undefined && postalCode !== '' && postalCodeIsValid)
    && selectedCivility !== Civility.UNDEFINED
    && (postalCode !== undefined && postalCode !== '' && postalCodeIsValid) && selectedCountry.position !== 0
    && !countryIsInvalid;
  }

  const handleSubmit = () => {
    const civilityInput = document.getElementById('civility-input') as HTMLInputElement
    civilityInput.value = getFrenchCivility(selectedCivility);
    storageService.setCivility(civilityInput.value);
    storageService.setFirstname(firstname);
    storageService.setLastname(lastname);
    storageService.setBirthdate(birthdate);
    storageService.setCountry(selectedCountry);
    storageService.setPostalCode(postalCode);
  }

  const hideMessage = () => {
    if (message !== undefined && kcalert.current !== null) {
      (kcalert.current as HTMLDivElement).remove();
    }
  }


  return (
    <Template
      {... { kcContext, ...props }}
      doFetchDefaultThemeResources={doFetchDefaultThemeResources}
      headerTitleNode={t('register.header.title')}
      headerSubtitleNode={getSubtitlesByApplicationId()}
      headerBoldSubtitleIds={['subtitle2']}
      headerStepperNode={1}
      formNode={
        <div>
          {message !== undefined && <div ref={kcalert}><KcAlert errorType={message.type} code={message.code} kcContext={kcContext} applicationId={applicationId}/></div>}
          <div className='fft-card-form'>
            <form id='register-quadruplet-form' action={url.loginAction} method='post'>

              <div id='quadruplet-input' className='fft-card-form-item'>
                <CivilityDropdown
                    civility={selectedCivility as Civility} setCivility={setSelectedCivility} disabled={false}
                    openSelectCivility={openSelectCivility} setOpenSelectCivility={setOpenSelectCivility}
                 />

                {/* NAMES */}
                <div id='names'>
                  <InputTextComponent name={'lastname'}
                    value={lastname}
                    function={onChangeLastname}
                    label={t('global.form.lastname')}
                    className={getClassName(false, !lastnameIsValid)}
                    invalid={!lastnameIsValid}
                    errorMessage={t('global.form.lastnameInvalid')}
                    tabIndex={3}
                    data-testid={"lastname"}
                  />

                  <InputTextComponent name={'firstname'}
                    value={firstname}
                    function={onChangeFirstname}
                    label={t('global.form.firstname')}
                    className={getClassName(false, !firstnameIsValid)}
                    invalid={!firstnameIsValid}
                    errorMessage={t('global.form.firstnameInvalid')}
                    tabIndex={4}
                    data-testid={"firstname"}
                  />
                </div>

                {/* BIRTHDATE */}
                <div className={'fft-card-form-item' + (birthdateInvalid ? ' invalid' : '')}
                  id='birthdate' aria-label={t('global.form.birthdate')}>
                  <fieldset >
                    <legend>{t('global.form.birthdate')}</legend>
                    <input name='birthdate'
                           data-testid={'birthdate'}
                      value={birthdate}
                      onChange={onChangeBirthdate}
                      type='text' autoComplete='true'
                      placeholder={t('global.form.birthdateFormat')}
                      aria-required
                      maxLength={10}
                      tabIndex={5}
                      aria-label={t('global.form.birthdate')}
                      inputMode='numeric' />
                  </fieldset>

                  {birthdateInvalid && <span>
                    {t('global.form.birthdateInvalid')}
                  </span>}

                  {showGuardianApproval &&
                    <div id='minority-check'>
                      <input id='minority-checkbox'
                        type='checkbox' tabIndex={6}
                        onChange={(event) => setGuadrianApproval(event.target.checked)}
                      />
                      <span>
                        {t('register.legal')}
                      </span>
                    </div>
                  }

                </div>
                {/* CITY HIDDEN INPUT */}
                <div style={{display: 'none'}}>
                  <legend>{t('global.form.city')}</legend>
                  <input name='city'
                         type='text'
                         autoComplete='false'
                         aria-required
                         aria-label={t('global.form.city')} />
                </div>
                <div id={'country-and-postal-code'}>
                  {/* COUNTRY */}
                  <CountryComponent onChangeCountry={onChangeCountry} selectedCountry={selectedCountry} countryIsInvalid={countryIsInvalid} setCountryIsInvalid={setCountryIsInvalid} tabIndex={7}/>

                  {/* POSTAL CODE */}
                  <InputTextComponent
                      type={'text'}
                      name={'postalCode'}
                      value={postalCode}
                      function={onChangePostalCode}
                      label={t('global.form.postalCode')}
                      className={getClassName(false, !postalCodeIsValid)}
                      invalid={!postalCodeIsValid}
                      errorMessage={t('global.form.postalCodeInvalid')}
                      tabIndex={8}
                      maxLength={20}
                      data-testid={"postalCode"}
                  />
                </div>

              </div>

              <input name='language' value={i18n.language} readOnly type='hidden' />

              {/* CONTINUE BUTTON */}
              <CardButtonComponent
                text={t('global.button.submit.continue')}
                filled={true}
                disabled={!isFormComplete()}
                applicationId={applicationId}
                handleSubmit={handleSubmit}
              />

              {/* BACK LOGIN BUTTON */}
              <CardFlatButtonComponent />

            </form >
          </div >
        </div >
      }
    />
  );
};

RegisterQuadruplet.displayName = 'RegisterQuadruplet';
export default RegisterQuadruplet;
