import { useReducer, useEffect } from "react";
import type { ReactNode } from "react";
import { headInsert } from "keycloakify/lib/tools/headInsert";
import { pathJoin } from "keycloakify/bin/tools/pathJoin";
import type { KcTemplateProps } from "keycloakify/lib/KcProps";
import { KcContext } from "../config/keycloak/kcContext";

import CardHeaderComponent from "../component/shared/CardComponent/CardHeaderComponent/CardHeaderComponent";
import './Template.scss';
import { clsx } from "keycloakify/lib/tools/clsx";

export type TemplateProps = {
  showUsernameNode?: ReactNode;
  formNode: ReactNode;
  infoNode?: ReactNode;
  displayInfo?: boolean;
  displayRequiredFields?: boolean;
  displayWide?: boolean;
  showAnotherWayIfPresent?: boolean;
  /**
   * If you write your own page you probably want
   * to avoid pulling the default theme assets.
   */
  doFetchDefaultThemeResources: boolean;
  /* header specific options */
  headerTitleNode?: string;
  headerSubtitleNode?: string[];
  /*
   * list of index corresponding to element of the
   * headerSubtitleNode list that have to be bold
   */
  /* eslint-disable-next-line */
  headerBoldSubtitleIds?: string[];
  headerStepperNode?: number;
} & { kcContext: KcContext } & KcTemplateProps;

const Template = (props: TemplateProps) => {
  const {
    displayWide = false,
    headerTitleNode,
    headerSubtitleNode,
    headerBoldSubtitleIds,
    headerStepperNode,
    formNode,
    kcContext,
    doFetchDefaultThemeResources,
  } = props;
  const cx  = clsx;
  const { url } = kcContext;
  const [isExtraCssLoaded, setExtraCssLoaded] = useReducer(() => true, false);

  useEffect(() => {
    if (!doFetchDefaultThemeResources) {
      setExtraCssLoaded();
      return;
    }

    let isUnmounted = false;
    const cleanups: (() => void)[] = [];
    const toArr = (x: string | readonly string[] | undefined) => (typeof x === "string" ? x.split(" ") : x ?? []);

    Promise.all(
      [
        ...toArr(props.stylesCommon).map(relativePath => pathJoin(url.resourcesCommonPath, relativePath)),
        ...toArr(props.styles).map(relativePath => pathJoin(url.resourcesPath, relativePath)),
      ]
        .reverse()
        .map(href => headInsert({ "type": "css", href, "position": "prepend" })),
    ).then(() => {
      if (isUnmounted) {
        return;
      }
      setExtraCssLoaded();
    });

    toArr(props.scripts).forEach(relativePath => headInsert({
      "type": "javascript",
      "src": pathJoin(url.resourcesPath, relativePath),
    }));

    if (props.kcHtmlClass !== undefined) {
      const htmlClassList = document.getElementsByTagName("html")[0].classList;
      const tokens = cx(props.kcHtmlClass).split(" ");

      htmlClassList.add(...tokens);
      cleanups.push(() => htmlClassList.remove(...tokens));
    }

    return () => {
      isUnmounted = true;
      cleanups.forEach(f => f());
    };
  }, [props.kcHtmlClass]);

  if (!isExtraCssLoaded) {
    return null;
  }

  return (
    <div className={cx(props.kcLoginClass)}>
      <div className={cx(props.kcFormCardClass, displayWide && props.kcFormCardAccountClass)}>
        <CardHeaderComponent
          title={headerTitleNode}
          subtitle1={headerSubtitleNode?.[0]}
          subtitle2={headerSubtitleNode?.[1]}
          subtitle3={headerSubtitleNode?.[2]}
          subtitle4={headerSubtitleNode?.[3]}
          boldSubtitleIds={headerBoldSubtitleIds}
          stepper={headerStepperNode}
        />
        <div id="kc-content">
          {formNode}
        </div>
      </div>
    </div>
  );
};

Template.displayName = 'Template';
export default Template;
