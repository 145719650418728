export enum TcEventType {
  ACTION = 'action',
  NAVIGATE = 'navigation'
}

export enum TcEventId {
  PAGE = 'page',
  CLIC = 'clic',
  RESET_PASSWORD = 'reset_pwd',
  RESET_EMAIL = 'reset_email',
  REGISTER = 'create_account',
  UPDATE_ACCOUNT = 'update_account',
  UNDEFINED = ''
}

export enum TcEventName {
  BACK_TOL_LOGIN = 'back_to_login',
  CONNECTION = 'connection',
  REGISTER = 'register',
  CONTINUE = 'continue'
}

export const TcTemplates = {
  LOGIN: {
    templateName: 'login',
    pages: {
      login: 'login'
    }
  },
  CREATE_ACCOUNT: {
    templateName: 'create_account',
    pages: {
      quadruplet:'quadruplet',
      credentials: 'credentials',
      optins: 'optins'
    }
  },
  UPDATE_ACCOUNT: {
    templateName: 'update_account',
    pages: {
      update_account:'update_account',
      update_credentials:'update_credentials',
      update_contacts:'update_contacts',
      update_optins:'update_optins'
    }
  },
  RESET_PWD: {
    templateName: 'reset_pwd',
    pages: {
      selectLogin: 'select_login',
      formRequest:'form_request',
      emailSent: 'emailSent',
      updatePwd: 'updatePwd'
    }
  },
  RESET_EMAIL: {
    templateName: 'reset_email',
    pages: {
      formRequest:'form_request',
      checkCode: 'check_code',
      updateEmail: 'update_email'
    }
  },
  OTHERS: {
    templateName: 'others',
    pages: {
      error: 'error',
      help: 'help'
    }
  }
}

const enum phoneType {
  android,
  ios
}

const enum workEnv {
  prod = 'prod',
  preprod = 'preprod',
  dev = 'dev',
  projet = 'projet'
}

interface correspondanceTable {
  idSite: number | undefined;
  work?: string;
  language?: string;
  collectAt?: string;
}

export interface envVar {
  production: boolean,
  currentLangue?: string,
  phoneType?: phoneType
}

const defaultLanguageFrench = 'fr';

const getBilletterieRG = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 554543 : 579491,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: envVar.currentLangue === 'fr' ? defaultLanguageFrench : 'en',
    collectAt: envVar.production ? 'https://logs1406.xiti.com' : 'https://logs1407.xiti.com'
  };
};

const getRolexParisMasters = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 585331 : 585332,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: envVar.currentLangue === 'fr' ? defaultLanguageFrench : 'uk',
    collectAt: 'https://logs1187.xiti.com'
  };
};

const getBoutiqueRG = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 574456 : 574480,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: envVar.currentLangue === 'fr_FR' ? defaultLanguageFrench : 'en_US',
    collectAt: envVar.production ? 'https://logs1407.xiti.com' : 'https://logs1407.xiti.com'
  };
};

const getTenUp = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 548414 : 579911,
    work: envVar.production ? workEnv.prod : workEnv.projet,
    collectAt: envVar.production ? 'https://logs1406.xiti.com' : 'https://logs1187.xiti.com'
  };
};

const getProshopFFT = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 574456 : 607677,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: envVar.currentLangue === 'fr_FR' ? defaultLanguageFrench : 'en_US',
    collectAt: envVar.production ? 'https://logs1409.xiti.com' : 'https://logs1409.xiti.com'
  };
};

const getWeAreBallos = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 527928 : 628817,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: undefined,
    collectAt: envVar.production ? 'https://logs1279.xiti.com' : 'https://logs1412.xiti.com'
  };
};

const getAdoc = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 548419 : undefined,
    collectAt: envVar.production ? 'https://logs1406.xiti.com' : undefined
  };
};

const getAdmin = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 574321 : undefined,
    collectAt: envVar.production ? '' : ''
  };
};

const getApplicationTenUp = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 601656 : 601657,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    collectAt: 'https://logs1409.xiti.com',
  };
};

const getApplicationRPM = (envVar: envVar): correspondanceTable => {
  return {
    idSite:
      getMobileOperatingSystem() === phoneType.android ? (envVar.production ? 631576 : 631577) : envVar.production ? 631574 : 631575,
    language: envVar.currentLangue === 'fr' ? 'francais' : 'anglais',
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    collectAt: 'https://logs1412.xiti.com'
  };
};

const getApplicationRG = (envVar: envVar): correspondanceTable => {
  return {
    idSite:
    getMobileOperatingSystem() === phoneType.android ? (envVar.production ? 590689 : 590688) : envVar.production ? 590718 : 590717,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: envVar.currentLangue === 'fr' ? '1' : '2',
    collectAt:'https://logs1187.xiti.com'
  };
};

const getApplicationFantasyGame = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 636172 : 636173,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: envVar.currentLangue === 'fr' ? 'francais' : 'anglais',
    collectAt:'https://logs1412.xiti.com'
  };
};

const getRGsite = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 591063 : 591065,
    work: envVar.production ? workEnv.prod : workEnv.dev,
    language: envVar.currentLangue === 'fr' ? defaultLanguageFrench : 'en',
    collectAt: 'https://logs1187.xiti.com'
  };
};

const getFFTsite = (envVar: envVar): correspondanceTable => {
  return {
    idSite: envVar.production ? 480869 : 582204,
    work: envVar.production ? workEnv.prod : workEnv.preprod,
    language: 'francais',
    collectAt: 'https://mcjfvzd.pa-cd.com'
  };
};

export const tcVarByAppId = new Map<string, (envVar: envVar) => correspondanceTable>([
  ['rg-fov', getBilletterieRG],
  ['rgbilletterie-site', getBilletterieRG],
  ['getBoutiqueRG', getBoutiqueRG],
  ['FED_MET', getTenUp],
  ['tenup-site', getTenUp],
  ['getProshopFFT', getProshopFFT],
  ['getWeAreBallos', getWeAreBallos],
  ['FED_ADOC', getAdoc],
  ['FED_ADMIN', getAdmin],
  ['SPE_Mobile', getApplicationRG],
  ['rg-app', getApplicationRG],
  ['tenup-app', getApplicationTenUp],
  ['rpm-app', getApplicationRPM],
  ['iam-test', getApplicationRPM],
  ['fft-connect', getApplicationRPM],
  ['rg-fantasyGame', getApplicationFantasyGame],
  ['rpmbilletterie-site', getRolexParisMasters],
  ['rg-site', getRGsite],
  ['fft-site', getFFTsite],
]);

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
 const getMobileOperatingSystem = (): phoneType | undefined => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
      return phoneType.android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
      return phoneType.ios;
  }

  return undefined;
}
