import { t } from "i18next"
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react"
import { UpdateAccountUser, CredentialUser } from "../../../../model/User"
import storageService from "../../../../services/storage.service"
import { getClassName } from "../../../../utils/getClassFunctions"
import { ValidPasswordRegex } from "../../../../utils/Regex"
import InputPasswordComponent from "../../InputComponent/InputPassswordComponent/InputPasswordComponent"
import InputTextComponent from "../../InputComponent/InputTextComponent/InputTextComponent"
import CheckboxComponent from "../../CheckboxComponent/CheckboxComponent";

export interface AccountComponentProps {
  user: UpdateAccountUser | CredentialUser,
  isFormValid: Dispatch<SetStateAction<boolean>>,
  startTabIndexAt: number,
  defaultShowDisconnect: boolean
}

export const AccountComponent = (props: AccountComponentProps) => {
  // LOGIN
  const [username, setUsername] = useState(storageService.getUsername() || props.user.username);
  const [previousUsername] = useState(props.user.username)
  // const [login, setLogin] = useState(storageService.getLogin() || props.user.login);
  const [loginIsValid, setLoginIsValid] = useState(true);
  // eslint-disable-next-line
  const [disconnect, setDisconnect] = useState<boolean>(true);
  const onChangeLogin = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    storageService.setUsername(event.target.value);
    if (event.target.value.length < 6) {
      setLoginIsValid(false);
    } else {
      setLoginIsValid(true);
    }
  }

  // PASSWORD
  const [previousPassword, setPreviousPassword] = useState('');
  const onChangePreviousPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPreviousPassword(event.target.value);
  };
  const [password, setPassword] = useState('');
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const onChangePasswordConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
  };
  const previousPasswordIsInvalid = (): boolean => {
    return (password !== '' || passwordConfirm !== '' || previousUsername !== username) && previousPassword === '';
  }
  const passwordConfirmIsValid = (): boolean => {
    return passwordConfirm !== password && passwordConfirm !== '';
  }

  useEffect(() => {
    let isPasswordValid = true;
    if((previousUsername !== username && previousPassword === '')) isPasswordValid = false;
    if (password !== '' && previousPassword === '') isPasswordValid = false;
    if (password !== passwordConfirm) isPasswordValid = false;
    if (password !== '' && !ValidPasswordRegex.test(password)) isPasswordValid = false;
    props.isFormValid(loginIsValid && isPasswordValid);
  }, [loginIsValid, previousPassword, password, passwordConfirm, username])

  useEffect(() => {
    storageService.clearLogin();
  }, [])

  return (
    <>
      <h3>{t('updateAccount.subComponent.account.title')}</h3>
      {/* LOGIN */}
      <InputTextComponent
        name={'username'}
        value={username}
        function={onChangeLogin}
        label={t('global.form.login')}
        className={getClassName(false, !loginIsValid)}
        invalid={!loginIsValid}
        errorMessage={t('keycloak.error.logininvalid')}
        tabIndex={props.startTabIndexAt + 0}
      />

      {/* PASSWORD */}
      <InputTextComponent name={'previous-password'} function={onChangePreviousPassword}
        label={t('updateAccount.subComponent.account.currentPassword')}
        className={getClassName(false, previousPasswordIsInvalid())}
        invalid={previousPasswordIsInvalid()}
        errorMessage={t('updateAccount.subComponent.account.currentPasswordInvalid')}
        tabIndex={props.startTabIndexAt + 1}
      />
      <InputPasswordComponent onChange={onChangePassword}
        legendCode={t('resetPassword.updatePassword.form.newPassword')}
        tabIndex={props.startTabIndexAt + 2} />

      <InputTextComponent name={'password-confirm'} function={onChangePasswordConfirm}
        label={t('resetPassword.updatePassword.form.confirmNewPassword')}
        className={getClassName(false, passwordConfirmIsValid())}
        invalid={passwordConfirmIsValid()}
        errorMessage={t('keycloak.password_confirm_error')}
        tabIndex={props.startTabIndexAt + 3}
      />

      {(password || props.defaultShowDisconnect) && <CheckboxComponent id={'disconnect'} name={'disconnect'} setValue={setDisconnect} label={t('checkbox.label')}/>}
    </>
  )
}
