import type { KcProps } from "keycloakify";
import { memo } from "react";
import { Trans, useTranslation } from "react-i18next";
import type { KcContext } from "../../../config/keycloak/kcContext";
import Template from "../../../view/Template";
import CardButtonComponent from "../../shared/CardComponent/CardButtonComponent/CardButtonComponent";
import './LoginOauth.scss'

type KcContext_LoginOauth = Extract<KcContext, { pageId: "login-oauth-grant.ftl"; }>;

const LoginOauth = memo(({
                             applicationId,
                             clientId,
                             kcContext,
                             ...props
                         }: { applicationId: string, clientId: string, kcContext: KcContext_LoginOauth; } & KcProps) => {

    const { t } = useTranslation();
    const { oauth, client } = kcContext;
    const oauthRedirect = new URL(window.location.origin + "/auth/realms/master/login-actions/consent")
    oauthRedirect.searchParams.set('client_id', clientId);
    const tab_id_value = new URL(window.location.href).searchParams.get('tab_id')
    if (tab_id_value != null) {
        oauthRedirect.searchParams.set('tab_id', tab_id_value);
    }

    function getAuthorizationMessage(application: string) {
        return (
            <div id={"authorizationList"}>
                <Trans i18nKey="login.authorization.subtitle" application={application}>
                    <span id={"applicationTitle"}>{{application}}</span>
                </Trans>
                {getAuthorizations()}
            </div>
        );
    }

    function getAuthorizations() {

        const basicsAuthorizations: Array<string> = t("login.authorization.form.basics", {returnObjects: true})
        const applicationAuthorizations: Array<string> = oauth.clientScopesRequested
            .map(consent => consent.consentScreenText)
            .filter(consentScreenText => consentScreenText.indexOf('authorization.form') !== -1)
            .map(consentScreenText => t('login.' + consentScreenText))
            return (<ul>
            {basicsAuthorizations.map((authorization, position) => <li key={position}
                                                                       id={applicationId}> {authorization} </li>)}
            {applicationAuthorizations.map((authorization, position) => <li key={position}
                                                                            id={applicationId}> {authorization} </li>)}
        </ul>)
    }



    return (
        <Template
            {...{kcContext, ...props}}
            doFetchDefaultThemeResources={true}
            headerTitleNode={t('login.authorization.title')}
            formNode={
                <div className={'login'}>
                    <div className={'fft-card-form'}>
                        <form action={oauthRedirect.href} method={'post'} className={"loginOauthForm"}>
                            <input type={'hidden'} name={'code'} value={oauth.code}/>
                            {getAuthorizationMessage(client.name)}
                            <CardButtonComponent text={t('register.optins.yes').toUpperCase()} filled={true}
                                                 name={'accept'}
                                                 disabled={false} applicationId={applicationId}/>
                            <CardButtonComponent text={t('register.optins.no').toUpperCase()} filled={false}
                                                 name={'cancel'}
                                                 disabled={false} applicationId={applicationId}/>

                        </form>
                    </div>
                </div>
            }
        />
    );
});

LoginOauth.displayName = 'LoginOauth';
export default LoginOauth;
