import { AxiosError } from "axios";
import moment from "moment";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../../../context/ClientContext";
import { getAlertSummaryFromCode, getAlertTypeFromCode } from "../../../model/AlertMessage";
import { Country } from "../../../model/Country";
import {
  Civility, getCivilityFromValue,
  getFrenchCivility
} from "../../../model/enum/Civility.enum";
import { ResetEmailType } from "../../../model/types/ResetEmailType";
import { ResetEmailUser } from "../../../model/User";
import resetEmailService from "../../../services/reset-email.service";
import storageService from "../../../services/storage.service";
import tagService from "../../../services/tag.service";
import { getClassName } from "../../../utils/getClassFunctions";
import tcPage from "../../../utils/hooks/tcPage";
import { ValidFrenchPhoneRegex, ValidPhoneRegex } from "../../../utils/Regex";
import { TcTemplates } from "../../../utils/TcVars";
import AlertComponent from "../../shared/AlertComponent/AlertComponent";
import CardButtonComponent from "../../shared/CardComponent/CardButtonComponent/CardButtonComponent";
import CardFlatButtonComponent from "../../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent";
import CardHeaderComponent from "../../shared/CardComponent/CardHeaderComponent/CardHeaderComponent";
import { DefaultCountry } from "../../shared/DropdownComponent/CountryDropdownComponent/CountryDropdown";
import QuadrupletInputComponent from "../../shared/QuadrupletInputComponent/QuadrupletInputComponent";
import './ResetEmail.scss';
import CountryComponent from "../../shared/SearchComponent/CountryComponent";
import { isQuadrupletSubmittable } from "../../../utils/formUtils";

interface ResetEmailProps {
  applicationId: string
}

export default function ResetEmail(props: ResetEmailProps) {
  useEffect(() => {
    storageService.clear();
  }, []);

  const { t } = useTranslation();

  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.RESET_EMAIL.templateName, TcTemplates.RESET_EMAIL.pages.formRequest, clientContext.clientId);

  const navigate = useNavigate();

  const [message, setMessage] = useState({ type: '', summary: '' });

  //USER DATA
  const [civility, setCivility] = useState(storageService.getCivility() || Civility.UNDEFINED);
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  // COUNTRY
  const [selectedCountry, setSelectedCountry] = useState<Country>(storageService.getCountry() || DefaultCountry);
  const [countryIsInvalid, setCountryIsInvalid] = useState(false);


  const onChangeCountry = (country: Country) => {
    if(country.position === 0)
      setSelectedCountry({...country, phoneNumberPrefix: selectedCountry.phoneNumberPrefix});
    else
      setSelectedCountry(country);
    const phoneRegex = country.code === 'FRA' ? ValidFrenchPhoneRegex : ValidPhoneRegex;
    const suffixPhone = country.position === 0 ? phone : phone.split(selectedCountry.phoneNumberPrefix)[1];
    const newPhone = country.phoneNumberPrefix + suffixPhone;
    setPhone(newPhone)
    setPhoneIsValid(phoneRegex.test(newPhone))
  }

  const [showQuadruplet, setShowQuadruplet] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // PHONE INPUT MANAGEMENT
  const getDialCode = (): string => selectedCountry.position === 0 ? '' : selectedCountry.phoneNumberPrefix.toString() ;

  function onChangePhone(event: ChangeEvent<HTMLInputElement>) {
    const phoneInput = event.target.value.replace(/\D/gi, '');
    event.target.value = phoneInput;
    if (phoneInput.length === 0) {
      setPhone('');
    } else {
      const value = getDialCode() + (phoneInput[0] === '0' ? phoneInput.substring(1,phoneInput.length) : phoneInput);
      const phoneRegex = selectedCountry.code === 'FRA' ? ValidFrenchPhoneRegex : ValidPhoneRegex;
      setPhone(value);
      setPhoneIsValid(phoneRegex.test(value));
    }
  }

  function isEmpty(type: ResetEmailType): boolean {
    switch (type) {
      case ResetEmailType.QUADRUPLET:
        return showQuadruplet;
      case ResetEmailType.PHONE:
        return phone === '';
      default:
        return phone === ''
          && birthdate === ''
          && firstname === ''
          && lastname === '';
    }
  }

  function isNotSubmittable() {
    const phoneRegex = selectedCountry.code === 'FRA' ? ValidFrenchPhoneRegex : ValidPhoneRegex;
    if (!submitted) {
      return !phoneRegex.test(phone) || countryIsInvalid || selectedCountry.position === 0;
    }
    return  !isQuadrupletSubmittable(firstname, lastname, birthdate, civility);
  }

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    if(selectedCountry.position === 0 ){
      return
    }
    if (!submitted) {
      await resetEmailService.getPhoneInputValidity(phone, selectedCountry.code)
        .then((response) => {
          resetEmailService.sendResetCode(phone, response.data?.idCrm);

          storageService.setIdCrm(response.data?.idCrm);
          storageService.setPhone(response.data?.phone);

          navigate("/reset/verify-code?" + tagService.getTcConsentParams(), { state: { idCrm: response.data?.idCrm, phone: response.data?.phone } });
        })
        .catch((error: AxiosError) => {
          setStateAlert(error);

          const isDuplicatePhone = getAlertSummaryFromCode(error.response?.data?.errorCode) === 'alert.warning.reset.tooMany';

          setShowQuadruplet(isDuplicatePhone);
          setSubmitted(isDuplicatePhone);
        });

    } else {
      const newUser: ResetEmailUser = {
        idCrm: '', phone: phone, country: selectedCountry.code, quadruplet: {
          civility: getFrenchCivility(getCivilityFromValue(civility)),
          firstName: firstname,
          lastName: lastname,
          birthDate: moment(birthdate, 'DD/MM/YYYY', true).format('YYYY-MM-DD'),
        }
      };
      await resetEmailService.sendResetEmailRequest(newUser)
        // TODO. Handle typing of return object with PhoneUser
        // eslint-disable-next-line
        .then((response: any) => {
          resetEmailService.sendResetCode(newUser.phone, response.data?.idCrm);
          storageService.setIdCrm(response.data?.idCrm);
          storageService.setPhone(response.data?.phone);
          navigate("/reset/verify-code?" + tagService.getTcConsentParams(), {
            state: { idCrm: response.data?.idCrm, phone: phone }
          });
        })
        .catch((error: AxiosError) => {
          setStateAlert(error);
          setShowQuadruplet(true);
          setPhone(phone);
        });
    }

  }

  function setStateAlert(alert: AxiosError) {
    setMessage({
      type: getAlertTypeFromCode(alert.response?.data?.errorCode),
      summary: getAlertSummaryFromCode(alert.response?.data?.errorCode)
    });
  }

  const getClassNameForTelInput = () => !submitted ? !phoneIsValid ? 'invalid' : '' : 'disable';

  return (
    <div className='reset-email'>
      <div>
        <CardHeaderComponent
          title={t('resetEmail.title')}
          subtitle1={t('resetEmail.resetEmail.header.subtitle1')}
          subtitle2={t('resetEmail.resetEmail.header.subtitle2')}
          subtitle3={t('resetEmail.resetEmail.header.subtitle3')}
          boldSubtitleIds={["subtitle2"]}
        />
        <AlertComponent
          type={message.type}
          summary={message.summary}
        />

        <div className='fft-card-form'>
          <form id="reset-email-form" onSubmit={handleSubmit}>
            <div id={'country-phone-div'}>
              {/* SELECT COUNTRY */}
              <CountryComponent onChangeCountry={onChangeCountry} selectedCountry={selectedCountry} disabled={submitted} countryIsInvalid={countryIsInvalid} setCountryIsInvalid={setCountryIsInvalid} tabIndex={1}/>
              <div id='reset-email-phone' className={getClassName(false, !phoneIsValid)}>

              <div id='phone-inputs-wrapper'>

                {/* PHONE INPUT */}
                <div id='phone'
                  className={getClassNameForTelInput()}>
                  <fieldset disabled={submitted}>
                    <legend>{t('global.form.phone')}</legend>
                    <div id='phone-input-content'>
                      <span>{getDialCode()}</span>
                      <input name='phone'
                        onChange={onChangePhone}
                        type='tel'
                        autoFocus={true} autoComplete='true'
                        disabled={submitted}
                        aria-required aria-label={t('global.form.phone')}
                        enterKeyHint='send' inputMode='tel'
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
              {!phoneIsValid && <span>
                {t('global.form.phoneInvalid')}
              </span>}
            </div>
            </div>

            {/* QUADRUPLET INPUT */}
            {showQuadruplet &&
              <QuadrupletInputComponent disabled={isEmpty(ResetEmailType.PHONE)}
                setCivility={setCivility}
                setLastname={setLastname}
                setFirstname={setFirstname}
                setBirthdate={setBirthdate}
                quadruplet={{civility:civility, birthDate: birthdate, firstName: firstname, lastName:lastname}}
              />
            }

            <CardButtonComponent
              text={t('global.button.submit.continue')}
              filled={true}
              disabled={isNotSubmittable()}
              applicationId={props.applicationId}
            />
          </form>
          <CardFlatButtonComponent />
        </div>
      </div>
    </div>
  )
}
