import { t } from "i18next";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { Civility } from "../../../model/enum/Civility.enum";
import { Quadruplet } from "../../../model/User";
import { getClassName, getClassnameByDisabled } from "../../../utils/getClassFunctions";
import { ValidAccentRegex, ValidBirthdateRegex, ValidCharForName, ValidNameRegex } from "../../../utils/Regex";
import CivilityDropdown from "../DropdownComponent/CivilityDropdownComponent/CivilityDropdown";
import InputTextComponent from "../InputComponent/InputTextComponent/InputTextComponent";
import './QuadrupletInputComponent.scss';
import { isDateAfterToday } from "../../../utils/dateUtils";


type QuadrupletInputComponent = {
  disabled: boolean,
  setCivility: Dispatch<SetStateAction<Civility>>,
  setLastname: Dispatch<SetStateAction<string>>,
  setFirstname: Dispatch<SetStateAction<string>>,
  setBirthdate: Dispatch<SetStateAction<string>>,
  readonly?: boolean,
  quadruplet?: Quadruplet,
  startTabIndexAt?: number;
};

interface Type {
  isEmpty(type: Type): boolean;
}

export default function QuadrupletInputComponent(props: QuadrupletInputComponent) {

  const startTabIndexAt = props.startTabIndexAt ? props.startTabIndexAt : 0;


  //CIVILITY
  const [openSelectCivility, setOpenSelectCivility] = useState(false);

  //LASTNAME
  const [lastnameIsValid, setLastnameIsValid] = useState(true);

  //FIRSTNAME
  const [firstnameIsValid, setFirstnameIsValid] = useState(true);

  //BIRTHDATE
  const [birthdateIsValid, setBirthdateIsValid] = useState(true);

  const onChangeLastname = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    const lastChar = value.charAt(value.length - 1);
    if (!ValidCharForName.test(lastChar) && !ValidAccentRegex.test(lastChar)) {
      event.target.value = value.substring(0, value.length - 1);
      props.setLastname(event.target.value);

      return;
    }
    props.setLastname(value);
    setLastnameIsValid(ValidNameRegex.test(value.trim()));
  }

  const onChangeFirstname = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const value = event.target.value;
    const lastChar = value.charAt(value.length - 1);
    if (!ValidCharForName.test(lastChar) && !ValidAccentRegex.test(lastChar)) {
      event.target.value = value.substring(0, value.length - 1);
      props.setFirstname(event.target.value);
      return;
    }
    props.setFirstname(value);
    setFirstnameIsValid(ValidNameRegex.test(value.trim()));
  }

  const onChangeBirthdate = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    formatBirthdateOnChange(event);
    const value = event.target.value;
    props.setBirthdate(value);

    if (value.length === 0 || value.length == 1 && new RegExp('\\D').test(value)) {
      setBirthdateIsValid(true);
      props.setBirthdate('');
    } else if (isDateAfterToday(value)) {
      setBirthdateIsValid(false);
    }
    else
      setBirthdateIsValid(ValidBirthdateRegex.test(value));

  }

  const formatBirthdateOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Stock cursor position before updating input
    let caretStart = event.target.selectionStart ? event.target.selectionStart : 0;
    let caretEnd = event.target.selectionEnd ? event.target.selectionEnd : 0;
    // Count number of '/' before adding it
    const nbrOfSeparator = [...event.target.value].filter(x => x === '/').length;
    // Add '/' after the first group XX i-or after first and second group if both exist
    if (event.target.value.length > 5) {
      event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/(.{2})(.{2})/, '$1/$2/').trim();
    } else if (event.target.value.length > 2) {
      event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/(.{2})/, '$1/').trim();
    } else {
      event.target.value = event.target.value.replace(/[^\d]/g, '').trim();
    }

    // Replace cursor belong the number of '/' separator added
    caretStart = caretStart + ([...event.target.value].filter(x => x === '/').length - nbrOfSeparator);
    caretEnd = caretEnd + ([...event.target.value].filter(x => x === '/').length - nbrOfSeparator);
    event.target.setSelectionRange(caretStart, caretEnd);
  }

  return (
    <div id='quadruplet-input'
      className={getClassnameByDisabled(props.disabled)}
    >

      <CivilityDropdown civility={props.quadruplet === undefined ? Civility.UNDEFINED : props.quadruplet.civility as Civility}
                        setCivility={props.setCivility}
                        disabled={props.disabled}
                        readonly={props.readonly}
                        openSelectCivility={openSelectCivility}
                        setOpenSelectCivility={setOpenSelectCivility} />

      <div id='names'>
        <InputTextComponent name={'lastname'} function={onChangeLastname}
          label={t('global.form.lastname')}
          disabled={props.disabled}
          className={getClassName(props.disabled, !lastnameIsValid)}
          invalid={!lastnameIsValid}
          errorMessage={t('global.form.lastnameInvalid')}
          readonly={props.readonly}
          value={props.quadruplet?.lastName}
          tabIndex={startTabIndexAt + 2}
        />

        <InputTextComponent name={'firstname'} function={onChangeFirstname}
          label={t('global.form.firstname')}
          disabled={props.disabled}
          className={getClassName(props.disabled, !firstnameIsValid)}
          invalid={!firstnameIsValid}
          errorMessage={t('global.form.firstnameInvalid')}
          readonly={props.readonly}
          value={props.quadruplet?.firstName}
          tabIndex={startTabIndexAt + 3}

        />
      </div>
      <InputTextComponent name={'birthdate'} function={onChangeBirthdate}
        label={t('global.form.birthdate')} placeHolder={t('global.form.birthdateFormat')}
        disabled={props.disabled}
        className={getClassName(props.disabled, !birthdateIsValid)}
        invalid={!birthdateIsValid} errorMessage={t('global.form.birthdateInvalid')}
        maxLength={10}
        readonly={props.readonly}
        value={props.quadruplet?.birthDate}
        tabIndex={startTabIndexAt + 4}
        inputMode='numeric'
      />
    </div>);
}
