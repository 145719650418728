import './CardTextButtonComponent.scss';

interface CardTextButtonProps {
  applicationId: string;
  redirectUri: string;
  text: string;
}

export default function CardTextButtonComponent(props: CardTextButtonProps) {
  return (
    <div className='fft-text-button'>
      <a className='fft-card-flat-button'
        id={props.applicationId}
        href={props.redirectUri}
        tabIndex={16}>
        <span>{props.text}</span>
      </a>
    </div>
  );
}
