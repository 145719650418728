import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TC_Wrapper from 'react-tag-commander';
import storageService from '../../services/storage.service';
import { TcEventId, tcVarByAppId } from '../TcVars';

const tcPage = (template: string, pageName: string, clientId: string) => {
  const wrapper = TC_Wrapper.getInstance();
  const { i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      try {
        const envVar = tcVarByAppId.get(clientId)?.call(this, {
          production: window.location.href.startsWith('https://auth.fft.fr/'),
          currentLangue: i18n.language
        });
        wrapper.triggerEvent(TcEventId.PAGE, this, {
          'env_template': template,
          'page_name': pageName,
          'env_url': window.location.href,
          'env_work': envVar?.work,
          'env_language': envVar?.language,
          'id_site_at': envVar?.idSite,
          'event_s_collect_at': envVar?.collectAt,
          'event_consent_analytics': storageService.getConsentAnalytics(),
          'event_consent_ad': storageService.getConsentAd(),
          'event_consent_personalization': storageService.getConsentPerso(),
          'event_device': storageService.getConsentDevice(),
          'event_consent_at': storageService.getConsentAt()
        });
      } catch (error) {
        console.log('Tagging error:', error);
      }
    }, 1000)
  }, []);
};

export default tcPage;
