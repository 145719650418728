import { KcProps } from 'keycloakify';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { KcContext } from '../../../../config/keycloak/kcContext';
import { ClientContext } from '../../../../context/ClientContext';
import tagService from '../../../../services/tag.service';
import tcPage from '../../../../utils/hooks/tcPage';
import { TcEventId, TcEventName, TcEventType, TcTemplates } from '../../../../utils/TcVars';
import Template from '../../../../view/Template';
import CardButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import CardTextButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardTextButton/CardTextButtonComponent';
import OptinsComponent from '../../../shared/DetailComponent/OptinsComponent/OptinsComponent';
import KcAlert from '../../KcAlert/KcAlert';

type KcContext_UpdateOptins = Extract<KcContext, { pageId: 'update-optins.ftl'; }>;

const UpdateOptins = ({ applicationId, kcContext, ...props }: { applicationId: string, kcContext: KcContext_UpdateOptins; } & KcProps) => {

  // TRADUCTION
  const { t, i18n } = useTranslation();

  // TAGGING
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.UPDATE_ACCOUNT.templateName, TcTemplates.UPDATE_ACCOUNT.pages.update_optins, clientContext.clientId);
  const handleSubmit = (): void => {
    tagService.sendEvent(TcEventId.UPDATE_ACCOUNT, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
  }

  // KC VAR
  const { url, message, user, redirectUri } = kcContext;

  // USER DATAS
  const [fftOptin, setFFTOptin] = useState<boolean | undefined>(user.optinFFT);
  const [partnerOptin, setPartnerOptin] = useState<boolean | undefined>(user.optinPartenaire);

  return (
    <Template
      {...{ kcContext, ...props }}
      doFetchDefaultThemeResources={true}
      headerTitleNode={t('updateAccount.header.title')}
      headerSubtitleNode={[
        t('updateAccount.header.subtitle1')
      ]}
      headerBoldSubtitleIds={['subtitle2']}
      formNode={
        <div>
          {message !== undefined && <KcAlert errorType={message.type} code={message.code} kcContext={kcContext}/>}
          <div className='fft-card-form'>
            <form id="reset-form" action={url.loginAction} method="post">

              <OptinsComponent applicationId={applicationId} startTabIndexAt={11}
                optins={
                  [
                    [fftOptin, setFFTOptin, 'optinFFT', t('register.optins.optin2'), false],
                    [partnerOptin, setPartnerOptin, 'optinPartenaire', t('register.optins.optin3'), false]
                  ]
                } />

              <input name='language' value={i18n.language} readOnly type='hidden' />

              {/* REGISTER BUTTON */}
              <CardButtonComponent
                text={t('updateAccount.form.submit')}
                filled={true} disabled={false}
                applicationId={applicationId}
                handleSubmit={handleSubmit}
                tabIndex={15} />

              {/* CANCEL BUTTON */}
              <CardTextButtonComponent
                applicationId={applicationId}
                redirectUri={redirectUri}
                text={t('updateAccount.form.cancel')}
              />

            </form>
          </div>
        </div>
      }
    />
  );
};

UpdateOptins.displayName = 'UpdateOptins';
export default UpdateOptins;
