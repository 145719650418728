import {AxiosError} from 'axios';
import 'moment/locale/fr';
import moment from "moment/moment";
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Navigate} from "react-router-dom";
import {ClientContext} from '../../context/ClientContext';
import {getAlertSummaryFromCode} from '../../model/AlertMessage';
import {Civility, getFrenchCivility,} from "../../model/enum/Civility.enum";
import {ResetPasswordUser, User} from '../../model/User';
import resetPasswordService from "../../services/reset-password.service";
import storageService from "../../services/storage.service";
import {getClassName} from '../../utils/getClassFunctions';
import tcPage from '../../utils/hooks/tcPage';
import { ValidEmailRegex} from '../../utils/Regex';
import {TcTemplates} from '../../utils/TcVars';
import AlertComponent from "../shared/AlertComponent/AlertComponent";
import CardFlatButtonComponent
  from '../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent';
import CardButtonComponent from "../shared/CardComponent/CardButtonComponent/CardButtonComponent";
import CardHeaderComponent from "../shared/CardComponent/CardHeaderComponent/CardHeaderComponent";
import {ResetType} from '../shared/ConfirmReset/ConfirmReset';
import InputTextComponent from '../shared/InputComponent/InputTextComponent/InputTextComponent';
import QuadrupletInputComponent from '../shared/QuadrupletInputComponent/QuadrupletInputComponent';
import './ResetPassword.scss';
import i18n from "i18next";
import tagService from '../../services/tag.service';
import { isQuadrupletSubmittable } from '../../utils/formUtils';

type ResetPasswordProps = {
  applicationId: string;
  clientId: string;
}

enum Type {
  email = 'email',
  quadruplet = 'quadruplet'
}

export default function ResetPassword(props: ResetPasswordProps) {

  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.RESET_PWD.templateName, TcTemplates.RESET_PWD.pages.formRequest, clientContext.clientId);
  useEffect(() => storageService.reset(), []);
  const [message, setMessage] = useState({ type: '', summary: '' });
  const [redirect, setRedirect] = useState(false);

  // Email
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(true);
  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailIsValid(event.target.value.length === 0 || (event.target.value.length !== 0 && ValidEmailRegex.test(event.target.value)))
  }

  // Quadruplet
  const [civility, setCivility] = useState(storageService.getCivility() || Civility.UNDEFINED);
  const [lastname, setLastname] = useState(storageService.getLastname() || '');
  const [firstname, setFirstname] = useState(storageService.getFirstname() || '');
  const [birthdate, setBirthdate] = useState(storageService.getBirthdate() || '');

  // Form
  function isEmpty(type: Type): boolean {
    if (type === Type.quadruplet) {
      return birthdate === '' && firstname === '' && lastname === '' && civility === Civility.UNDEFINED;
    }
    else if (type === Type.email) {
      return email === '';
    }
    else return email === '' && birthdate === '' && firstname === '' && lastname === '' && civility === Civility.UNDEFINED;
  }
  /**
   * Check if email or quadruplet is filled and valid
   * @returns true if form is correctly filled, false otherwise
   */
  function isFormFilled() {
    return (email.length !== 0 && ValidEmailRegex.test(email)) || isQuadrupletSubmittable(firstname, lastname, birthdate, civility);
  }
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    if (isEmpty(Type.quadruplet)) {
      // Send reset password by email
      const newUser: User = {
        clientId: props.clientId,
        email: email, language: i18n.language
        ,templateId: props.applicationId,
        event_consent_analytics: storageService.getConsentAnalytics(),
        event_consent_ad: storageService.getConsentAd(),
        event_consent_personalization: storageService.getConsentPerso(),
        event_device: storageService.getConsentDevice(),
        event_consent_at: storageService.getConsentAt(),
        atUserId:storageService.getAtUserId()
      };
      await sendReset(newUser);
    } else if (isEmpty(Type.email)) {
      // Send reset password by quadruplet
      moment.locale('fr');
      const newUser: ResetPasswordUser = {
        clientId: props.clientId, email: '',
        quadruplet: {
          civility: getFrenchCivility(civility),
          firstName: firstname,
          lastName: lastname,
          birthDate: moment(birthdate, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
        }
        , language: i18n.language
        ,templateId: props.applicationId,
        event_consent_analytics: storageService.getConsentAnalytics(),
        event_consent_ad: storageService.getConsentAd(),
        event_consent_personalization: storageService.getConsentPerso(),
        event_device: storageService.getConsentDevice(),
        event_consent_at: storageService.getConsentAt(),
        atUserId:storageService.getAtUserId()
      };
      await sendReset(newUser);
    }
  }
  const sendReset = async (newUser: ResetPasswordUser): Promise<void> => {
    await resetPasswordService.resetPasswordByUser(newUser)
      // TODO. Handle typing of return object with EmailUser
      // eslint-disable-next-line
      .then((response: any) => {
        setEmail(response.data.email);
        storageService.setResetType(ResetType.password, response.data.email);
        setRedirect(true);

      })
      .catch((error: AxiosError) => {
        setMessage({ type: 'error', summary: getAlertSummaryFromCode(error.response?.data?.errorCode) });
      });
  }

  return (
    <div className='reset-password'>
      {redirect ? <Navigate to={'/reset/confirm-reset?application_id=' + props.applicationId + '&client_id=' + props.clientId + '&' + tagService.getTcConsentParams()} /> : <div>
        <CardHeaderComponent
          title={t('resetPassword.resetPassword.header.title')}
          subtitle1={t('resetPassword.resetPassword.header.subtitle1')}
          subtitle2={t('resetPassword.resetPassword.header.subtitle2')}
          boldSubtitleIds={['subtitle1']}
        />
        <AlertComponent
          type={message.type}
          summary={message.summary}
        />
        <div className='fft-card-form'>
          <form id="reset-password-form" onSubmit={handleSubmit}>

            <InputTextComponent name={'email'} function={onChangeEmail}
              label={t('global.form.email')}
              disabled={!isEmpty(Type.quadruplet)}
              className={getClassName(!isEmpty(Type.quadruplet), !emailIsValid)}
              invalid={!emailIsValid} errorMessage={t('global.form.emailInvalid')}
              type={'text'} 
              enterKeyHint='send' inputMode='email'
            />

            <div className='fft-card-form-item' id='separator'>
              <span>{t('global.form.or')}</span>
            </div>
            <QuadrupletInputComponent
              setCivility={setCivility}
              setLastname={setLastname}
              setFirstname={setFirstname}
              setBirthdate={setBirthdate}
              disabled={!isEmpty(Type.email)}
              quadruplet={{civility: civility, birthDate: birthdate, firstName: firstname, lastName:lastname}}
            />
            <CardButtonComponent
              text={t('global.button.submit.continue')}
              filled={true}
              disabled={!isFormFilled()}
              applicationId={props.applicationId}
            />
          </form>
          <CardFlatButtonComponent />
        </div>
      </div>}
    </div>
  )
}
