import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import iconFlagFr from "../../../../resources/img/icons/flags/icon-flag-fr.svg";
import iconFlagUk from "../../../../resources/img/icons/flags/icon-flag-uk.svg";
import iconQuestionMark from "../../../../resources/img/icons/icon-question-mark.svg";
import storageService from "../../../../services/storage.service";
import { eventBus } from "../../../../services/event-bus.service";
import HelpPopup, { HelpPopupProps } from "../../HelpPopupComponent/HelpPopup";
import './CardFooterComponent.scss';

interface CardFooterProps {
  resetEmailUrl: string;
  resetPasswordUrl: string;
  helpPageType: HelpPopupProps['helpPageType'];
}

export default function CardFooterComponent(props: CardFooterProps) {

  const { t, i18n } = useTranslation();

  const [langCode, setLangCode] = useState(i18n.language);

  // LANG SELECT
  const onChangeSelectedLang = () => {
    const langCode = i18n.language === 'fr' ? 'en' : 'fr';
    i18n.changeLanguage(langCode);
    storageService.setLocale(langCode);
  }

  // HELP POPUP
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  useEffect(() => {
    eventBus.on('openPopup', () => setIsPopupOpen(true));
    return () => { eventBus.remove('openPopup', () => setIsPopupOpen(true)); }
  }, [])

  useEffect(() => setLangCode(i18n.language), [i18n.language])
  return (
    <>
      <footer className='fft-card-footer'>
        <button type="button" onClick={onChangeSelectedLang}>
          <img alt={langCode === 'fr' ? 'en' : 'fr'} src={langCode === 'fr' ? iconFlagUk : iconFlagFr} />
        </button>

        <div id='fft-card-footer-help' onClick={togglePopup}>
          <img src={iconQuestionMark} alt="Point d'interrogation" />
          <span>{t('global.footer.help')}</span>
        </div>
      </footer>
      {isPopupOpen &&
        <HelpPopup
          handleClose={togglePopup}
          resetEmailUrl={props.resetEmailUrl}
          resetPasswordUrl={props.resetPasswordUrl}
          helpPageType={props.helpPageType}
        />
      }
    </>
  );
}
