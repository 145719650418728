import { AxiosError } from "axios";
import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { ClientContext } from "../../../context/ClientContext";
import { getAlertSummaryFromCode } from "../../../model/AlertMessage";
import resetEmailService from "../../../services/reset-email.service";
import storageService from "../../../services/storage.service";
import tcPage from "../../../utils/hooks/tcPage";
import { TcTemplates } from "../../../utils/TcVars";
import AlertComponent from "../../shared/AlertComponent/AlertComponent";
import CardFlatButtonComponent from "../../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent";
import CardButtonComponent from "../../shared/CardComponent/CardButtonComponent/CardButtonComponent";
import CardHeaderComponent from "../../shared/CardComponent/CardHeaderComponent/CardHeaderComponent";
import './CheckCode.scss';
import tagService from "../../../services/tag.service";

interface CheckCodeProps {
	applicationId: string;
}

const CheckCode = (props: CheckCodeProps) => {

	const { t } = useTranslation();

	const clientContext = useContext(ClientContext);
	tcPage(TcTemplates.RESET_EMAIL.templateName, TcTemplates.RESET_EMAIL.pages.checkCode, clientContext.clientId);

	const idCrm: string = storageService.getIdCrm();
	const phone: string = storageService.getPhone();

	const [shouldRedirect, setShouldRedirect] = useState(false);
	const [code, setCode] = useState(['']);
	const [message, setMessage] = useState({ type: '', summary: '' });
	const [disabled, setDisabled] = useState(true);
	const [codeError, setCodeError] = useState(false);

	const checkCode = async (event: FormEvent): Promise<void> => {
		event.preventDefault();
		cleanErrors();
		resetEmailService.checkResetCode(code.join(''), idCrm)
			.then(() => {
				storageService.setCode(code.join(''));
				setShouldRedirect(true);
			})
			.catch((error: AxiosError) => {
				setStateErrorCode(error);
				setCodeError(true);
			});
	}
	const resendCode = async (event: FormEvent): Promise<void> => {
		event.preventDefault();
		cleanErrors();
		await resetEmailService.sendResetCode(phone, idCrm);
		setMessage({
			type: 'success',
			summary: 'resetEmail.checkCode.alert.resendCodeOk'
		});
		setCode(['']);
	}
	const handleChange = async (index: number, event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		const maxLength = event.target.maxLength;
		let value = event.target.value;
		if (!(new RegExp(/\d/).test(value))) {
			event.target.value = '';
			return;
		}
		// If more than maxLength characters are entered, substring the value
		if (value.length > maxLength) {
			value = value.substring(0, maxLength);
			event.target.value = value;
		}
		const newCode = [...code];
		newCode[index] = value;
		// If one character is entered in input X focus on input X+1
		if (value.length >= maxLength) {
			if (index < 6) {
				const nextSibling = document.querySelector(`input[name=code-${index + 1}]`);
				if (nextSibling !== null) {
					(nextSibling as HTMLElement).focus();
				}
			}
		}
		setCode(newCode);
		setDisabled(!new RegExp(/\d{6}/).test(newCode.toString().replaceAll(',', '')))
	};
	function setStateErrorCode(error: AxiosError) {
		setMessage({
			type: 'error',
			summary: getAlertSummaryFromCode(error.response?.data?.errorCode)
		});
	}
	function cleanErrors() {
		setMessage({
			type: '',
			summary: ''
		});
		setCodeError(false);
	}
	function renderItems() {
		const items = [];
		let classNames = codeError ? 'error' : '';

		classNames = (classNames ? classNames + ' ' : '') + (disabled ? '' : 'valid');

		for (let index = 0; index < 6; index++) {
			items.push(
				<span className={classNames} key={index}>
					<input className={classNames} name={'code-' + index}
						data-testid={'code-' + index}
						type="text" min={0} max={9} maxLength={1}
						onChange={event => handleChange(index, event)}
						placeholder=" " autoComplete='off'
						inputMode='numeric'
					/>
				</span>
			)
		}
		return items;
	}
	function maskNumber(): string {
		const phoneNumberLength = phone.length;
		const indexFirstSlice = (phone as string).startsWith('0') ? 2 : 1;
		const mask = phone.slice(0, indexFirstSlice) +
			phone.slice(indexFirstSlice, phoneNumberLength - 2).replace(/\d/g, "X") +
			phone.slice(phoneNumberLength - 2, phoneNumberLength);

		return mask.replace(/(.{2})/g, "$1 ");
	}

	return (
		<div className='check-code'>
			{
				shouldRedirect ?
					<Navigate to={"/reset/update-email?" + tagService.getTcConsentParams()} /> :
					<div>
						<CardHeaderComponent
							title={t('resetEmail.title')}
							subtitle1={t('resetEmail.checkCode.header.subtitle1')}
							subtitle2={t('resetEmail.checkCode.header.subtitle2')}
							subtitle3={t('resetEmail.checkCode.header.subtitle3')}
							subtitle4={maskNumber() + '.'}
							boldSubtitleIds={['subtitle2', 'subtitle4']}
						/>
						<AlertComponent
							type={message.type}
							summary={message.summary}
						/>

						<div className='fft-card-form'>
							<form id="verify-code-form" onSubmit={checkCode}>
								<div id='code-container'>
									<fieldset id='code-container'>
										<legend>{t('resetEmail.checkCode.form.codeField')}</legend>
										{renderItems()}
									</fieldset>
									<button onClick={resendCode} id='resend-code'>{t('resetEmail.checkCode.form.resendCode')}</button>
								</div>
								<CardButtonComponent
									text={t('global.button.submit.validate')}
									filled={true}
									disabled={disabled}
									applicationId={props.applicationId}
								/>
							</form>
							<CardFlatButtonComponent />
						</div>
					</div>
			}
		</div>
	)
}

export default CheckCode;
