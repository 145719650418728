import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import storageService from '../../services/storage.service';

const tcConsentParam = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // event_consent_analytics
    if (searchParams.get('event_consent_analytics')) {
      storageService.setConsentAnalytics(searchParams.get('event_consent_analytics') || '');
    }
    // event_consent_ad
    if (searchParams.get('event_consent_ad')) {
      storageService.setConsentAd(searchParams.get('event_consent_ad') || '');
    }
    // event_consent_personalization
    if (searchParams.get('event_consent_personalization')) {
      storageService.setConsentPerso(searchParams.get('event_consent_personalization') || '');
    }
    // event_device
    if (searchParams.get('event_device')) {
      storageService.setConsentDevice(searchParams.get('event_device') as 'web'|'app');
    }
    // event_consent_at
    if (searchParams.get('event_consent_at')) {
      storageService.setConsentAt(searchParams.get('event_consent_at') || '');
    }
    // atuserid
    if (searchParams.get('atuserid')) {
      storageService.setAtUserId(searchParams.get('atuserid') || '');
    }
  }, []);
};

// const initTcParam()

export default tcConsentParam;

export interface TcConsentParam {
  event_consent_analytics: string,
  event_consent_ad: string,
  event_consent_personalization: string,
  event_device: 'web'|'app',
  event_consent_at: string,
  atUserId: string
}
