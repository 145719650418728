import { Component } from 'react';
import './LogosComponent.scss';

interface LogosProps {
  applicationId: string
}

// eslint-disable-next-line
interface LogosState { }

export default class Logos extends Component<LogosProps, LogosState> {

  private readonly FFT: string = 'FFT';
  private readonly TENUP: string = 'TENUP';
  private readonly RG: string = 'RG';
  private readonly RPM: string = 'RPM';
  private readonly applicationLogoText: string = 'Logo de l\'application ';
  private readonly applicationId: string = !['FFT', 'RG', 'TENUP', 'RPM']
    .includes(this.props.applicationId) ? 'FFT' : this.props.applicationId;
  secondaryLogos: JSX.Element[] = this.getSecondaryLogosFromApplicationId();

  getSecondaryLogosFromApplicationId(): JSX.Element[] {
    const secondaryLogos: JSX.Element[] = [];
    const applicationNames = this.getApplicationNames();

    // If getApplicationNames() returns undefined, set a default secondary logos line
    if (applicationNames === undefined) {
      secondaryLogos.push(<img key={this.TENUP} className='logo-secondary-item' id={this.TENUP}
        alt={this.applicationLogoText + this.TENUP} />);
      secondaryLogos.push(<img key={this.RG} className='logo-secondary-item' id={this.RG}
        alt={this.applicationLogoText + this.RG} />);
      secondaryLogos.push(<img key={this.RPM} className='logo-secondary-item' id={this.RPM}
        alt={this.applicationLogoText + this.RPM} />);

    } else {
      for (const applicationName of applicationNames) {
        secondaryLogos.push(<img key={applicationName} className='logo-secondary-item' id={applicationName}
          alt={this.applicationLogoText + applicationName} />);
      }
    }

    return secondaryLogos;
  }

  /**
   * Set map with 4 possible values for secondary logos order
   * Return the one with key corresponding to applicationId value
   */
  getApplicationNames(): string[] | undefined {
    const secondaryLogosByApplicationIdTable = new Map<string, string[]>();

    secondaryLogosByApplicationIdTable.set(this.FFT, [this.RG, this.TENUP, this.RPM]);
    secondaryLogosByApplicationIdTable.set(this.TENUP, [this.FFT, this.RG, this.RPM]);
    secondaryLogosByApplicationIdTable.set(this.RG, [this.FFT, this.TENUP, this.RPM]);
    secondaryLogosByApplicationIdTable.set(this.RPM, [this.FFT, this.TENUP, this.RG]);

    return secondaryLogosByApplicationIdTable.get(this.applicationId);
  }

  render() {
    return (
      <div className='logos'>
        <img className='logo-primary' id={this.applicationId}
          alt={this.applicationLogoText + this.applicationId} />
        <div className='logo-secondary'>
          {this.secondaryLogos}
        </div>
      </div>
    )
  }
}
