import { useEffect } from "react";
import { KcContext, kcContext } from "../../../../config/keycloak/kcContext";
import { KcFftContextBase } from '../../../../config/keycloak/KcFftContextBase';
import '../../../../resources/i18n/config';
import FftRouter from '../../../../router/FftRouter';
import CardFooterComponent from '../CardFooterComponent/CardFooterComponent';
import './CardComponent.scss';

export interface CardProps {
  applicationId: string;
  clientId: string;
}

export default function CardComponent(props: CardProps) {

  function focusNextElement() {
    const focussableElements = 'button:not([disabled]), input:not([disabled]):not([type=checkbox])';
    if (document.activeElement && (document.activeElement as HTMLInputElement).form) {
      const focussable = Array.prototype.filter.call((document.activeElement as HTMLInputElement).form?.querySelectorAll(focussableElements),
        function(element) {
          return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
        });
      const index = focussable.indexOf(document.activeElement);
      if (focussable[index + 1]?.nodeName === 'INPUT') {
        focussable[index + 1].focus();
      } else if (focussable[index + 1]?.nodeName === 'BUTTON') {
        focussable[index + 1].click();
      }
    }
  }

  const focusNextInputOnEnterKey = (e: KeyboardEvent) => {
    if (e.key == 'Enter' && (e.target as HTMLInputElement)?.nodeName === 'INPUT') {
      e.preventDefault();
      focusNextElement();
      return false;
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", focusNextInputOnEnterKey, false);
    return () => {
      document.removeEventListener("keydown", focusNextInputOnEnterKey, false);
    }
  }, []);

  const getHelpPageType = (kcContext: KcContext | undefined) => {
    if (kcContext === undefined) {
      return 'GENERAL';
    }

    switch (kcContext.pageId) {
      case 'update-account.ftl':
        return 'UPDATE';
      case 'update-credentials.ftl':
        return 'UPDATE_CREDENTIALS';
      case 'update-contacts.ftl':
        return 'UPDATE_CONTACTS';
      case 'update-optins.ftl':
        return 'UPDATE_OPTINS';
      default:
        return 'GENERAL';
    }
  }

  return (
    <div className='fft-card'>
      <FftRouter applicationId={props.applicationId} clientId={props.clientId} />
      <CardFooterComponent
        resetEmailUrl={getResetEmailUrl(props)}
        resetPasswordUrl={getResetPasswordUrl(props)}
        helpPageType={getHelpPageType(kcContext)}
      />
    </div>
  )
}

export function getResetEmailUrl(props: CardProps): string {
  const redirect = (kcContext !== undefined)
    ? (kcContext as KcFftContextBase).emailResetRedirectUri
    : (process.env.RESET_EMAIL_URL !== undefined)
      ? process.env.RESET_EMAIL_URL
      : '';

  if (redirect) {
    const redirectUrl = new URL(redirect);

    redirectUrl.searchParams.set('application_id', props.applicationId);
    redirectUrl.searchParams.set('client_id', props.clientId);

    return redirectUrl.href;

  } else {
    return '';
  }
}

export function getResetPasswordUrl(props: CardProps): string {
  const redirect = (kcContext !== undefined)
    ? (kcContext as KcFftContextBase).passwordResetRedirectUri
    : (process.env.RESET_PASSWORD_URL !== undefined)
      ? process.env.RESET_PASSWORD_URL
      : '';

  if (redirect) {
    const redirectUrl = new URL(redirect);

    redirectUrl.searchParams.set('application_id', props.applicationId);
    redirectUrl.searchParams.set('client_id', props.clientId);

    return redirectUrl.href;

  } else {
    return '';
  }
}
