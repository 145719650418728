import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Civility, getCivility } from '../../../../model/enum/Civility.enum';
import iconArrowDown from '../../../../resources/img/icons/icon-arrow-down.svg';
import iconArrowUp from '../../../../resources/img/icons/icon-arrow-up.svg';
import { getClassName } from '../../../../utils/getClassFunctions';
import './CivilityDropdown.scss';

export type CivilityDropdownProps = {
  disabled: boolean,
  readonly?: boolean,
  civility: Civility,
  openSelectCivility: boolean,
  setOpenSelectCivility: Dispatch<SetStateAction<boolean>>,
  setCivility: Dispatch<SetStateAction<Civility>>;
}

export default function CivilityDropdown(props: CivilityDropdownProps) {

  // CIVILITY
  const onChangeCivility = async (civility: string): Promise<void> => {
    props.setCivility(civility as Civility)
  };
  const close = () => {
    props.setOpenSelectCivility(false);
  }

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLDivElement;
    if (target !== null && (target.offsetParent?.id !== 'civility' && target.id !== 'civility')) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick, false);
    return () => { document.removeEventListener("click", handleClick, false); }
  }, []);

  return (
    <div id='civility-box' className={getClassName(props.disabled, false)}>
      {/* CIVILITY */}
      <fieldset id='civility' data-testid='civility' tabIndex={1} onClick={() => props.setOpenSelectCivility(!props.openSelectCivility)}>
        <legend>{t('global.form.civility')}</legend>
        <span id='civility-span' />
        <input id='civility-input' name='civility' value={getCivility(props.civility)} data-testid='civility-input' disabled={props.disabled} readOnly />
        <div>
          <button type='button' disabled={props.disabled}>
            <img src={props.openSelectCivility && !props.disabled && !props.readonly ? iconArrowUp : iconArrowDown} alt='' />
          </button>
        </div>
        {props.openSelectCivility && !props.disabled && !props.readonly && <div id='civility-dropdown'>
          {Object.values(Civility).map((civility) => (
            <span id='civility-select' data-testid={'civility-select-' + civility} key={civility}
              onClick={() => onChangeCivility(civility)}>
              {getCivility(civility)}
            </span>
          ))}
        </div>}
      </fieldset>
    </div>
  );
}
