import { ChangeEvent, useState } from "react";
import { InvalidCharRegex } from "../../../../utils/Regex";
import './InputTextComponent.scss';

export interface InputProps {
  name: string,
  function: (event: ChangeEvent<HTMLInputElement>) => void,
  label: string,
  type?: string,
  description?: string,
  placeHolder?: string,
  disabled?: boolean
  className?: string,
  invalid?: boolean,
  errorMessage?: string,
  maxLength?: number,
  value?: string,
  tabIndex?: number,
  readonly?: boolean,
  enterKeyHint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send',
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search',
  autoComplete?: 'true' | 'new-password'
}

export default function InputTextComponent(props: InputProps) {

  const [showPassword, setShowPassword] = useState(false);
  let type = props.type;

  if (props.name.match(/password/)) {
    type = showPassword ? "text" : "password";
  }

  const controlInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    event.target.value = value.replace(InvalidCharRegex, '');

    if (value.length !== event.target.value.length) {
      return;
    }
    props.function(event);
  }


  return (
    <div id={props.name + '-input'}
        data-testid={props.name + '-input'}
        className={props.className}>
      <fieldset disabled={props.disabled}>
        <legend>{props.label}</legend>
        <input name={props.name} onChange={controlInput}
          tabIndex={props.tabIndex ? props.tabIndex : 1}
          type={type === undefined ? 'text' : type} autoComplete={props.autoComplete ? props.autoComplete : 'true'}
          aria-required
          aria-label={props.label}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          maxLength={props.maxLength}
          value={props.value}
          readOnly={props.readonly}
          enterKeyHint={props.enterKeyHint ? props.enterKeyHint : 'next'}
          inputMode={props.inputMode ? props.inputMode : 'text'}
          data-testid={props.name}
        />
        {props.name.match(/password/) &&
          <i id={'icon-eye' + (showPassword ? '-crossed' : '')} data-testid={'icon-eye' + (showPassword ? '-crossed' : '')} onClick={() => setShowPassword(!showPassword)} />
        }

      </fieldset>
      {props.invalid && <span>
        {props.errorMessage}
      </span>}
    </div>
  );
}
