import CardStepperComponent from '../CardStepperComponent/CardStepperComponent';
import './CardHeaderComponent.scss';

interface CardHeaderProps {
  title?: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  subtitle4?: string;
  boldSubtitleIds?: string[];
  stepper?: number;
}

export default function CardHeaderComponent(props: CardHeaderProps) {
  const subtitles = [props.subtitle1, props.subtitle2, props.subtitle3, props.subtitle4];
  return (
    <div className='fft-card-header'>
      <h1>{props.title}</h1>
      {
        props.stepper &&
        <CardStepperComponent step={props.stepper} />
      }
      {props.subtitle1 &&
        <h2>
          {subtitles.map((subtitle,index) =>
            <span key={index} id={props.boldSubtitleIds?.includes("subtitle" + (index + 1)) ? 'bold' : ''}>
              {subtitle}
            </span>)}
        </h2>
      }
    </div>
  );
}
