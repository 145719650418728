import { t } from "i18next";

export enum Civility {
    MME = 'Ms',
    M = 'Mr',
    UNDEFINED = '',
}

export function getCivility(civility: string): Civility {
    return isCivilityUndefined(civility) ? Civility.UNDEFINED : civility === Civility.M ? t('global.form.Mr' ) : t('global.form.Ms' )
}

export function getFrenchCivility(civility: string | undefined): string {
    return isCivilityUndefined(civility) ? Civility.UNDEFINED : civility === Civility.M ? 'M' : 'MME';
}

export function getCivilityFromValue(civility: string) {
    const civilities = Object.entries(Civility).find(([, value]) => value === civility)
    return civilities === undefined ? Civility.UNDEFINED : civilities[1];
}

export function getCivilityValueFromFrench(civility: string) {
    return isCivilityUndefined(civility) ? Civility.UNDEFINED : civility === 'M' ? getCivility('Mr') : getCivility('Ms');
}

function isCivilityUndefined(civility: string | undefined) {
    return (civility === undefined || civility === Civility.UNDEFINED);
}

export function getCivilityFromKey(civility: string) {
    const civilities = Object.entries(Civility).find(([key,]) => key === civility)
    return civilities === undefined ? Civility.UNDEFINED : civilities[1];
}