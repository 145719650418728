import type { KcProps } from "keycloakify";
import type { KcContext } from "../../../config/keycloak/kcContext";
import {useTranslation} from "react-i18next";
import logoTenup from "../../../resources/img/logos/logo-TENUP-3.svg";
import logoRG from "../../../resources/img/logos/logo-RG.svg";
import logoRPM from "../../../resources/img/logos/logo-RPM.svg";
import storageService from "../../../services/storage.service";
import logoApple from "../../../resources/img/logos/logo_apple-store.svg";
import logoAppleEn from "../../../resources/img/logos/logo_apple-store-en.svg";
import logoGoogle from "../../../resources/img/logos/logo_google-play.png";
import logoGoogleEn from "../../../resources/img/logos/logo_google-play-en.png";
import CardButtonComponent from "../../shared/CardComponent/CardButtonComponent/CardButtonComponent";
import './KcInfo.scss';

type KcContext_Info = Extract<KcContext, { pageId: "info.ftl"; }>;


type button = {
    text: string;
    link: string;
}

type store = {
    apple: string;
    google: string;
}

type application ={
    application: string;
    img: string;
    buttons: button[];
    store: store;
}

const KcInfo = ({ applicationId, kcContext }: {applicationId: string, kcContext?: KcContext_Info } & KcProps) => {

  if (kcContext && kcContext.actionUri) {
    const { actionUri } = kcContext;
    window.location.href = actionUri;
  }
  const { t } = useTranslation();

  const applications: application[] = [
    { application: "Ten'up", img: logoTenup, buttons:[{text: t('resetPassword.success.application.tenup'), link:process.env.TENUP_URL ||''} ],
      store: { apple: 'https://apps.apple.com/fr/app/tenup/id1457871907',
        google: 'https://play.google.com/store/apps/details?id=com.fft.tenup'}},
    {application: 'RG', img: logoRG, buttons:[
        {text:t('resetPassword.success.application.rg.ticketing'), link: (storageService.getLocale() == 'fr' ? process.env.RG_TICKETING_URL : process.env.RG_TICKETING_URL_EN) || ''},
        {text: t('resetPassword.success.application.rg.eshop'), link: (storageService.getLocale() == 'fr' ? process.env.RG_ESHOP_URL : process.env.RG_ESHOP_URL_EN) || ''},
        {text: t('resetPassword.success.application.rg.travel'), link: (storageService.getLocale() == 'fr' ? process.env.RG_TRAVEL_URL_FR : process.env.RG_TRAVEL_URL_EN) || ''}],
      store: { google: 'https://play.google.com/store/apps/details?id=com.netcosports.androlandgarros',
        apple: 'https://apps.apple.com/fr/app/roland-garros-officiel/id1371584793'}},
    {application: 'RPM', img: logoRPM, buttons:[{text: t('resetPassword.success.application.rpm'), link: (storageService.getLocale() == 'fr'? process.env.RPM_URL : process.env.RPM_URL_EN) || ''}],
      store: { google: 'https://play.google.com/store/apps/details?id=com.fft.rpm.android&gl=FR',
        apple: 'https://apps.apple.com/fr/app/rolex-paris-masters/id6443660414'}}]

  function displayStoreLogos(applications: application[]){
    return (applications.map((application,index) =>
        <div key={index} className={"column" + (index+1) }>
          <div className={"store"}>
            <img src={ storageService.getLocale() == 'fr' ? logoApple : logoAppleEn} onClick={() => window.open(application.store.apple)}/>
            <img className={ storageService.getLocale() == 'fr' ? '' : 'logo-google-en'} src={ storageService.getLocale() == 'fr' ? logoGoogle : logoGoogleEn} onClick={() => window.open(application.store.google)}/>
          </div>
        </div>));
  }

  function displayApplications(applications: application[]){
    return (
        (applications.map((application, indexApplication)=>
        <div key={indexApplication} className={"column" + (indexApplication+1) }>
          <img src={application.img}/>
          {application.buttons.map((button, index)=>
              <CardButtonComponent key={index} text={button.text} filled={false} disabled={false} applicationId={applicationId} handleSubmit={() => window.open(button.link)}/>
          )}
        </div>)));
  }

  // to be adapted according to the updated information.
  const getUpdatedInformation = () => kcContext ? "resetPassword" : "resetEmail";
  
  return (
      <div className='fft-success-reset-password'>
          <div id='header'>
              <div id='title'>
                  {t(getUpdatedInformation() + '.success.header.title')}
                  <br />
              </div>
              <div id={'subtitle'}>
                  {t(getUpdatedInformation() + '.success.header.subtitle1')}
                  <br/>
                  <div>
                      {t(getUpdatedInformation() + '.success.header.subtitle2')}
                  </div>
              </div>

          </div>
          <div className={"logos"}>
              { displayApplications(applications) }
              { displayStoreLogos(applications) }
          </div>

      </div>
  );
};

KcInfo.displayName = 'Info';
export default KcInfo;
