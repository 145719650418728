import { Buffer } from 'buffer';
import React from "react";
import CardComponent from "../component/shared/CardComponent/CardComponent/CardComponent";
import FooterComponent from "../component/shared/FooterComponent/FooterComponent";
import HeaderComponent from "../component/shared/HeaderComponent/HeaderComponent";
import LogosComponent from "../component/shared/LogosComponent/LogosComponent";
import { kcContext } from "../config/keycloak/kcContext";
import { KcFftContextBase } from "../config/keycloak/KcFftContextBase";
import { ClientContext } from "../context/ClientContext";
import './Home.scss';

/* eslint-disable-next-line */
interface HomeProps { }

interface HomeState {
  templateId: string,
  clientId: string
}

export default class Home extends React.Component<HomeProps, HomeState> {

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      templateId: this.getTemplateIdFromContext(),
      clientId: this.getClientIdFromContext()
    }
    this.setLoginUrl();
  }

  /**
   * Retrieve template name by importance order :
   * - kcContext if defined
   * - applicationId url param if defined
   * - 'FFT' if none of either previous option is defined
   */
  getTemplateIdFromContext(): string {
    const validApplications = ['FFT', 'RG', 'TENUP', 'RPM'];
    const defaultApplication = 'FFT';

    if (kcContext === undefined) {
      const templateIdFromUrlParam: string | null = new URLSearchParams(window.location.search).get('application_id');

      if (templateIdFromUrlParam === null) {
        const templateIdFromLocal = localStorage.getItem("templateId");
        return templateIdFromLocal === null ? defaultApplication : templateIdFromLocal;
      } else {
        if (validApplications.includes(templateIdFromUrlParam)) {
          localStorage.setItem("templateId", templateIdFromUrlParam);
          return templateIdFromUrlParam;
        }
        return defaultApplication;
      }
    }
    return (kcContext as KcFftContextBase).clientConfig.templateId;
  }

  getClientIdFromContext(): string {
    if (kcContext === undefined) {
      const clientIdFromUrlParam: string | null = new URLSearchParams(window.location.search).get('client_id');
      return clientIdFromUrlParam === null ? '' : clientIdFromUrlParam;
    }
    return (kcContext as KcFftContextBase).client.clientId;
  }

  setLoginUrl() {
    if (kcContext !== undefined && kcContext.pageId === 'login.ftl') {
      // Set loginUrl when it's login page
      localStorage.setItem("urlLoginBase64", Buffer.from(window.location.href).toString('base64'));
    } else if (kcContext === undefined) {
      // Set loginUrl when state is present
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const urlLoginBase64 = params.get('state');
      if (urlLoginBase64 !== null) {
        localStorage.setItem("urlLoginBase64", urlLoginBase64);
      }
    }
  }

  render() {
    return (
      <div className="home" id={this.state.templateId}>
        <ClientContext.Provider value={this.state}>
          <HeaderComponent applicationId={this.state.templateId} />

          <CardComponent applicationId={this.state.templateId} clientId={this.state.clientId} />

          <LogosComponent applicationId={this.state.templateId} />

          <FooterComponent applicationId={this.state.templateId} />
        </ClientContext.Provider>
      </div>
    )
  }
}
