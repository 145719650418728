import { Buffer } from 'buffer';
import { useTranslation } from "react-i18next";
import storageService from "../../../../../services/storage.service";
import tagService from "../../../../../services/tag.service";

import './CardFlatButtonComponent.scss';
import { MouseEvent, useContext } from 'react';
import { ClientContext } from '../../../../../context/ClientContext';
import { TcEventId, TcEventName, TcEventType } from '../../../../../utils/TcVars';

export default function CardFlatButtonComponent() {

  const { t, i18n } = useTranslation();
  const clientContext = useContext(ClientContext);

  // Construct URL for back to login
  let toLoginURI = new URL(window.location.href);
  const toLoginStr = Buffer.from(storageService.getUrlLoginBase64(), 'base64').toString();
  try {
    toLoginURI = new URL(toLoginStr);
  } catch (error) {
    // console.log('Invalid URL:', toLoginStr);
  }
  if (toLoginURI.searchParams.get('locale')) {
    toLoginURI.searchParams.set('locale', i18n.language);
  } else {
    toLoginURI.searchParams.append('locale', i18n.language);
  }

  const handleClick = (event: MouseEvent): void => {
    event.preventDefault();
    tagService.sendEvent(TcEventId.CLIC, TcEventType.NAVIGATE, TcEventName.BACK_TOL_LOGIN, clientContext.clientId, i18n.language);
    window.location.href = toLoginURI.href;
  };

  return (
    <div className='fft-card-flat-button' >
      <div>
        <a onClick={e => handleClick(e)} href={toLoginURI.href}>{t('global.button.navigate.backLogin')}</a>
      </div>
    </div>
  )
}