import { getCivilityValueFromFrench } from "../../../../model/enum/Civility.enum";
import {CredentialUser, Quadruplet} from "../../../../model/User";
import {t} from "i18next";
import {Dispatch, SetStateAction} from "react";
import './SelectLoginRow.scss';


type SelectLoginProps = {
    applicationId: string,
    login: string,
    quadruplet: Quadruplet,
    idCrm: string,
    lastConnection?: string,
    license?: number,
    selectedAccount: CredentialUser,
    setSelectedAccount: Dispatch<SetStateAction<CredentialUser>>;
}

export default function SelectLoginRow(props: SelectLoginProps) {
    function formatDate(date: string) {
        const split = date.split('-');
        return split.reverse().join('/');
    }

    function getLastDateConnection(date?: string) {
        return date === undefined ? t('resetPassword.selectLogin.form.unknown') : formatDate(date.split('T')[0]);
    }

    function formatNames(firstName: string, lastName: string) {
        return firstName[0].toUpperCase() + firstName.substring(1).toLowerCase() + ' ' + lastName.toUpperCase();
    }
    return(
    <div className='select-login-item'
         key={props.login}
         onClick={() => props.setSelectedAccount({ username: props.login, quadruplet: props.quadruplet, idCrm: props.idCrm, clientId: props.selectedAccount.clientId})}

    >

        {/* RADIO BUTTON */}
        <input className='select-login-item-input'
               id={props.applicationId}
               type="radio"
               value={props.login}
               name={props.quadruplet?.firstName}
               checked={props.selectedAccount.username === props.login}
        />

        {/* NAMES */}
        <div className='first-column'>
            <span className='name' data-testid={'name'}>{getCivilityValueFromFrench(props.quadruplet?.civility)}  {formatNames(props.quadruplet?.firstName, props.quadruplet?.lastName)}</span>
            <span className={'color-gray'}>{t('global.form.login')}:
              <span className={'login'} data-testid={'login'} id={props.applicationId}> {props.login} </span></span>
            {props.license && <span className={'license'} id={props.applicationId}> {t('resetPassword.selectLogin.form.license') + " " + props.license}</span>}

        </div>

        {/* DATES */}
        <div className='second-column'>
            <span> {t('resetPassword.selectLogin.form.bornOn')} <span className={'color-dark'} data-testid={'birthdate'}>{formatDate(props.quadruplet?.birthDate)}</span></span>
            <span className='last-login' data-testid={'lastDateConnection'}>{t('resetPassword.selectLogin.form.lastLogin')}: {getLastDateConnection(props?.lastConnection)} </span>
        </div>
    </div>)
}