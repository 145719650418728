import './FooterComponent.scss';

interface FooterProps {
  applicationId: string,
}

export default function FooterComponent(props: FooterProps) {
  const FFT = 'FFT';
  const TENUP = 'TENUP';
  const RG = 'RG';
  const RPM = 'RPM';
  const secondaryLogos = getSecondaryLogosFromApplicationId();

  function getSecondaryLogosFromApplicationId(): [string, string, string] {
    switch (props.applicationId) {
      case 'FFT':
        return [RG, TENUP, RPM];
      case 'TENUP':
        return [FFT, RG, RPM];
      case 'RG':
        return [FFT, TENUP, RPM];
      case 'RPM':
        return [FFT, TENUP, RG];
      default:
        return [RG, TENUP, RPM];
    }
  }

  return (
    <div className='fft-footer' id={props.applicationId}>
      <img className='fft-footer-logo' id={secondaryLogos[0]} alt={secondaryLogos[0]} />
      <img className='fft-footer-logo' id={secondaryLogos[1]} alt={secondaryLogos[1]} />
      <img className='fft-footer-logo' id={secondaryLogos[2]} alt={secondaryLogos[2]} />
    </div>
  )
}
