import './HeaderComponent.scss';

interface HeaderProps {
  applicationId: string,
}

export default function HeaderComponent(props: HeaderProps) {
  return (
    <div className='fft-header' id={props.applicationId} data-testid={props.applicationId + " header div"}>
      <img id={props.applicationId} alt={props.applicationId} data-testid={props.applicationId + " header img"}/>
    </div>
  )
}
