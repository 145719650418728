import { ResetType } from "../component/shared/ConfirmReset/ConfirmReset";
import { Country } from "../model/Country";
import { Civility, getCivilityFromKey } from "../model/enum/Civility.enum";
import { RegisterUser } from "../model/User";
import { TcConsentParam } from "../utils/hooks/tcConsentParam";

class StorageService {

  public setIdCrm(newIdCrm: string) {
    const user = {
      ...this.getUser(),
      idCrm: newIdCrm
    }
    this.setUser(user);
  }

  public getIdCrm(): string {
    return this.getUser().idCrm;
  }

  public getEmail(): string {
    return this.getUser()?.email;
  }

  public setEmail(email: string) {
    const user = {
      ...this.getUser(),
      email: email
    }
    this.setUser(user);
  }

  // public getLogin(): string {
  //   return this.getUser()?.login;
  // }
  //
  // public setLogin(login: string) {
  //   const user = {
  //     ...this.getUser(),
  //     login: login
  //   }
  //   this.setUser(user);
  // }
  public getUsername(): string {
    return this.getUser()?.username;
  }

  public setUsername(username: string) {
    const user = {
      ...this.getUser(),
      username: username
    }
    this.setUser(user);
  }

  public getFirstname(): string {
    return this.getUser()?.quadruplet?.firstName;
  }

  public setFirstname(firstName: string) {
    const user = {
      ...this.getUser(),
      quadruplet: { ...this.getUser()?.quadruplet, firstName: firstName }
    }
    this.setUser(user);
  }

  public getLastname(): string {
    return this.getUser()?.quadruplet?.lastName;
  }

  public setLastname(lastName: string) {
    const user = {
      ...this.getUser(),
      quadruplet: { ...this.getUser()?.quadruplet, lastName: lastName }
    }
    this.setUser(user);
  }

  public getCivility(): Civility {
    const civility = this.getUser()?.quadruplet?.civility;
    return (civility === undefined || civility === Civility.UNDEFINED) ? Civility.UNDEFINED : getCivilityFromKey(civility);
  }

  public setCivility(civility: string) {
    const user = {
      ...this.getUser(),
      quadruplet: { ...this.getUser()?.quadruplet, civility: civility }
    }
    this.setUser(user);
  }

  public getBirthdate(): string {
    return this.getUser()?.quadruplet?.birthDate;
  }

  public setBirthdate(birthDate: string) {
    const user = {
      ...this.getUser(),
      quadruplet: { ...this.getUser()?.quadruplet, birthDate: birthDate }
    }
    this.setUser(user);
  }

  public getCountry(): Country {
    return this.getUser()?.country;
  }

  public setCountry(country: Country) {
    const user = { ...this.getUser(), country: country }
    this.setUser(user);
  }

  public getPostalCode(): string {
    return this.getUser()?.postalCode;
  }

  public setPostalCode(postalCode: string) {
    const user = { ...this.getUser(), postalCode: postalCode }
    this.setUser(user);
  }

  public setPhone(phone: string) {
    const user = {
      ...this.getUser(),
      phone: phone
    }
    this.setUser(user);
  }

  public getPhone(): string {
    return this.getUser()?.phone;
  }

  public setCode(code: string) {
    const user = {
      ...this.getUser(),
      code: code
    }
    this.setUser(user);
  }

  public getCode(): string {
    return this.getUser().code;
  }

  public getUrlLoginBase64(): string {
    const url = localStorage.getItem("urlLoginBase64") as string;
    return url ? url : '';
  }

  public clear() {
    localStorage.removeItem("user");
    localStorage.removeItem("reset");
  }

  public reset() {
    localStorage.removeItem("reset");
  }

  public clearLogin() {
    const user = JSON.parse(localStorage.getItem("user") as string) as RegisterUser;
    if (user != undefined) {
      user.username = '';
      this.setUser(user);
    }
  }

  private getUser(): RegisterUser {
    return JSON.parse(localStorage.getItem("user") as string) as RegisterUser;
  }

  private setUser(user: RegisterUser) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  public setResetType(resetType: ResetType, hiddenResetCanal: string) {
    localStorage.setItem("reset", JSON.stringify(new ConfirmReset(resetType, hiddenResetCanal)));
  }

  public getResetType(): ResetType {
    return (JSON.parse(localStorage.getItem("reset") as string) as ConfirmReset)?.resetType;
  }

  public getHiddenResetCanal(): string {
    return (JSON.parse(localStorage.getItem("reset") as string) as ConfirmReset)?.hiddenResetCanal;
  }

  public setLocale(locale: string) {
    localStorage.setItem("locale", locale);
  }

  public getLocale(): string {
    return localStorage.getItem("locale") as string;
  }

  public getConsentAnalytics(): string {
    return (JSON.parse(localStorage.getItem('tcParams') as string) as TcConsentParam)?.event_consent_analytics;
  }

  public setConsentAnalytics(consentAnalytics: string) {
    const consentParams = {
      ...this.getConsentParams(),
      event_consent_analytics: consentAnalytics
    }
    this.setConsentParams(consentParams);
  }

  public getConsentAd(): string {
    return (JSON.parse(localStorage.getItem('tcParams') as string) as TcConsentParam)?.event_consent_ad;
  }

  public setConsentAd(consentAd: string) {
    const consentParams = {
      ...this.getConsentParams(),
      event_consent_ad: consentAd
    }
    this.setConsentParams(consentParams);
  }

  public getConsentPerso(): string {
    return (JSON.parse(localStorage.getItem('tcParams') as string) as TcConsentParam)?.event_consent_personalization;
  }

  public setConsentPerso(consentPerso: string) {
    const consentParams = {
      ...this.getConsentParams(),
      event_consent_personalization: consentPerso
    }
    this.setConsentParams(consentParams);
  }

  public getConsentDevice(): 'web'|'app' {
    return (JSON.parse(localStorage.getItem('tcParams') as string) as TcConsentParam)?.event_device;
  }

  public setConsentDevice(consentDevice: 'web'|'app') {
    const consentParams = {
      ...this.getConsentParams(),
      event_device: consentDevice
    }
    this.setConsentParams(consentParams);
  }

  public getConsentAt(): string {
    return (JSON.parse(localStorage.getItem('tcParams') as string) as TcConsentParam)?.event_consent_at;
  }

  public setConsentAt(consentAt: string) {
    const consentParams = {
      ...this.getConsentParams(),
      event_consent_at: consentAt
    }
    this.setConsentParams(consentParams);
  }

  public getAtUserId(): string {
    return (JSON.parse(localStorage.getItem('tcParams') as string) as TcConsentParam)?.atUserId;
  }

  public setAtUserId(atUserId: string) {
    const consentParams = {
      ...this.getConsentParams(),
      atUserId: atUserId
    }
    this.setConsentParams(consentParams);
  }

  public getConsentParams(): TcConsentParam {
    return JSON.parse(localStorage.getItem('tcParams') as string) as TcConsentParam;
  }

  public setConsentParams(consentParams: TcConsentParam) {
    localStorage.setItem('tcParams', JSON.stringify(consentParams));
  }

  public setCountries(countries: Country[]) {
    localStorage.setItem('countries', JSON.stringify(countries))
  }

  public getCountries(): Country[] {
    return JSON.parse(localStorage.getItem('countries') as string) as Country []
  }
}

export default new StorageService();

class ConfirmReset {
  resetType: ResetType;
  hiddenResetCanal: string;

  constructor(resetType: ResetType, hiddenResetCanal: string) {
    this.resetType = resetType;
    this.hiddenResetCanal = hiddenResetCanal;
  }
}
