import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputTextComponent from '../InputTextComponent/InputTextComponent';
import './InputPasswordComponent.scss';

export interface PasswordProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  legendCode: string;
  tabIndex?: number;
}

export default function InputPasswordComponent(props: PasswordProps) {

  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const rules: RegExp[] = [
    /.{10}/,
    /[A-Z]/,
    /\d/,
    /[ !"#$%&()*+,-.\\/:;<=>?@[\]^_`{|}~]/]

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    props.onChange(event);
  };

  const renderRules = () => {
    return Array(rules.length).fill(null).map((_, i) => i + 1).map(index => {
      return <div className='rule' key={index}>
        <img
          id={rules[index - 1].test(password) ? 'checked' : 'cross'}
          data-testid={rules[index - 1].test(password) ? 'checked' : 'cross'}
          alt={t('resetPassword.updatePassword.form.descriptionRule' + index)}
        />
        <span>
          &nbsp;{t('resetPassword.updatePassword.form.rule' + index)}
        </span>
      </div>
    });
  }
  return (
    <div>
      <InputTextComponent
        className='fft-card-form-item'
        name={'password-new'}
        function={onChangePassword}
        label={props.legendCode}
        tabIndex={props.tabIndex} />
      {
        password &&
        <div className='fft-card-form-item' id='password-rules' data-testid='password-rules'>
          {renderRules()}
        </div>
      }
    </div>
  )

}