import { useContext, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { CardProps, getResetEmailUrl, getResetPasswordUrl } from '../shared/CardComponent/CardComponent/CardComponent';
import iconError from '../../resources/img/icons/icon-error.svg';
import HelpPopup from '../shared/HelpPopupComponent/HelpPopup';
import './Error.scss';
import CardFlatButtonComponent from '../shared/CardComponent/CardButtonComponent/CardFlatButton/CardFlatButtonComponent';
import { ClientContext } from '../../context/ClientContext';
import tcPage from '../../utils/hooks/tcPage';
import { TcTemplates } from '../../utils/TcVars';

function Error(props: CardProps) {

  const { t } = useTranslation();

  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.OTHERS.templateName, TcTemplates.OTHERS.pages.error, clientContext.clientId);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }

  return (
    <div className='fft-error'>

      <div id='header'>
        <div id='icon'>
          <img src={iconError} alt={t('global.error.notFound.icon')} />
        </div>
        <div id='title'>{t('global.error.notFound.title')}</div>
        <div id='subtitle'>
          <Trans i18nKey='global.error.notFound.message2' />
          <br />
          <div>
            <Trans i18nKey='global.error.notFound.message3'>
              <a className='popup-hover' onClick={togglePopup} />
            </Trans>
          </div>
        </div>
      </div>

      <CardFlatButtonComponent />

      {isPopupOpen &&
        <HelpPopup
          handleClose={togglePopup}
          resetEmailUrl={getResetEmailUrl(props)}
          resetPasswordUrl={getResetPasswordUrl(props)}
          helpPageType='GENERAL'
        />
      }

    </div>
  )
}

export default Error
