import { KcProps } from 'keycloakify';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { KcContext } from '../../../../config/keycloak/kcContext';
import { ClientContext } from '../../../../context/ClientContext';
import tagService from '../../../../services/tag.service';
import tcPage from '../../../../utils/hooks/tcPage';
import { TcEventId, TcEventName, TcEventType, TcTemplates } from '../../../../utils/TcVars';
import Template from '../../../../view/Template';
import CardButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardButtonComponent';
import CardTextButtonComponent from '../../../shared/CardComponent/CardButtonComponent/CardTextButton/CardTextButtonComponent';
import { AccountComponent } from '../../../shared/DetailComponent/AccountComponent/AccountComponent';
import KcAlert from '../../KcAlert/KcAlert';

type KcContext_UpdateCredentials = Extract<KcContext, { pageId: 'update-credentials.ftl'; }>;

const UpdateAccount = ({ applicationId, kcContext, ...props }: { applicationId: string, kcContext: KcContext_UpdateCredentials; } & KcProps) => {

  // TRADUCTION
  const { t, i18n } = useTranslation();

  // TAGGING
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.UPDATE_ACCOUNT.templateName, TcTemplates.UPDATE_ACCOUNT.pages.update_credentials, clientContext.clientId);
  const handleSubmit = (): void => {
    tagService.sendEvent(TcEventId.UPDATE_ACCOUNT, TcEventType.ACTION, TcEventName.CONTINUE, clientContext.clientId, i18n.language);
  }

  // KC VAR
  const { url, message, user, redirectUri } = kcContext;

  // USER DATAS
  const [isAccountFormValid, setIsAccountFormValid] = useState(true);

  return (
    <Template
      {...{ kcContext, ...props }}
      doFetchDefaultThemeResources={true}
      headerTitleNode={t('updateAccount.header.title')}
      headerSubtitleNode={[
        t('updateAccount.header.subtitle1')
      ]}
      headerBoldSubtitleIds={['subtitle2']}
      formNode={
        <div>
          {message !== undefined && <KcAlert errorType={message.type} code={message.code} kcContext={kcContext}/>}
          <div className='fft-card-form'>
            <form id="reset-form" action={url.loginAction} method="post">

              <AccountComponent user={user} isFormValid={setIsAccountFormValid} startTabIndexAt={5} defaultShowDisconnect={true}/>

              <input name='language' value={i18n.language} readOnly type='hidden' />

              {/* REGISTER BUTTON */}
              <CardButtonComponent
                text={t('updateAccount.form.submit')}
                filled={true} disabled={!isAccountFormValid}
                applicationId={applicationId}
                handleSubmit={handleSubmit}
                tabIndex={15} />

              {/* CANCEL BUTTON */}
              <CardTextButtonComponent
                applicationId={applicationId}
                redirectUri={redirectUri}
                text={t('updateAccount.form.cancel')}
              />

            </form>
          </div>
        </div>
      }
    />
  );
};

UpdateAccount.displayName = 'UpdateAccount';
export default UpdateAccount;
