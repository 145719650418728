import type { KcProps } from "keycloakify";
import { ChangeEvent, memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import type { KcContext } from "../../../config/keycloak/kcContext";
import { ClientContext } from "../../../context/ClientContext";
import storageService from "../../../services/storage.service";
import tagService from "../../../services/tag.service";
import { getClassName } from "../../../utils/getClassFunctions";
import tcPage from "../../../utils/hooks/tcPage";
import { TcEventId, TcEventName, TcEventType, TcTemplates } from "../../../utils/TcVars";
import Template from "../../../view/Template";
import CardButtonComponent from "../../shared/CardComponent/CardButtonComponent/CardButtonComponent";
import InputTextComponent from "../../shared/InputComponent/InputTextComponent/InputTextComponent";
import KcAlert from "../KcAlert/KcAlert";
import './Login.scss';

type KcContext_Login = Extract<KcContext, { pageId: "login.ftl"; }>;

const Login = memo(({ applicationId, clientId, kcContext, ...props }: { applicationId: string, clientId: string, kcContext: KcContext_Login; } & KcProps) => {

  const { t, i18n } = useTranslation();
  const clientContext = useContext(ClientContext);
  tcPage(TcTemplates.LOGIN.templateName, TcTemplates.LOGIN.pages.login, clientContext.clientId);

  const { url, passwordResetRedirectUri, message } = kcContext;
  // const [login, setLogin] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const passwordResetRedirect = new URL(passwordResetRedirectUri);
  passwordResetRedirect.searchParams.set('application_id', applicationId);
  passwordResetRedirect.searchParams.set('client_id', clientId);
  passwordResetRedirect.searchParams.append('state', storageService.getUrlLoginBase64());
  if (storageService.getLocale()) {
    passwordResetRedirect.searchParams.append('locale', storageService.getLocale());
  }

  const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    // setLogin(event.target.value);
    setUsername(event.target.value);
  };
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: { preventDefault: () => void }): void => {
    if (isSubmitting) {
      event.preventDefault();
      return;
    }
    tagService.sendEvent(TcEventId.CLIC, TcEventType.ACTION, TcEventName.CONNECTION, clientContext.clientId, i18n.language);

    setIsSubmitting(true);
  };

  const handleRegisterClick = (): void => {
    tagService.sendEvent(TcEventId.CLIC, TcEventType.ACTION, TcEventName.REGISTER, clientContext.clientId, i18n.language);
  };

  return (
    <Template
      {...{ kcContext, ...props }}
      doFetchDefaultThemeResources={true}
      headerTitleNode={t('login.header.title')}
      headerSubtitleNode={[
        t('login.header.subtitle1'),
        t('login.header.subtitle2'),
        t('login.header.' + applicationId)
      ]}
      headerBoldSubtitleIds={['subtitle2']}
      formNode={
        <div>

          {message !== undefined && <KcAlert errorType={message.type} code={message.code} kcContext={kcContext}/>}

          <div className='login'>
            <div className='fft-card-form'>
              <form id="reset-form" action={url.loginAction} method="post">
                  <InputTextComponent name={'username'} function={onChangeUsername}
                                      label={t('login.form.loginOrEmail')}
                                      className={getClassName(false, false)}
                  />
                  <InputTextComponent name={"password"} function={onChangePassword}
                                      label={t('global.form.password')}
                                      className={'fft-card-form-item'}
                                      autoComplete={'new-password'}/>

                  <div className="fft-link">
                  <span aria-label={t('login.form.forgottenPassword')}>
                    <a href={passwordResetRedirect.pathname + passwordResetRedirect.search}>
                      {t('login.form.forgottenPassword')}
                    </a>
                  </span>
                  </div>

                  {/* LOGIN BUTTON */}
                  <CardButtonComponent text={t('login.form.submit')} filled={true} disabled={!(username && password)}
                                       applicationId={applicationId} handleSubmit={handleSubmit}/>

                  {/* REGISTER BUTTON */}
                  <div className='fft-card-button'>
                      <a id={applicationId} href={url.registrationUrl} onClick={handleRegisterClick}>
                    <span>
                      {t('login.form.register')}
                    </span>
                  </a>
                </div>
              </form>


            </div>
          </div>
        </div>
      }
    />
  );
});

Login.displayName = 'Login';
export default Login;
